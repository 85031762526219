function Movie(props) {

    const stroke = props.stroke  ?? "#A7A5A5";



    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 15" fill="none">
            <path
                d="M5.24984 1.85352L4.6665 4.77018M9.33317 1.85352L8.74984 4.77018M12.8332 4.77018H1.1665M3.9665 12.3535H10.0332C11.0133 12.3535 11.5033 12.3535 11.8777 12.1628C12.2069 11.995 12.4747 11.7273 12.6424 11.398C12.8332 11.0237 12.8332 10.5336 12.8332 9.55352V4.65352C12.8332 3.67342 12.8332 3.18338 12.6424 2.80903C12.4747 2.47975 12.2069 2.21203 11.8777 2.04425C11.5033 1.85352 11.0133 1.85352 10.0332 1.85352H3.9665C2.98641 1.85352 2.49637 1.85352 2.12202 2.04425C1.79274 2.21203 1.52502 2.47975 1.35724 2.80903C1.1665 3.18338 1.1665 3.67342 1.1665 4.65352V9.55352C1.1665 10.5336 1.1665 11.0237 1.35724 11.398C1.52502 11.7273 1.79274 11.995 2.12202 12.1628C2.49637 12.3535 2.98641 12.3535 3.9665 12.3535Z"
                stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default Movie;
