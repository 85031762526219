function List(props) {
    const color = props.color ? props.color : "#a7a5a5";
    const style = props.style ? props.style : {};

    return (
        <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 2.25C0.541667 1.79167 0.791667 1.54167 1.25 1.5H2.75C3.20833 1.54167 3.45833 1.79167 3.5 2.25V3.75C3.45833 4.20833 3.20833 4.45833 2.75 4.5H1.25C0.791667 4.45833 0.541667 4.20833 0.5 3.75V2.25ZM2.5 3.5V2.5H1.5V3.5H2.5ZM15.5 2.5C15.8125 2.52083 15.9792 2.6875 16 3C15.9792 3.3125 15.8125 3.47917 15.5 3.5H5.5C5.1875 3.47917 5.02083 3.3125 5 3C5.02083 2.6875 5.1875 2.52083 5.5 2.5H15.5ZM15.5 7.5C15.8125 7.52083 15.9792 7.6875 16 8C15.9792 8.3125 15.8125 8.47917 15.5 8.5H5.5C5.1875 8.47917 5.02083 8.3125 5 8C5.02083 7.6875 5.1875 7.52083 5.5 7.5H15.5ZM15.5 12.5C15.8125 12.5208 15.9792 12.6875 16 13C15.9792 13.3125 15.8125 13.4792 15.5 13.5H5.5C5.1875 13.4792 5.02083 13.3125 5 13C5.02083 12.6875 5.1875 12.5208 5.5 12.5H15.5ZM2.75 6.5C3.20833 6.54167 3.45833 6.79167 3.5 7.25V8.75C3.45833 9.20833 3.20833 9.45833 2.75 9.5H1.25C0.791667 9.45833 0.541667 9.20833 0.5 8.75V7.25C0.541667 6.79167 0.791667 6.54167 1.25 6.5H2.75ZM1.5 7.5V8.5H2.5V7.5H1.5ZM0.5 12.25C0.541667 11.7917 0.791667 11.5417 1.25 11.5H2.75C3.20833 11.5417 3.45833 11.7917 3.5 12.25V13.75C3.45833 14.2083 3.20833 14.4583 2.75 14.5H1.25C0.791667 14.4583 0.541667 14.2083 0.5 13.75V12.25ZM2.5 13.5V12.5H1.5V13.5H2.5Z"
                fill={color}/>
        </svg>
    )
}

export default List;
