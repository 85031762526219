import React from "react";

function ChevronLeft(props) {

    const style = props.style ? {...props.style} : {}
    const color = props.color ? props.color : "#fff";
    return (
        <svg
            viewBox="0 0 1024 1024"
            style={style}
            fill={color}
            height="1.2em"
            width="1.2em"
        >
            <path d="M258.3 486.8L709.1 134.7A7.97 7.97 0 01722 141v77.3c0 4.9-2.3 9.6-6.1 12.6l-360 281.1 360 281.1c3.9 3 6.1 7.7 6.1 12.6V883c0 6.7-7.7 10.4-12.9 6.3L258.3 537.2a31.96 31.96 0 010-50.4z" />
        </svg>
    )
}
export default ChevronLeft;
