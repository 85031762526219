import React, { createContext, useState, useContext } from 'react';
import {useUserStore} from "./StoreUserData";

const UserContext = createContext();


// Энийг ашиглаад хэрэглэгчийн мэдээллийг глобал байдлаар хадгалдаг
export const UserProvider = ({ children }) => {



    const { user, setUser } = useUserStore();

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );

};
