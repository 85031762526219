import React, {useEffect, useRef, useState} from 'react'
import HeaderCard from "../../common/header-author/Header-Card";
import HeaderAuthor from "../../common/header-author/Header-Author";
import CardAuthor from "../../cards/CardAuthor";
import {fakeMovie} from "../../data/fakeData";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import Slider from "react-slick";
import ChevronLeft from "../../icons/ChevronLeft";
import ChevronRight from "../../icons/ChevronRight";
import CustomPopup from "../../common/pop-up/CustomPopup";
import MovieModal from "../../modal/MovieModal";

export default function Author() {

    const [latestMovies] = fakeMovie;


    const [data, setData] = useState([]);

    const slickRef = useRef()


    useEffect(() => {
        getCollection();
    }, []);


    const getCollection = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/collection`);
            if ((await response).success) {
                setData((await response).payload);
            }
        } catch (e) {
        }
    }

    const goNext = () => {
        if (slickRef.current)
            slickRef.current.slickNext()
    }
    const goPrev = () => {
        if (slickRef.current)
            slickRef.current.slickPrev()
    }

    let settings = {
        dots: false,
        infinite: true,
        fade: false,
        arrows: false,
        speed: 1200,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5500,
        swipeToSlide: false,
        swipe: false,
        className: "authors-slider-container",
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    };




    return (
        <div className={"container"}>
            <div className="slider-over-navigation">
                <HeaderCard title={"Найруулагчийн цуглуулга"}/>
                <Slider {...settings} ref={slickRef}>
                    {data.map((item, index) => (
                        <div key={`${item.id}`}>
                            <HeaderAuthor item={item} id={item.collectionId}/>
                            <CardAuthor collectionId={item.collectionId}  />
                        </div>
                    ))}
                </Slider>
                <div className="author-prev" onClick={goPrev}>
                   <ChevronLeft/>
                </div>
                <div className="author-next" onClick={goNext}>
                   <ChevronRight/>
                </div>
            </div>
        </div>
    )
}
