import React from "react";

function BookmarkMinus(props) {

    const style = props.style ? {...props.style} : {}
    const color = props.color  ?  props.color : "#A7A5A5";


    const width = props.width  ? props.width : "20px";
    const height = props.height ? props.height : "20px";


    return (
        <svg
            style={style}
            fill={color}
            viewBox="0 0 16 16"
            height={width}
            width={height}
        >
            <path
                fillRule="evenodd"
                d="M5.5 6.5A.5.5 0 016 6h4a.5.5 0 010 1H6a.5.5 0 01-.5-.5z"
            />
            <path d="M2 2a2 2 0 012-2h8a2 2 0 012 2v13.5a.5.5 0 01-.777.416L8 13.101l-5.223 2.815A.5.5 0 012 15.5V2zm2-1a1 1 0 00-1 1v12.566l4.723-2.482a.5.5 0 01.554 0L13 14.566V2a1 1 0 00-1-1H4z" />
        </svg>
    )
}

export default BookmarkMinus;
