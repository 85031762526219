function Wallet(props) {

    const color = props.stroke  ?? "#A7A5A5";

    const style = props.style  && props.style  ;

    return (

        <svg style={style} width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5 1C14.8125 1.02083 14.9792 1.1875 15 1.5C14.9792 1.8125 14.8125 1.97917 14.5 2H2.5C2.08333 2.02083 1.72917 2.16667 1.4375 2.4375C1.16667 2.72917 1.02083 3.08333 1 3.5V12.5C1.02083 12.9167 1.16667 13.2708 1.4375 13.5625C1.72917 13.8333 2.08333 13.9792 2.5 14H13.5C13.9167 13.9792 14.2708 13.8333 14.5625 13.5625C14.8333 13.2708 14.9792 12.9167 15 12.5V5.5C14.9792 5.08333 14.8333 4.72917 14.5625 4.4375C14.2708 4.16667 13.9167 4.02083 13.5 4H3.5C3.1875 3.97917 3.02083 3.8125 3 3.5C3.02083 3.1875 3.1875 3.02083 3.5 3H13.5C14.2083 3.02083 14.8021 3.26042 15.2812 3.71875C15.7396 4.19792 15.9792 4.79167 16 5.5V12.5C15.9792 13.2083 15.7396 13.8021 15.2812 14.2812C14.8021 14.7396 14.2083 14.9792 13.5 15H2.5C1.79167 14.9792 1.19792 14.7396 0.71875 14.2812C0.260417 13.8021 0.0208333 13.2083 0 12.5V3.5C0.0208333 2.79167 0.260417 2.19792 0.71875 1.71875C1.19792 1.26042 1.79167 1.02083 2.5 1H14.5ZM11.25 9C11.2917 8.54167 11.5417 8.29167 12 8.25C12.4583 8.29167 12.7083 8.54167 12.75 9C12.7083 9.45833 12.4583 9.70833 12 9.75C11.5417 9.70833 11.2917 9.45833 11.25 9Z"
                fill={color}/>
        </svg>

    )
}

export default Wallet;
