import React ,{useState}from 'react'
import {Link} from "react-router-dom";
import Close from "../icons/Close";
import ArrowRight from "../icons/ArrowRight";
import ArrowLeft from "../icons/ArrowLeft";
import Wallet from "../icons/Wallet";
import Calendar from "../icons/Calendar";
import Phone from "../icons/Phone";

export default function MenuDropdownMobile({show}) {

    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [currentMenu, setCurrentMenu] = useState('main');

    const menus = {
        main: (
            <>
                <div className={"body"}>
                    <div className="title" onClick={() => setCurrentMenu('otherMenus')}>
                        <div>Бусад цэсүүд</div>
                        <div><ArrowRight /></div>
                    </div>
                    <Link to="/all-projects" className="title">
                        <div>Анимэ</div>
                        <div><ArrowRight /></div>
                    </Link>
                    <Link to={"/manga-projects"} className="title">
                        <div>Манга</div>
                        <div><ArrowRight /></div>
                    </Link>
                    <Link to={"/netflix"} className="title">
                        <div>Netflix</div>
                        <div><ArrowRight /></div>
                    </Link>
                </div>

            </>
        ),
        otherMenus: (
            <>
                <div className={"body"}>
                    <button className="goback" onClick={() => setCurrentMenu('main')}>
                        <ArrowLeft/>
                    </button>
                    <Link to={"/"} className="title">
                        <div className={"flex gap-2 items-center"}>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 16H14M9.0177 1.764L2.23539 7.03912C1.78202 7.39175 1.55534 7.56806 1.39203 7.78886C1.24737 7.98444 1.1396 8.20478 1.07403 8.43905C1 8.70352 1 8.9907 1 9.56505V16.8C1 17.9201 1 18.4801 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4801 19 17.9201 19 16.8V9.56505C19 8.9907 19 8.70352 18.926 8.43905C18.8604 8.20478 18.7526 7.98444 18.608 7.78886C18.4447 7.56806 18.218 7.39175 17.7646 7.03913L10.9823 1.764C10.631 1.49075 10.4553 1.35412 10.2613 1.3016C10.0902 1.25526 9.9098 1.25526 9.73865 1.3016C9.54468 1.35412 9.36902 1.49075 9.0177 1.764Z" stroke="#A7A5A5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <div>Нүүр хуудас</div>
                        </div>
                    </Link>
                    <Link to={"/guide"} className="title">
                        <div className={"flex gap-2 items-center"}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.09 8C8.3251 7.33167 8.78915 6.76811 9.39995 6.40913C10.0108 6.05016 10.7289 5.91894 11.4272 6.03871C12.1255 6.15849 12.7588 6.52152 13.2151 7.06353C13.6713 7.60553 13.9211 8.29152 13.92 9C13.92 11 10.92 12 10.92 12M11 16H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#A7A5A5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <div>Хандив өгөх заавар</div>
                        </div>
                    </Link>
                    <Link to={"/qpay"} className="title">
                        <div className={"flex gap-2 items-center"}>
                            <Wallet/>
                            <div>Хандив</div>
                        </div>
                    </Link>
                    <Link to={"/"} className="title">
                        <div className={"flex gap-2 items-center"}>
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 7H1M14 1V4M6 1V4M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21ZM9.99726 11.3306C9.19752 10.4216 7.8639 10.1771 6.86188 11.0094C5.85986 11.8418 5.71879 13.2335 6.50568 14.2179C7.077 14.9327 8.65927 16.3397 9.48333 17.0569C9.66198 17.2124 9.7513 17.2902 9.85597 17.321C9.94664 17.3477 10.0479 17.3477 10.1386 17.321C10.2432 17.2902 10.3325 17.2124 10.5112 17.0569C11.3353 16.3397 12.9175 14.9327 13.4888 14.2179C14.2757 13.2335 14.1519 11.8331 13.1326 11.0094C12.1134 10.1858 10.797 10.4216 9.99726 11.3306Z" stroke="#A7A5A5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            <div>Хуваарь</div>
                        </div>
                    </Link>
                </div>
            </>
        ),
    };





    return (

        <div className={`menu-dropdown-mobile`}>
            <div className="section">Цэс</div>
            <button className="close" onClick={show}>
                <Close stroke="#000" />
            </button>
            {menus[currentMenu]}
        </div>

    )
}
