import React, {useEffect, useState} from 'react'
import {get} from "sodon-http/API"
import {API_URL} from "../../constans/API_URL";
import HeaderCard from "../../components/common/header-author/Header-Card";
import CardCommon from "../../components/cards/CardCommon";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import MovieModal from "../../components/modal/MovieModal";

export default function PageAnimeMovie() {


    const [data, setData] = useState([]);
    const [pages, setPages] = useState(null);

    useEffect(() => {
        fetchAnimeMovies();
    }, []);


    const fetchAnimeMovies = async () => {
        try {
            const response = await get(`${API_URL}/api/movie?type=2`);
            if ((await response).success) {
                setData((await response).payload);
                setPages((await  response).payload)
            }
        } catch (e) {
        }
    }



    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }




    return (
        <>
            <div className={"container"}>
                <div className="ongoing">
                    <HeaderCard title={"Анимэ кинонууд"}/>
                    <div className={"grid mt-5 lg:grid-cols-8 gap-4 md:grid-cols-5 sm:grid-cols-4 max-sm:grid-cols-2"}>
                        {
                            data.map((item, index) => (
                                <>
                                    <CardCommon key={index} item={item}  onClick={() => {openDialog(item)}} />
                                </>
                            ))
                        }

                    </div>
                    <CustomPopup open={isDialogOpen} close={handleClose}>
                        <MovieModal
                            item={selectedItem}
                            shown={isDialogOpen}
                            setShown={value => setIsDialogOpen(value)}
                        />
                    </CustomPopup>
                </div>
            </div>


        </>

    )
}
