import React from 'react'
import CardLatest from "../../../components/cards/CardLatest";
import Paginate from "../../../components/paginate/paginate";
import {fakeData, fakeLatest} from "../../../components/data/fakeData";
import {API_URL} from "../../../constans/API_URL";


export default function BasketMovie({item, onClick}) {



    const imageUri = item.moduleItemImage !== null
        ? ``
        : null


    if (item.module === "MOVIE"){
        return (
            <div onClick={onClick} className={"saved-movie"}>
                <div className={"img"}>
                    <img src={`https://www.mnfansubs.net/resource/${item.moduleItemImage}`} alt=""/>
                </div>
            </div>
        )
    }
    if (item.module === "MANGA"){
        return (
            <div onClick={onClick} className={"saved-movie"}>
                <div className={"img"}>
                    <img src={`https://www.mnfansubs.net/resource/${item.moduleItemImage}`} alt=""/>
                </div>
            </div>
        )
    }

}

