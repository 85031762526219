import React, {useEffect, useState} from 'react'
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";

export default function ClientExpireDate() {


    const [expireDate, setExpireDate] = useState('');



    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const dateResponse = await get(`${API_URL}/api/movie/user/subscription/getExpired`);
            if (dateResponse.status === 200) {
                setExpireDate(dateResponse.payload)
            }
        } catch (error) {
            console.error('error');
        }
    };



    const [daysUntilExpire, setDaysUntilExpire] = useState(0);

    useEffect(() => {
        if (expireDate) {
            const expireDateTime = new Date(parseInt(expireDate) * 1000); // Convert Unix timestamp to milliseconds and then to a Date object
            const currentTime = new Date();
            const differenceInTime = expireDateTime - currentTime; // Difference in milliseconds
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert milliseconds to days

            setDaysUntilExpire(differenceInDays);
        }
    }, [expireDate]);

    console.log("sss", daysUntilExpire);

    return (
        <>
            {
                daysUntilExpire === 0 ?
                    <div style={{color: "red"}}>
                        {daysUntilExpire}
                    </div>
                    :
                    <div style={{color: "green"}}>
                        {daysUntilExpire}
                    </div>

            }


        </>

    )
}
