import React, {Component} from 'react';
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropMethods";
import {API_URL} from "../../../constans/API_URL";
import CustomAlert from "../../common/CustomAlert";
import {Link, useNavigate} from "react-router-dom";

class ProfileImage extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        imageSrc:"",
        crop: {x: 0, y: 0},
        zoom: 1,
        aspect: 1,
        croppedAreaPixels: null,
        croppedImage: null,
        rotation: 0,
    }

    inputRef = React.createRef();

    componentDidUpdate(prevProps, prevState) {
        if (this.state.successMessage && prevState.successMessage !== this.state.successMessage) {
            alert(this.state.successMessage);
            this.setState({ successMessage: '' }); // Reset the message after showing alert
        }
        if (this.state.errorMessage && prevState.errorMessage !== this.state.errorMessage) {
            alert(this.state.errorMessage);
            this.setState({ errorMessage: '' }); // Reset the message after showing alert
        }
    }

    onCropChange = (crop) => {
        this.setState({crop});
    }
    onZoomChange = (zoom) => {
        this.setState({zoom});
    }
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        // console.log("croppedAreaPixels", croppedAreaPixels)
        this.setState({croppedAreaPixels});
        if (croppedAreaPixels) {
            setTimeout(() => {
                this.showCroppedImage();
            }, 300)
        }
    }

    showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            this.state.imageSrc,
            this.state.croppedAreaPixels,
            this.state.rotation,
        )

        // console.log()
        if (croppedImage) {
            console.log("DONE: croppedImage", croppedImage);
            this.setState({croppedImage});
        } else {
            console.error("Error cropping image ")
        }
    }

    savePhoto = async () => {
        try {
            const form = new FormData();

            // Check if croppedImage is a Blob object (URL)
            if (
                this.state.croppedImage.startsWith('blob:')) {
                const blob = await fetch(this.state.croppedImage).then(res => res.blob());
                console.log("BLOB: ", blob);
                form.append("profileImageFile", blob, "profile.jpg");
                console.log("form", form);
            } else {
                // Assuming croppedImage is a Base64 string
                const base64Data = this.state.croppedImage;
                const blob = new Blob([base64Data], { type: 'image/jpeg' });
                form.append("profileImageFile", blob, "profile.jpeg");
            }

            const response = await fetch(`${API_URL}/api/client/profile/changePhoto`, {
                method: "POST",
                credentials: "include", // Include session cookies if needed
                headers: {
                    "Accept": "application/json",
                    // "Content-Type": "multipart/form-data; boundary=asdf",
                },
                body: form,
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Image upload response:', data);
                this.setState({
                    showAlert: true,
                    alertType: 'success',
                    message: 'Image uploaded successfully!',
                });
                // Auto close alert and navigate back after 0.5 seconds
                setTimeout(() => {
                    this.setState({ showAlert: false });

                }, 1000);
            } else {
                console.error('Error uploading image:', response.statusText);
                this.setState({
                    showAlert: true,
                    alertType: 'error',
                    message: 'Error uploading image: ' + response.statusText,
                });

                // Auto close alert after 0.5 seconds
                setTimeout(() => {
                    this.setState({ showAlert: false });
                }, 1000);
            }
        } catch (e) {
            console.error('Error saving photo:', e);
        }
    }
;

    render() {
        return (
            <div className="container-md" style={{}}>

                <CustomAlert
                    isVisible={this.state.showAlert}
                    type={this.state.alertType}
                    message={this.state.message}
                />
                <div
                    style={{position: "relative", width: "100%", height:"30vh", cursor:"pointer", background:"grey", borderRadius:"10px"}}
                    onClick={ () => {
                        if (!this.state.imageSrc && this.inputRef.current)
                            this.inputRef.current.click()
                    }}
                >
                    {
                        this.state.imageSrc ?
                            <Cropper
                                image={this.state.imageSrc}
                                onCropChange={this.onCropChange}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={this.state.aspect}
                                onZoomChange={this.onZoomChange}
                                onCropComplete={this.onCropComplete}
                            /> :
                            <div style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)"
                            }}>
                                <h1 style={{fontSize: 28, color: "#fff"}}>Зураг сонгох</h1>
                            </div>

                    }
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    flexWrap:'wrap',
                }}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                        <div>
                            {
                                this.state.croppedImage ?
                                    <div style={{display: "flex", alignItems: "center", gap: 10}}>
                                        <imgprofile-ima
                                            src={this.state.croppedImage}
                                            alt=""
                                            style={{borderRadius: "50%", width: 70, height: 70,}}
                                        />
                                        <img
                                            src={this.state.croppedImage}
                                            alt=""
                                            style={{borderRadius: "50%", width: 50, height: 50,}}
                                        />
                                        <img
                                            src={this.state.croppedImage}
                                            alt=""
                                            style={{borderRadius: "50%", width: 20, height: 20,}}
                                        />
                                    </div> :
                                    <div style={{display: "flex", alignItems: "center", gap: 10}}>
                                        <img
                                            src={"images/profile.png"}
                                            alt=""
                                            style={{borderRadius: "50%", width: 70, height: 70,}}
                                        />
                                        <img
                                            src={"images/profile.png"}
                                            alt=""
                                            style={{borderRadius: "50%", width: 50, height: 50,}}
                                        />
                                        <img
                                            src={"images/profile.png"}
                                            alt=""
                                            style={{borderRadius: "50%", width: 20, height: 20,}}
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems:"center", gap: "10px",flexWrap:'wrap'}}>
                        <div>
                            <input
                                ref={this.inputRef}
                                type="file"
                                placeholder="Image..."
                                accept={"image/*"}
                                style={{color: "#fff"}}
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    const reader = new FileReader();
                                    // console.log(e.target.value);
                                    reader.readAsDataURL(file);
                                    reader.onload = (event) => {
                                        this.setState({imageSrc: event.target.result});
                                    }
                                    // this.setState({imageSrc: e.target.result})
                                }}
                            />
                        </div>

                        <div>

                            {
                                this.state.croppedImage ?
                                    <button
                                        className="button-md button-original"
                                        onClick={this.savePhoto}
                                    >
                                        Хадгалах
                                    </button>
                                    :
                                    <button disabled={true} className="button-md button-secondary">
                                        Хадгалах
                                    </button>
                            }
                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

ProfileImage.propTypes = {};

export default ProfileImage;
