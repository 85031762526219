import React,{useState, useEffect} from 'react'
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import CardLatest from "../../components/cards/CardLatest";
import HeaderCard from "../../components/common/header-author/Header-Card";

export default function PageWatched() {


    const [movieData, setMovieData] = useState([]);


    useEffect(() => {
        getLatestEpisodes();
    }, []);


    const getLatestEpisodes = async () => {
        try {
            let uniqueEpisodeIds = new Set(); // Initialize outside the if block
            const response = await get(`${API_URL}/api/client/episode/watched`);
            if ((await response).success) {
                const data = (await response).payload.thisPageElements;
                const filteredEpisodes = data.filter(item => {
                    const episodeId = item.episode.id;
                    const isUnique = !uniqueEpisodeIds.has(episodeId);
                    if (isUnique) {
                        uniqueEpisodeIds.add(episodeId);
                    }

                    return isUnique;
                });
                setMovieData(filteredEpisodes);
            }
        } catch (e) {

        }
    }
    return (
        <div className={"container"}>
            <HeaderCard title={"Үзсэн анги"}/>
            <div className={"mt-5 grid lg:gap-4  sm:gap-3 max-sm:gap-2  lg:grid-cols-4  sm:grid-cols-3 max-sm:grid-cols-2"}>
                {
                    movieData.map((item, index) => (
                        <CardLatest key={index} item={item.episode} />
                    ))
                }
            </div>
        </div>
    )
}
