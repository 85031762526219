import React, {useEffect, useState} from 'react'
import BookmarkAdd from "../icons/Bookmark-add";
import Heart from "../icons/Heart";
import Star from "../icons/Star";
import Eye from "../icons/Eye";
import Info from "../icons/Info";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import BookmarkMinus from "../icons/BookmarkMinus";
import CustomAlert from "../common/CustomAlert";

export default function CardPopular({item, onClick}) {



    const [isLoading, setIsLoading] = useState(false)
    const [checkSaved, setCheckSaved] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await get(`${API_URL}/api/client/user/savedItem/check?module=movie&moduleItemId=${item.id}`);
                if (response.success) {
                    const data = response.payload;
                    if (typeof data === 'boolean') {
                        setCheckSaved(data);
                    }
                }
            } catch (error) {

            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [item.id]);



    const handleSaveItem = async () => {
        setAlertVisible(true);
        try {
            const form = {
                module: 'MOVIE',
                moduleItemId: item.id,
                moduleItemName: item.name,
                moduleItemDesc: item.description,
                moduleItemImage: `${item.image?.name}.${item.image?.ext}`
            };
            // Make the POST request
            const response = await postJson(`${API_URL}/api/client/user/savedItem/saveOrDelete`, form);
            if (response.status === 200 && response.success) {
                setCheckSaved(!checkSaved);
                setAlertType(!checkSaved === true ? 'success' : 'warning');
                setAlertMessage(!checkSaved === true ? 'Амжилттай хадгалагдлаа ' : 'Амжилттай устгагдлаа')
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error saving item:", error);
            alert('Алдаа', error);
        }
    };





    return (
        <div className={"card-popular"}>
            <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
            <div className="img" onClick={onClick}>
                <img src={`https://www.mnfansubs.net/resource/${item.movieImage?.name}.${item.movieImage?.ext}`} alt=""/>
            </div>
            <div className={"flex justify-between flex-1"} >
                <div>
                    <div className={"title"} onClick={onClick}>
                        {item.name}
                    </div>
                   {/* <div className={"icons"}>
                        <div>
                            <Star stroke={"#FF6600"}/>
                            <div>
                                4.7(2k)
                            </div>
                        </div>
                        <div>
                            <Heart/>
                            <div>

                                4.7(2k)
                            </div>
                        </div>
                        <div >
                            <Eye/>
                            <div>
                                4.7(2k)
                            </div>
                        </div>
                    </div>*/}
                    <div className={"about"}>
                        <div>
                        <span>
                            Төлөв:
                        </span>
                            <span>
                            {item.movieStatus}
                        </span>
                        </div>
                        <div>
                        <span>
                          Гарч эхэлсэн:
                        </span>
                            <span>
                            {item.year}
                        </span>
                        </div>
                        <div>
                        <span>
                             Ангийн тоо:
                        </span>
                            <span>
                            {item.totalNumber}
                        </span>
                        </div>
                    </div>
                </div>
                <div>
                    <button onClick={handleSaveItem}>
                        {
                            checkSaved ===false &&
                            <div className={"button-md button-primary-outline"}>
                                <BookmarkAdd color={"#fff"}/>
                            </div>
                        }
                        {
                            checkSaved ===true &&
                            <div className={"button-md button-success"}>
                                <BookmarkMinus color={"#fff"} />
                            </div>
                        }
                    </button>
                    <div onClick={onClick} className={" button-md button-warring-outline mt-2"}>
                        <Info/>
                    </div>
                </div>
            </div>
        </div>
    )
}
