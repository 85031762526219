import React, {useState} from 'react'
import '../../App.css'
import Mail from "../../components/icons/Mail";
import {Link, useNavigate} from "react-router-dom";
import ArrowLeft from "../../components/icons/ArrowLeft";
import {postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import Select from "react-select";
import {customStyles} from "../../components/common/SelectStyle";
import CustomAlert from "../../components/common/CustomAlert";

export default function Recovery() {

    const navigate = useNavigate();


    const [email, setEmail] = useState("");
    const [error, setError] = useState("")
    const [clicked, setClicked] = useState(false)


    //notification alert
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");



    //select options
    const [selectedOption, setSelectedOption] = useState(null);



    const changeLoginName = async () => {
        setClicked(true)
        try {
            const form = {
                type: 'EMAIL',
                value: email
            };
            const response = await postJson(`${API_URL}/api/core/signin/recovery?type=EMAIL`, form);
            if (response.success && response.status === 200) {
                const data = response.payload;
                if (data.type === "SUCCESS") {
                    setAlertType('success');
                    setAlertMessage("Амжилттай"); // Adjusted message for clarity
                    setAlertVisible(true);
                    setTimeout(() => {
                        setAlertVisible(false);
                    }, 2000);
                    navigate(`/otp`, { state: { userId: response.payload.userId } });

                }
                else {
                    setAlertType('error');
                    setAlertMessage("Хэрэглэгч олдсонгүй");
                    setAlertVisible(true);
                    setTimeout(() => {
                        setAlertVisible(false);
                    }, 2000);
                }
            }  setClicked(false)
        } catch (error) {
            setAlertType('error');
            setAlertMessage("Алдаа гарлаа. Дахин оролдоно уу.");
            setAlertVisible(true);
            setTimeout(() => {
                setAlertVisible(false);
            }, 2000);
            setClicked(false)
        }
    };



    const handleChangeType = (selectedOption) => {
        setSelectedOption(selectedOption);
        setError('');
        console.log("Selected option:", selectedOption);
    };


    const optionType = [
        { value: 'changePassword', label: 'Нууц үг солих' },
        { value: 'changeLoginName', label: 'Нэвтрэх нэр солих' }
    ];




    return (
        <div className={"container-center"} style={{height: "80vh"}}>
           <div>
               <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
               <Link to={"/auth"}>
                   <div className={"button-md button-primary w-1/6"}>
                       <ArrowLeft/>
                   </div>
               </Link>
               <div className={"recovery"}>
                   <div  className={"recovery-container"}>
                   {/*    <Select
                           styles={customStyles}
                           placeholder={"Төрөл сонгоно уу"}
                           options={optionType}
                           value={selectedOption}
                           onChange={handleChangeType}
                       />*/}
                       <div className={"title"}>
                           Баталгаажуулсан и-мэйл оруулна уу
                       </div>
                       <div className={"input-container mt-5"}>
                           <Mail stroke={"#fff"} fill={"#202020"}/>
                           <input
                               value={email}
                               onChange={e => setEmail(e.target.value)}
                               placeholder="И-мэйл"
                               className={"input"}/>
                       </div>
                       <div className="errorLabel">{error}</div>
                       <button  disabled={clicked} className="button-md button-primary mt-5" onClick={()=> changeLoginName()}>
                           {
                               clicked === false ?
                                   <div>
                                       Илгээх
                                   </div>

                                   :
                                   <div>
                                       Илгээж байна
                                   </div>
                           }
                       </button>
                   </div>
               </div>
           </div>
        </div>
    )
}


