import React, {useEffect, useRef, useState,} from 'react'
import HeaderCard from "../../common/header-author/Header-Card";
import CardYear from "../../cards/CardYear";
import "../../cards/cards.css"
import Slider from "react-slick";
import ChevronLeft from "../../icons/ChevronLeft";
import ChevronRight from "../../icons/ChevronRight";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import CustomPopup from "../../common/pop-up/CustomPopup";
import MovieModal from "../../modal/MovieModal";
export default function ThisYear() {


    const [data, setData] = useState([])
    const slickRef = useRef()
    const [isSliding, setIsSliding] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        fetchMovie();
    }, []);

    const fetchMovie = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?movieStatus=COMPLETED`);
            if ((await response).success) {
                setData((await response).payload.thisPageElements);
            } else {
                console.warn("getOngoingMovie", (await response).message);
            }
        } catch (e) {
        }
    }


    let settings = {
        dots: false,
        infinite: true,
        fade: false,
        arrows: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 4,
        autoplay: false,
        autoplaySpeed: 3500,
        className: "thisYear-slider-container",
        dotsClass: "thisYear-slider-pagination",
        beforeChange: () => setIsSliding(true),
        afterChange: () => setIsSliding(false),
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            }
        ]
    };

    const goNext = () => {
        if (slickRef.current)
            slickRef.current.slickNext()
    }
    const goPrev = () => {
        if (slickRef.current)
            slickRef.current.slickPrev()
    }

    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }

    return (
        <div className={"container"}>
            <div className="this-year">
                <HeaderCard title={"Энэ жилд"} />
                <div>
                    <Slider {...settings} ref={slickRef}>
                        {data.map((item, index) => (
                            <CardYear key={item.id} item={item} onClick={() => {
                                if (!isSliding) {
                                    openDialog(item);
                                }
                            }} />
                        ))}
                    </Slider>
                </div>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <MovieModal
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
                <div className="this-year-prev" onClick={goPrev}>
                  <ChevronLeft/>
                </div>
                <div className="this-year-next" onClick={goNext}>
                   <ChevronRight/>
                </div>
            </div>
        </div>
    )
}
