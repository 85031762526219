import React,{useState,useEffect} from 'react'
import {Link} from "react-router-dom";
import User from "../icons/User";
import Menu from "../icons/Menu";
import Wallet from "../icons/Wallet";
import BookmarkAdd from "../icons/Bookmark-add";
import Close from "../icons/Close";
import MenuDropdownMobile from "../menu-dropdown/Menu-Dropdown-Mobile";
import ProfileDropdownMobile from "../menu-dropdown/Profile-Dropdown-Mobile";
import SearchMobile from "../navbar/search-popup/Search-Mobile";
export default function FixedMenuMobile() {

    const [showMenu, setShowMenu] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [showSearch, setShowSearch] = useState(false)



    const toggleProfile = () => {
        if (!showProfile) {
            setShowProfile(true);
            setShowMenu(false);
        } else{
            setShowProfile(false);
            setShowMenu(false)
        }
    };

    const toggleMenu = () => {
        if (!showMenu) {
            setShowMenu(true);
            setShowProfile(false)
        } else{
            setShowMenu(false)
            setShowProfile(false);
        }
    };





    return (
        <>
            <div>
                <div className={"fixed-menu-mobile"}>
                    <button className={"button-md button-secondary-outline"} onClick={toggleMenu}>
                        {
                            !showMenu ?
                                <>
                                    <Menu/>
                                </>
                                :
                                <>
                                    <div style={{padding:"5px", backgroundColor:"#2e2e2e", borderRadius:"50%"}}>
                                        <Close stroke={"#fff"} />
                                    </div>
                                </>

                        }
                    </button>
                    <Link to="/saved" className="button-md button-secondary-outline">
                        <BookmarkAdd />
                    </Link>
                    <SearchMobile/>
                    <Link to="/guide" className="button-md button-secondary-outline  ">
                        <Wallet/>
                    </Link>
                    <button className={"button-md button-secondary-outline"} onClick={toggleProfile}>
                        {
                            !showProfile ?
                                <>
                                    <User/>
                                </>
                                :
                                <>
                                    <div style={{padding:"5px", backgroundColor:"#2e2e2e", borderRadius:"50%"}}>
                                        <Close stroke={"#fff"} />
                                    </div>
                                </>

                        }
                    </button>
                </div>
                {showMenu &&
                    <div className={`menu-dropdown-mobile-enter`}>
                        <MenuDropdownMobile show={toggleMenu}/>
                    </div>
                }
                {showProfile &&
                    <div className={`fixed-profile-mobile-enter`}>
                        <ProfileDropdownMobile show={toggleProfile}/>
                    </div>
                }

            </div>


        </>

    )
}
