import React, {useEffect, useRef, useState} from 'react'
import HeaderCard from "../../common/header-author/Header-Card";
import CardPopularList from "../../cards/CardPopularList";
import {fakeMovie} from "../../data/fakeData";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import ArrowRight from "../../icons/ArrowRight";
import {Link} from "react-router-dom";
import "../../../components/cards/cards.css"
import CustomPopup from "../../common/pop-up/CustomPopup";
import MovieModal from "../../modal/MovieModal";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";

export default function Popular() {

    const [asdasd] = fakeMovie;


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    const [dataSeason, setDataSeason] = useState([]);
    const [dataAllTime, setDataAllTime] = useState([]);
    const [dataThisYear, setDataThisYear] = useState([]);

    useEffect(() => {
        fetchMovieSeason();
        fetchMovieAllTime();
        fetchMovieThisYear();
    }, []);

    const fetchMovieSeason = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/stat?type=1&from=2024-01-01&to=2024-03-20&size=10`);
            if ((await response).success)
                setDataSeason((await response).payload);
        } catch (e) {
        }
    }
    const fetchMovieAllTime = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/stat?type=1&from=2023-01-01&to=2024-01-01&size=10`);
            if ((await response).success)
                setDataAllTime((await response).payload);
        } catch (e) {
        }
    }


    const fetchMovieThisYear = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/stat?type=1&from=2024-01-01&to=2024-03-20&size=10`);
            if ((await response).success)
                setDataThisYear((await response).payload);
        } catch (e) {
        }
    }



    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }



    return (
        <div className={"container"}>
            <HeaderCard title={"Хамгийн их хандалттай төслүүд"}/>
            <Tabs className={"popular__tabs mt-5"} selectedTabClassName="popular_react-tabs__tab__selected">
                <div className={"flex justify-between items-center"}>
                    <TabList className={"popular__tab-list"}>
                        <Tab className={"popular_react-tabs__tab"} >
                            Бүх цаг үед
                        </Tab>
                        <Tab className={"popular_react-tabs__tab"}>
                            Энэ улирал
                        </Tab>
                        <Tab className={"popular_react-tabs__tab"}>
                            Энэ жил
                        </Tab>
                    </TabList>
                    <Link to={"/popular"}>
                        <div className="button-more button-original-outline">
                            Цааш үзэх
                            <ArrowRight color="#00b3ff"/>
                        </div>
                        <div className="button-mobile">
                            <ArrowRight color="#A7A5A5"/>
                        </div>
                    </Link>
                </div>
                <TabPanel>
                    <div className={"mt-5 grid xl:grid-cols-3 lg:grid-cols-3 gap-4 md:grid-cols-2 sm:grid-cols-2 max-sm:grid-cols-1"}>
                        {
                            dataAllTime.map((item, index) => (
                                <CardPopularList key={index} item={item.movie} onClick={() => {openDialog(item.movie)}} />
                            ))
                        }
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={"mt-5 grid xl:grid-cols-3 lg:grid-cols-3 gap-4 md:grid-cols-2 sm:grid-cols-2 max-sm:grid-cols-1"}>
                        {
                            dataSeason.map((item, index) => (
                                <CardPopularList key={index} item={item.movie}  onClick={() => {openDialog(item.movie)}} />
                            ))
                        }
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={"mt-5 grid xl:grid-cols-3 lg:grid-cols-3 gap-4 md:grid-cols-2 sm:grid-cols-2 max-sm:grid-cols-1"}>
                        {
                            dataThisYear.map((item, index) => (
                                <CardPopularList key={index} item={item.movie}  onClick={() => {openDialog(item.movie)}} />
                            ))
                        }
                    </div>
                </TabPanel>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <MovieModal
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
            </Tabs>



        </div>
    )
}
