function Info(props) {
    const style = props.style ? props.style : {};
    const color = props.color ? props.color : "#FF6600";
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10.0003 13.3333V9.99999M10.0003 6.66666H10.0087M18.3337 9.99999C18.3337 14.6024 14.6027 18.3333 10.0003 18.3333C5.39795 18.3333 1.66699 14.6024 1.66699 9.99999C1.66699 5.39762 5.39795 1.66666 10.0003 1.66666C14.6027 1.66666 18.3337 5.39762 18.3337 9.99999Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Info;
