import React, {useEffect, useState} from 'react'
import HeaderCard from "../../components/common/header-author/Header-Card";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import { useNavigate} from "react-router-dom";
import {fakeMovie} from "../../components/data/fakeData";
import CardPopular from "../../components/cards/CardPopular";
import ArrowLeft from "../../components/icons/ArrowLeft";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import MovieModal from "../../components/modal/MovieModal";
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";

export default function PagePopular() {

    const navigate = useNavigate();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [dataSeason, setDataSeason] = useState([]);
    const [dataAllTime, setDataAllTime] = useState([]);
    const [dataThisYear, setDataThisYear] = useState([]);

    useEffect(() => {
        fetchMovieSeason();
        fetchMovieAllTime();
        fetchMovieThisYear();
    }, []);


    const fetchMovieSeason = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/stat?type=1&from=2024-01-01&to=2024-03-20&size=10`);
            if ((await response).success)
                setDataSeason((await response).payload);
        } catch (e) {
        }
    }
    const fetchMovieAllTime = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/stat?type=1&from=2023-01-01&to=2024-01-01&size=10`);
            if ((await response).success)
                setDataAllTime((await response).payload);
        } catch (e) {
        }
    }


    const fetchMovieThisYear = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/stat?type=1&from=2024-01-01&to=2024-03-20&size=10`);
            if ((await response).success)
                setDataThisYear((await response).payload);
        } catch (e) {
        }
    }


    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }

    return (
        <div className={"container"}>
            <Tabs className={"popular__tabs mt-5"} selectedTabClassName="popular_react-tabs__tab__selected">
                <div className={"flex flex-wrap justify-between"}>
                    <div className={"flex items-center gap-3"}>
                        <div className="button-mobile button-primary" onClick={() => navigate(-1)}>
                            <ArrowLeft />
                        </div>
                        <div className={"page-header-title"}>
                            Хамгийн их хандалттай төслүүд
                        </div>
                    </div>
                    <TabList className={"popular__tab-list"} activeTabClassName={"popular_react-tabs__tab__selected"}>
                        <Tab className={"popular_react-tabs__tab"}>
                            Бүх цаг үед
                        </Tab>
                        <Tab className={"popular_react-tabs__tab"}>
                            Энэ улирал
                        </Tab>
                        <Tab className={"popular_react-tabs__tab"}>
                            Энэ жил
                        </Tab>
                    </TabList>
                </div>
                <TabPanel>
                    <div className={"Popular"}>
                        {
                            dataAllTime.map((item, index) => (
                                <>
                                    <CardPopular key={item.id} item={item.movie} index={index} onClick={() => {openDialog(item.movie)}}/>
                                </>
                            ))
                        }
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={"Popular"}>
                        <div className={""}>
                            {
                                dataSeason.map((item, index) => (
                                    <>
                                        <CardPopular key={item.id} item={item.movie} index={index} onClick={() => {openDialog(item.movie)}}/>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={"Popular"}>
                        <div className={""}>
                            {
                                dataThisYear.map((item, index) => (
                                    <>
                                        <CardPopular key={item.id} item={item.movie} index={index} onClick={() => {openDialog(item.movie)}}/>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
            <CustomPopup open={isDialogOpen} close={handleClose}>
                <MovieModal
                    item={selectedItem}
                    shown={isDialogOpen}
                    setShown={value => setIsDialogOpen(value)}
                />
            </CustomPopup>
        </div>
    )
}
