function ArrowLeft(props) {

    const style = props.style ? {...props.style} : {}
    const stroke = props.stroke ? props.stroke : "#A7A5A5"
    const fill =  props.fill ? props.fill : "#A7A5A5"


    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={fill}>
            <path d="M16.6663 10H3.33301M3.33301 10L8.33301 15M3.33301 10L8.33301 5" stroke={stroke}
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ArrowLeft;
