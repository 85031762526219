import React from 'react'
import ArrowRight from "../../icons/ArrowRight";
import {Link} from "react-router-dom";

export default function HeaderAuthor({item,id}) {

    // console.log("item.name", item.name)


    if (item) {
        return (
            <div className={"card-author-header mt-5"}>
                <div className={"flex gap-3"}>
                    <div className="img">
                        <img src="images/naruto.jpeg" alt=""/>
                    </div>
                    <div>
                        <div className={"name"}>
                            {item.name}
                        </div>
                        {/*<div className={"age"}>
                            54
                        </div>*/}
                    </div>
                </div>
                <div>
                    <Link to={{pathname: `/author-collections/${id}`}}>
                        <button className="button-more button-primary">
                            Цааш...
                            <ArrowRight color="#A7A5A5"/>
                        </button>
                        <div className="button-mobile">
                            <ArrowRight color="#A7A5A5"/>
                        </div>
                    </Link>
                </div>
            </div>
        )

    }
}
