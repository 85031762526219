import React, {useEffect, useRef, useState} from "react";
import ReactPlayer from 'react-player'
import Play from "../icons/Play";
import Plus from "../icons/Plus";
import Chain from "../icons/Chain";
import ArrowRight from "../icons/ArrowRight";
import ArrowDown from "../icons/ArrowDown";
import SectionEpisode from "../../pages/episode/EpisodeSection";
import {get, postJson} from "sodon-http/API";
import {API_URL, FAKE_API} from "../../constans/API_URL";
import {Link,} from 'react-router-dom';
import Close from "../icons/Close";
import CustomAlert from "../common/CustomAlert";
import BookmarkMinus from "../icons/BookmarkMinus";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import VolumeUp from "../icons/VolumeUp";
import Muted from "../icons/Muted";
import ShowMore from "../icons/ShowMore";
import ShowLess from "../icons/ShowLess";


function MovieModal({item, shown, setShown}) {


    const [trailerException, setTrailerException] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [trailerMute, setTrailerMute] = useState(true);
    const [trailerPlaying, setTrailerPlaying] = useState(true);


    const [movieData, setMovieData] = useState([]);
    const [selectedSection, setSelectedSection] = useState(null);
    const [types, setTypes] = useState([]);

    const [animeSections, setAnimeSections] = useState([]);
    const [sectionEpisodes, setSectionEpisodes] = useState([]);
    const [episodes, setEpisodes] = useState([]);

    const [ovaEpisodes, setOvaEpisodes] = useState([]);
    const [songEpisodes, setSongEpisodes] = useState([]);
    const [animeMovie, setAnimeMovie] = useState([]);



    // хадгалах товч
    const [isLoading, setIsLoading] = useState(false)
    const [checkSaved, setCheckSaved] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");


    useEffect(() => {
        setTypes([{type: "episode", id: 1,}, {type: "ova", id: 2,}, {type: "movie", id: 3,}, {type: "song", id: 5,},])
    }, []);


    useEffect(() => {
        fetchMovieData();
    }, [item]);


    useEffect(() => {
        setIsLoading(true);
        const checkSaved = async () => {
            try {
                const response = await get(`${API_URL}/api/client/user/savedItem/check?module=movie&moduleItemId=${item.moduleItemId ? item.moduleItemId : item.id}`);
                if (response.success) {
                    const data = response.payload;
                    if (typeof data === 'boolean')
                        setCheckSaved(data);
                }
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };
        checkSaved();
    }, [item.id]);

    useEffect(() => {
        if (movieData) {
            if (movieData.sections && movieData.sections.length >= 1) {
                setAnimeSections(movieData.sections);
            }
            if (movieData.sections && movieData.sections.length === 0) {
                fetchEpisodes();
            }
        }
    }, [movieData])


    useEffect(() => {
        fetchSelectedSectionEpisode();
    }, [selectedSection])

    useEffect(() => {
        if (types && types.length > 0) {
            tryAllEpisodeTypes()
        }
    }, [types])


    const tryAllEpisodeTypes = async () => {
        for (let i = 0; i < types.length; i++) {
            if (types[i].type !== "episode") {
                await fetchEpisodesByType(types[i]);
            }
        }
    }


    const toggleSectionEpisodes = (item) => {
        if (selectedSection === item.id){
            setSelectedSection(null)
        }else {
            setSelectedSection(item.id)
        }
    };

    const fetchEpisodesByType = async (type) => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/list?movieId=${item.moduleItemId ? item.moduleItemId : item.id}&typeId=${type.id}`);
            if ((await response).success) {
                const data = response.payload
                if (type.type === "movie")
                    setAnimeMovie(data);
                else if (type.type === "song")
                    setSongEpisodes(data);
                else if (type.type === "ova")
                    setOvaEpisodes(data);
            }
        } catch (e) {

        }
    }

    const fetchMovieData = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/${item.moduleItemId ? item.moduleItemId : item.id}`);
            if ((await response).success) {
                setMovieData((await response).payload)
            }
        } catch (e) {

        }
    }


    const fetchSelectedSectionEpisode = async () => {
        if (selectedSection !== null){
            try {
                const response = await get(`${API_URL}/api/movie/${item.id}/${selectedSection}/episode`);
                if ((await response).success) {
                    setSectionEpisodes((await response).payload)
                }
            } catch (e) {

            }

        }

    }

    const fetchEpisodes = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/list?movieId=${item.moduleItemId ? item.moduleItemId : item.id}`);
            if ((await response).success) {
                setEpisodes((await response).payload)
            }
        } catch (e) {

        }
    }


    const handleSaveItem = async () => {
        setAlertVisible(true);
        try {
            const form = {
                module: 'MOVIE',
                moduleItemId: item.moduleItemId ? item.moduleItemId : item.id,
                moduleItemName: item.moduleItemName ? item.moduleItemName : item.name,
                moduleItemDesc: item.moduleItemDesc ? item.moduleItemDesc : item.desc,
                moduleItemImage:  item.moduleItemImage ? item.moduleItemImage : `${item.image?.name}.${item.image?.ext}`
            };
            // Make the POST request
            const response = await postJson(`${API_URL}/api/client/user/savedItem/saveOrDelete`, form);
            if (response.status === 200 && response.success) {
                setCheckSaved(!checkSaved);
                setAlertType(!checkSaved === true ? 'success' : 'warning');
                setAlertMessage(!checkSaved === true ? 'Амжилттай хадгалагдлаа ' : 'Амжилттай устгагдлаа')
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);
            }
        } catch (error) {

        }
    };

    const toggleMuteTrailer = () => {
        setTrailerMute(!trailerMute);
    };

    const togglePlayTrailer = () => {
        setTrailerPlaying(!trailerPlaying);
    };

    const [currentEpisode, setCurrentEpisode] = useState(null)




    function renderMovieSections() {
        return (
            <TabPanel>
                <div className={`movie-modal-section ${selectedSection !== null ? 'active' : ''}`}>
                    {animeSections && animeSections.map((item, index) => (
                        <>
                            <div key={`${item.id}`} className={`player-section ${selectedSection === item.id ? 'active' : ''}`}
                                 onClick={() => toggleSectionEpisodes(item)}>
                                <div className={"title"}>
                                    {item.name}
                                </div>
                                {selectedSection === item.id ?
                                    <div>
                                        <ArrowDown/>
                                    </div>
                                    :
                                    <div>
                                        <ArrowRight/>
                                    </div>

                                }
                            </div>
                            <div>
                                {selectedSection === item.id && (
                                    <div className="player-section-body-active">
                                        <div>{
                                            sectionEpisodes.length > 1 ?
                                                <div>
                                                    {sectionEpisodes.map((item, index) => (
                                                        <Link to={`/player/episode/${item.id}`}>
                                                            <SectionEpisode key={index} item={item} onClick={item => setCurrentEpisode(item)}/>
                                                        </Link>

                                                    ))}
                                                </div>
                                                :
                                                <div>
                                                    Анги байхгүй байна
                                                </div>
                                        }

                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    ))}
                    {episodes.map((item, index) => (
                        <Link to={`/player/episode/${item.id}`}>
                            <SectionEpisode key={index} item={item} onClick={item => setCurrentEpisode(item)}/>
                        </Link>
                    ))}
                </div>
            </TabPanel>
        );
    }
    function renderOVA() {
        return (
            <TabPanel>
                <div className={"movie-modal-section"}>
                    {
                        ovaEpisodes.length > 1 ?
                            (
                                ovaEpisodes.map((item, index) => (
                                    <Link to={`/player/episode/${item.id}`}>
                                        <SectionEpisode key={index} item={item} onClick={item => setCurrentEpisode(item)}/>
                                    </Link>
                                ))
                            )
                            :
                            (
                                <div style={{color: "#C1BFBF"}}>
                                    Тусгай анги байхгүй
                                </div>
                            )
                    }
                </div>
            </TabPanel>
        );
    }
    function renderSpecialMovie() {
        return (
            <TabPanel>
                <div className={"movie-modal-section"}>
                    {
                        animeMovie.length > 1 ?
                            (
                                animeMovie.map((item, index) => (
                                    <Link to={`/player/episode/${item.id}`}>
                                        <SectionEpisode key={index} item={item} onClick={item => setCurrentEpisode(item)}/>
                                    </Link>
                                ))
                            )
                            :
                            (
                                <div style={{color: "#C1BFBF"}}>
                                    Кино байхгүй
                                </div>
                            )
                    }
                </div>
            </TabPanel>
        );
    }
    function renderSong() {
        return (
            <div>
                <TabPanel>
                    <div className={"movie-modal-section"}>
                        {
                            songEpisodes.length > 1 ?
                                (
                                    songEpisodes.map((item, index) => (
                                        <Link to={`/player/episode/${item.id}`}>
                                            <SectionEpisode key={index} item={item} onClick={item => setCurrentEpisode(item)}/>
                                        </Link>
                                    ))
                                )
                                :
                                (
                                    <div style={{color:"#C1BFBF"}}>
                                        Дуу байхгүй
                                    </div>
                                )
                        }
                    </div>
                </TabPanel>
            </div>
        );
    }


    if (movieData) {
        return (
            <>
                <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
                <div className={"movie-modal-container__video"}>
                    <button className={"button-close"} onClick={() => setShown(false)}>
                        <Close stroke={"#fff"} style={{width: 20, height: 20}}/>
                    </button>
                    {
                        trailerMute === true ?
                            <button className={"button-muted"} onClick={toggleMuteTrailer}>
                                <Muted stroke="#fff" style={{width: 20, height: 20}}/>
                            </button>
                            :
                            <button className={"button-muted"} onClick={toggleMuteTrailer}>
                                <VolumeUp stroke="#fff" style={{width: 20, height: 20}}/>
                            </button>

                    }
                    {
                        trailerPlaying === true ?
                            <button className={"button-play"} onClick={togglePlayTrailer}>
                                <svg width="20" height="20"  viewBox="0 0 14 14" fill={"#fff"}
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="4" y="1" width="2" height="12" fill={"#fff"}/>
                                    <rect x="8" y="1" width="2" height="12" fill={"#fff"}/>
                                </svg>
                            </button>
                            :
                            <button className={"button-play"} onClick={togglePlayTrailer}>
                                <svg width="20" height="20" viewBox="0 0 14 14" fill={"#fff"}
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 1V13L11 7L3 1Z" />
                                </svg>
                            </button>
                    }

                    {
                        shown &&
                        <>
                            {
                                trailerException ?
                                    <img
                                        src={`${FAKE_API}/resource/${movieData?.image?.path}`}
                                        alt=""
                                    />
                                    :
                                    <ReactPlayer
                                        loop={false}
                                        playing={trailerPlaying}
                                        muted={trailerMute}
                                        height={"100%"}
                                        width={"100%"}
                                        url={movieData?.trailer}
                                        onError={error => {
                                            setTrailerException(true);

                                        }}
                                        onBuffer={() => {
                                            setTrailerException(false);
                                        }}
                                        onEnded={e => {
                                            setTrailerException(true);
                                        }}
                                        stopOnUnmount
                                    />
                            }
                        </>
                    }
                </div>
                <div className={"movie-modal-container"}>
                    <div className={"movie-modal gap-5 grid grid-cols-12 w-full"}>
                        <div className={"content col-span-12 lg:col-span-5"}>
                            <div>
                                {
                                    movieData.image &&
                                    <div className={"cover-img h-0 lg:h-auto"}>
                                        <img
                                            src={`https://www.mnfansubs.net/resource/${movieData.image.name}.${movieData.image.ext}`}
                                            alt=""/>
                                    </div>

                                }
                                <div className={"mt-2 gap-2 grid-cols-2 grid"}>
                                    <Link to={`/player/episode/${episodes.id}`}>
                                        <button className="button-md button-original w-full">
                                            <Play color={"#fff"}/>
                                            Эхлүүлэх
                                        </button>
                                    </Link>
                                    <div>
                                        {
                                            checkSaved === false &&
                                            <>
                                                <button onClick={handleSaveItem}
                                                        className="button-md button-primary w-full">
                                                    <Plus color={"#DFDFDF"}/>Хадгалах
                                                </button>
                                            </>
                                        }
                                        {
                                            checkSaved === true &&
                                            <>
                                                <button onClick={handleSaveItem} className="button-md button-success w-full">
                                                    <BookmarkMinus color={"#FFF"}/>
                                                    Хадгалсан
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className={"categories"}>
                                    <div className={"mt-2 mb-2"}>Төрөл:</div>
                                    <ul>
                                        {movieData.categories && movieData.categories.map(item => (
                                            <li key={item.id}><Chain/>{item.name}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={"info"}>
                                    <div>
                                        <span>Гарч эхэлсэн:</span>
                                        <span>{movieData.year}</span>
                                    </div>
                                    <div>
                                        <span>Англи нэр:</span>
                                        <span>{movieData.nameEn}</span>
                                    </div>
                                    {
                                        movieData.totalNumber &&
                                        <div>
                                            <span>Ангийн тоо:</span>
                                            <span>{movieData.totalNumber}</span>
                                        </div>
                                    }
                                    <div>
                                        <span>Төлөв:</span>
                                        {movieData.movieStatus === "ONGOING" && <span>Гарч байгаа</span>}
                                        {movieData.movieStatus === "COMPLETED" && <span>Дууссан</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-span-12 lg:col-span-7"}>
                            <div className={"flex justify-between"}>
                                <div className={"title"}>{movieData.name}</div>
                            </div>
                            {
                                movieData.description &&
                                <>
                                    <div className={"desc "} onClick={() => setShowDescription(!showDescription)}>
                                        {showDescription ? movieData.description : `${movieData.description.substring(0, 250)}...`}
                                    </div>

                                </>
                            }
                            <div>
                                <Tabs className={"player_section__tabs mt-5"}  selectedTabClassName={"player_section__tab--selected"}>
                                    <TabList className={"player_section__list"}>
                                        <Tab className={"player_section__tab"}>Ангиуд</Tab>
                                        {
                                            ovaEpisodes.length > 0 &&
                                            <Tab className={"player_section__tab"}>Тусгай анги</Tab>
                                        }
                                        {
                                            animeMovie.length > 0 &&
                                            <Tab className={"player_section__tab"}>Кино</Tab>
                                        }
                                        {
                                            songEpisodes.length > 0 &&
                                            <Tab className={"player_section__tab"}>Дуу</Tab>
                                        }
                                    </TabList>
                                    {renderMovieSections()}
                                    {renderOVA()}
                                    {renderSpecialMovie()}
                                    {renderSong()}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


}

export default MovieModal;
