import React, {useEffect, useState} from 'react'
import {API_URL} from "../../constans/API_URL";
import {Link} from "react-router-dom";
import {get} from "sodon-http/API";
import Slider from "react-slick";
import CustomPopup from "../common/pop-up/CustomPopup";
import MovieModal from "../modal/MovieModal";

export default function CardAuthor({collectionId}) {


    const [data, setData] = useState([]);
    const [isSliding, setIsSliding] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);



    useEffect(() => {
        getCollectionMovie();
    }, []);


    const getCollectionMovie = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/collection/${collectionId}`);
            if ((await response).success) {
                setData((await response).payload);
            } else {

            }
        } catch (e) {
        }
    }

    let vars = {
        dots: true,
        infinite: true,
        fade: false,
        arrows: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        autoplaySpeed: 2500,
        className: "ofAuthor-slider-container",
        dotsClass: "slick-dots slider-over-dots",
        beforeChange: () => setIsSliding(true),
        afterChange: () => setIsSliding(false),
    };


    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }



    return (
        <div>
            <Slider {...vars}>
                {
                    data.map((item, index) => (
                        <div key={`${item.id}`} className="card-author">
                            <div className="img" onClick={() => {
                                if (!isSliding) {
                                    openDialog(item.movie);
                                }
                            }} >
                                <img src={`https://www.mnfansubs.net/resource/${item.movie?.image?.name}_s.${item.movie?.image?.ext}`}/>
                            </div>
                        </div>
                    ))
                }

            </Slider>
            <CustomPopup open={isDialogOpen} close={handleClose}>
                <MovieModal
                    item={selectedItem}
                    shown={isDialogOpen}
                    setShown={value => setIsDialogOpen(value)}
                />
            </CustomPopup>
        </div>

    )
}
