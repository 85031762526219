function BookmarkAdd(props) {

    const style = props.style ? {...props.style} : {}
    const color = props.stroke  ?  props.stroke : "#A7A5A5";

    return (
        <svg style={style} width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="bookmark-add">
                <path id="Icon"
                      d="M9.99996 10.8333V5.83333M7.49996 8.33333H12.5M15.8333 17.5V6.5C15.8333 5.09987 15.8333 4.3998 15.5608 3.86502C15.3211 3.39462 14.9387 3.01217 14.4683 2.77248C13.9335 2.5 13.2334 2.5 11.8333 2.5H8.16663C6.76649 2.5 6.06643 2.5 5.53165 2.77248C5.06124 3.01217 4.67879 3.39462 4.43911 3.86502C4.16663 4.3998 4.16663 5.09987 4.16663 6.5V17.5L9.99996 14.1667L15.8333 17.5Z"
                      stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>

    )
}

export default BookmarkAdd;
