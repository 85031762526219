import React, {useEffect, useState} from 'react'
import {get} from "sodon-http/API"
import {API_URL} from "../../constans/API_URL";
import HeaderCard from "../../components/common/header-author/Header-Card";
import CardCommon from "../../components/cards/CardCommon";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import ArrowLeft from "../../components/icons/ArrowLeft";
import {storeScrollPosition} from "../../constans/StoreUserData";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import MovieModal from "../../components/modal/MovieModal";
export default function PagesAuthor() {

    const navigate = useNavigate();
    const {id} = useParams();
    const [movieData, setMovieData] = useState([])

    useEffect(() => {
        getCollectionMovie();
    }, []);

    const getCollectionMovie = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/collection/${id}`);
            if ((await response).success) {
                setMovieData((await response).payload);
            }
        } catch (e) {
        }
    }


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }

    return (
        <>
            <div className={"container"}>
                <div className="ongoing">
                    <div className={"flex items-center gap-3"}>
                        <div className="button-md button-primary" onClick={() => navigate(-1)}>
                            <ArrowLeft />
                        </div>
                        <div className={"page-header-title"}>
                            {movieData[0]?.collection.name}
                        </div>
                    </div>
                    <div className={"grid mt-3 lg:grid-cols-6 gap-4 md:grid-cols-5 sm:grid-cols-4 max-sm:grid-cols-2"}>
                        {
                            movieData.map((item, index) => (
                                <>
                                    <CardCommon
                                        key={index}
                                        item={item}
                                        onClick={() => {openDialog(item.movie)}} />
                                </>
                            ))
                        }
                    </div>
                    <CustomPopup open={isDialogOpen} close={handleClose}>
                        <MovieModal
                            item={selectedItem}
                            shown={isDialogOpen}
                            setShown={value => setIsDialogOpen(value)}
                        />
                    </CustomPopup>
                </div>
            </div>
        </>
    )
}
