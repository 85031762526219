import React, {useState} from 'react'
import '../../App.css'
import Mail from "../../components/icons/Mail";
import {Link, useLocation, useNavigate} from "react-router-dom";
import ArrowLeft from "../../components/icons/ArrowLeft";
import {postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import CustomAlert from "../../components/common/CustomAlert";
import Key from "../../components/icons/Key";

export default function Otp() {

    const navigate = useNavigate();

    const [token, setToken] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')


    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false);


    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");

    const location = useLocation();
    const { userId } = location.state || {};





    const changePassword = async () => {
        try {
            const form = {
                userId,
                token,
                newPassword,
                confirmPassword
            }
            const response = await postJson(`${API_URL}/api/core/signin/password`, form);
            if (response.success && response.status === 200) {
                const data = response.payload;
                if (data.type === "SUCCESS") {
                    setAlertType('success');
                    setAlertMessage("Амжилттай солигдлоо"); // Assuming response.payload is not directly renderable
                    setAlertVisible(true);

                    setTimeout(() => {
                        setAlertVisible(false); // First, hide the alert
                        navigate('/auth'); // Then navigate to the desired route
                    }, 2000); // Adjust the timeout duration if needed
                }
                else {
                    const _errors = {}
                    const data = await response.payload
                    data?.errors?.forEach(error => {
                        _errors[error.field] = error.defaultMessage;
                    })
                    console.log("__________",response.payload);
                    setError(_errors);
                    setAlertType('error');
                    setAlertMessage(response.payload.text);
                    setAlertVisible(true);
                    setTimeout(() => {
                        setAlertVisible(false);
                    }, 2000);
                }
            }
        } catch (error) {
            setAlertType('error');
            setAlertMessage("Алдаа гарлаа. Дахин оролдоно уу.", error);
            setAlertVisible(true);
            setTimeout(() => {
                setAlertVisible(false);
            }, 2000);
        }
    };

    const ReturnCorrespondingErrorMessage = ({field}) => {
        if (error[field]) {
            return (
                <div
                    style={{
                        width: "100%",
                        transform: "translateY(-3px)",
                        textAlign: "left"
                    }}>
                    <label htmlFor={field} className="errorLabel" style={{fontSize: "14px", lineHeight: 1}}>{error[field]}</label>
                </div>
            )
        } else {
            return <></>
        }
    }





    return (
        <div className={"container-center"}>
            <div>
                <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
                <Link to={"/auth"}>
                    <div className={"button-md button-primary w-1/6"}>
                        <ArrowLeft/>
                    </div>
                </Link>
                <div>
                    <div className={"recovery"}>
                        <div className={"recovery-container gap-2"}>
                            <div style={{color:"#fff"}}>
                                И-мэйлээр явуулсан  баталгаажуулах кодыг оруулна уу
                            </div>
                            <div className={"input-container"}>
                                <Key stroke={"#fff"} fill={"#202020"}/>
                                <input
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                    placeholder="Token"
                                    className={"input"}/>

                            </div>
                            <ReturnCorrespondingErrorMessage field="token"/>
                            <div className={"input-container"}>
                                <Key stroke={"#fff"} fill={"#202020"}/>
                                <input
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Шинэ нууц үг"
                                    className={"input"}/>
                            </div>
                            <ReturnCorrespondingErrorMessage field="newPassword"/>
                            <div className={"input-container"}>
                                <Key stroke={"#fff"} fill={"#202020"}/>
                                <input
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="Нууц үг давтах"
                                    className={"input"}/>
                            </div>
                            <ReturnCorrespondingErrorMessage field="passwordConfirmed"/>
                            <ReturnCorrespondingErrorMessage field="confirmPassword"/>
                            <button className="button-md button-primary" onClick={() => changePassword()} >
                                {
                                    isLoading === false ?
                                        <div>
                                            Илгээх
                                        </div>

                                        :
                                        <div>
                                            Илгээж байнаа
                                        </div>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
