import {ReactSVG} from "react-svg";
import React, {useEffect, useRef, useState} from "react";
import Close from "../../icons/Close";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import CustomPopup from "../../common/pop-up/CustomPopup";
import MovieModal from "../../modal/MovieModal";

function SearchPop() {

    const [buttonExists, setButtonExists] = useState(true);
    const [searchExists, setSearchExists] = useState(false);
    const inputRef = useRef(null);

    //popup
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    const buttonRef = useRef(null);
    const searchWrapperRef = useRef(null);
    const searchCloseRef = useRef(null);
    const resultsRef = useRef();



    //Search props
    const [query, setQuery] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [showResult, setShowResult] = useState(true);

    useEffect(() => {
        if (buttonRef.current && searchWrapperRef.current) {
            buttonRef.current.addEventListener("mouseup", onMouseUpButton);
            searchCloseRef.current.addEventListener("mouseup", onMouseUpSearchClose);
        }
        return () => {
            buttonRef.current && buttonRef.current.removeEventListener("mouseup", onMouseUpButton, false);
            searchCloseRef.current && searchCloseRef.current.removeEventListener("mouseup", onMouseUpSearchClose, false);
        }
    }, []);
    useEffect(() => {
        if (query !== "") {
            searchMovie()
        } else {
            hideSearchResults();
        }
    }, [query])

    useEffect(() => {
        if (searchData.length > 0) {
            showSearchResults();
        } else if (query === "" || !searchData.length) {
            hideSearchResults();
        }
    }, [searchData]);

    useEffect(() => {
        if (!searchExists) {
            hideSearchResults();
        } else {
            if (query !== "" && searchData.length) {
                setTimeout(() => {
                    showSearchResults();
                }, 300)
            }
        }
    }, [searchExists])


    function onMouseUpButton(e) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const searchRect = searchWrapperRef.current.getBoundingClientRect();
        if (searchWrapperRef.current) {
            searchWrapperRef.current.style.left = `-${searchRect.width - buttonRect.width}px`;
            searchWrapperRef.current.style.opacity = `1`;
            searchWrapperRef.current.style.pointerEvents = `fill`;
            buttonRef.current.style.opacity = `0`;
            buttonRef.current.style.pointerEvents = `none`;
        }
    }

    function onMouseUpSearchClose(e) {
        if (searchWrapperRef.current) {
            searchWrapperRef.current.style.left = "100%";
            searchWrapperRef.current.style.opacity = `0`;
            searchWrapperRef.current.style.pointerEvents = `none`;
            buttonRef.current.style.opacity = `1`;
            buttonRef.current.style.pointerEvents = `fill`;
        }
    }

    function toggleSearch(toggle = true) {
        setQuery("");
        if (toggle) {
            setSearchExists(true);
            setButtonExists(false);
        } else {
            setSearchExists(false);
            setButtonExists(true);
        }
    }



    const searchMovie = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?q=${query}&pageSize=40`);
            if ((await response).success) {
                setSearchData((await response).payload.thisPageElements);
            }
        } catch (e) {

        }
    }


    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }

    function showSearchResults() {
        if (searchExists && resultsRef.current) {
            const searchRect = searchWrapperRef.current.getBoundingClientRect();
            resultsRef.current.style.opacity = '1';
            resultsRef.current.style.pointerEvents = "fill";
            resultsRef.current.style.left = `${searchRect.left}px`;
            resultsRef.current.style.top = `${searchRect.top + searchRect.height}px`;
        }
    }
    function hideSearchResults() {
        if (resultsRef.current) {
            resultsRef.current.style.opacity = '0';
            resultsRef.current.style.pointerEvents = "none";
        }
    }




    return (
        <>
            <div className="nav-search-pop" ref={searchWrapperRef}>
                <ReactSVG
                    src="icons/search-refraction.svg"
                    fill="#fff"
                />
                <input
                    ref={inputRef}
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Анимэ, Манга хайх..."
                />
                <button ref={searchCloseRef} onClick={() => toggleSearch(false)}>
                    <Close stroke={"#fff"}/>
                </button>
            </div>
            <div>
                <button
                    ref={buttonRef}
                    id="search-button"
                    onClick={() => {
                        toggleSearch(true);
                    }}
                    className="button-nav"
                >
                    <ReactSVG src="icons/search-refraction.svg" fill="#fff"/>
                </button>
                <div>
                    <div ref={resultsRef} className={"search-result"}>
                        {searchData && searchData.map((item, index) => (
                            <ul className={"grid grid-cols-6 gap-2"} key={index} onClick={() => {openDialog(item)}}>
                                <div>
                                    <img src={`https://www.mnfansubs.net/resource/${item.image?.name}.${item.image?.ext}`}
                                         alt={item.name}/>
                                </div>
                                <div className={"title col-span-4"}>
                                    <span>{item.name}</span>
                                </div>
                            </ul>
                        ))}
                    </div>

                </div>

            </div>

            <CustomPopup open={isDialogOpen} close={handleClose}>
                <MovieModal
                    item={selectedItem}
                    shown={isDialogOpen}
                    setShown={value => setIsDialogOpen(value)}
                />
            </CustomPopup>
        </>
    )
}

export default SearchPop;
