import React, { useEffect, useState } from 'react'
import HeaderCard from "../../components/common/header-author/Header-Card";
import CardCommon from "../../components/cards/CardCommon";
import {API_URL} from "../../constans/API_URL";
import {get} from "sodon-http/API";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import MovieModal from "../../components/modal/MovieModal";

export default function PageOnlyMoviesSeries() {

    const [movies, setMovies] = useState([]);

    useEffect(() => {
        fetchMovies();
    }, []);

    const fetchMovies = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?type=4`);
            if ((await response).success) {
                setMovies((await response).payload.thisPageElements);
            }
        } catch (e) {
        }
    }


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }


    return (
        <div className={"container"}>
            <div>
                <div>
                    <HeaderCard title={"Цуврал кино"} />
                    <div>
                        <div className={"mt-5 grid xl:grid-cols-6 lg:grid-cols-6 gap-4 md:grid-cols-5 sm:grid-cols-4 max-sm:grid-cols-2"}>
                            {movies.map((item, index) => (
                                <CardCommon key={item.id} item={item} index={index}  onClick={() => {openDialog(item)}}/>
                            ))}
                        </div>
                    </div>
                </div>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <MovieModal
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
            </div>
        </div>
    );
}
