function Login(props) {
    const color = props.color ? props.color : "#a7a5a5";
    const style = props.style ? props.style : {};

    return (
        <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 8C11 8.125 10.9479 8.23958 10.8438 8.34375L6.34375 12.8438C6.11458 13.0521 5.88542 13.0521 5.65625 12.8438C5.44792 12.6146 5.44792 12.3854 5.65625 12.1562L9.28125 8.5H0.5C0.1875 8.47917 0.0208333 8.3125 0 8C0.0208333 7.6875 0.1875 7.52083 0.5 7.5H9.28125L5.65625 3.84375C5.44792 3.61458 5.44792 3.38542 5.65625 3.15625C5.88542 2.94792 6.11458 2.94792 6.34375 3.15625L10.8438 7.65625C10.9479 7.76042 11 7.875 11 8ZM13.5 1C14.2083 1.02083 14.8021 1.26042 15.2812 1.71875C15.7396 2.19792 15.9792 2.79167 16 3.5V12.5C15.9792 13.2083 15.7396 13.8021 15.2812 14.2812C14.8021 14.7396 14.2083 14.9792 13.5 15H10.5C10.1875 14.9792 10.0208 14.8125 10 14.5C10.0208 14.1875 10.1875 14.0208 10.5 14H13.5C13.9167 13.9792 14.2708 13.8333 14.5625 13.5625C14.8333 13.2708 14.9792 12.9167 15 12.5V3.5C14.9792 3.08333 14.8333 2.72917 14.5625 2.4375C14.2708 2.16667 13.9167 2.02083 13.5 2H10.5C10.1875 1.97917 10.0208 1.8125 10 1.5C10.0208 1.1875 10.1875 1.02083 10.5 1H13.5Z"
                fill={color}/>
        </svg>
    )
}

export default Login;
