import React, {useEffect, useState} from 'react'
import Star from "../icons/Star";
import Play from "../icons/Play";
import Heart from "../icons/Heart";
import BookmarkAdd from "../icons/Bookmark-add";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import BookmarkMinus from "../icons/BookmarkMinus";
import CustomAlert from "../common/CustomAlert";


export default function CardOngoing({item, onClick}) {


    const [isLoading, setIsLoading] = useState(false)

    const [checkSaved, setCheckSaved] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await get(`${API_URL}/api/client/user/savedItem/check?module=movie&moduleItemId=${item.id}`);
                if (response.success) {
                    const data = response.payload;
                    if (typeof data === 'boolean') {
                        setCheckSaved(data);
                    }
                }
            } catch (error) {

            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [item.id]);




    const handleSaveItem = async () => {
        setAlertVisible(true);
        try {
            const form = {
                module: 'MOVIE',
                moduleItemId: item.id,
                moduleItemName: item.name,
                moduleItemDesc: item.description,
                moduleItemImage: `${item.image?.name}.${item.image?.ext}`
            };
            // Make the POST request
            const response = await postJson(`${API_URL}/api/client/user/savedItem/saveOrDelete`, form);
            if (response.status === 200 && response.success) {
                setCheckSaved(!checkSaved);
                setAlertType(!checkSaved === true ? 'success' : 'warning');
                setAlertMessage(!checkSaved === true ? 'Амжилттай хадгалагдлаа ' : 'Амжилттай устгагдлаа')
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error saving item:", error);
            alert('Алдаа', error);
        }
    };




    return (
        <>
            <div className="cart-ongoing">
                <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
            {/*    <div className="status">
                    <span>{item.movieStatus}</span>
                </div>*/}
                <div className="img">
                    <img src={`https://www.mnfansubs.net/resource/${item.image?.name}_s.${item.image?.ext}`} alt="" />
                </div>
                <div className={"overlay"}>
                    <div onClick={onClick} className="about">
                        <div className={"title"}>
                        <span>
                            {item.name}
                        </span>
                        </div>
                        {/*    {
                        item.rating &&
                        <div className={"rating"}>
                            <Star fill={"#FF4418"}/>
                            <span>
                        {item.rating}
                        </span>
                        </div>

                    }*/}
                        {
                            item.description &&
                            <div className={"desc"}>
                        <span>
                            {item.description}
                        </span>
                            </div>
                        }
                        <div className={"sub"}>
                            {/*<div className={"flex"}>
                        <span>
                               Англи нэр:
                        </span>
                        <span>
                            {item.nameEn}
                        </span>
                    </div>*/}
                            {
                                item.year &&
                                <div className={"flex"}>
                                <span>
                                    Гарч эхэлсэн:
                                </span>
                                    <span>
                                    {item.year}
                                </span>
                                </div>
                            }
                            {
                                item.totalNumber &&
                                <div className={"flex"}>
                                <span>
                                    Ангийн тоо:
                                </span>
                                    <span>
                                    {item.totalNumber}
                                </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={"buttons"}>
                        <button onClick={onClick} className={"svg"}>
                            <Play color={"#fff"}/>
                        </button>
                        {/*   <div className={"button-master-sm button-master-primary"}>
                        <Heart stroke={"#fff"}/>
                    </div>*/}
                        <button onClick={handleSaveItem}>
                            {
                                checkSaved ===false &&
                                <div className={"button-master-sm button-master-primary"}>
                                    <BookmarkAdd color={"#fff"}/>
                                </div>
                            }
                            {
                                checkSaved ===true &&
                                <div className={"button-master-sm button-success"}>
                                    <BookmarkMinus color={"#fff"} />
                                </div>
                            }
                        </button>
                    </div>
                </div>
            </div>
            <div className={"card-common-mobile"} onClick={onClick}>
                <div className={"img"} >
                    <img src={`https://www.mnfansubs.net/resource/${item.image?.name}.${item?.image?.ext}`} />
                </div>
                <div className={"about"}>
                    {
                        item.score &&
                        <div className={"rating"}>
                            <Star stroke={"#FF6600"}/>
                            <span>{item.score}</span>
                        </div>

                    }
                    <div className={"title"}>
                        <a>{item.name}</a>
                    </div>
                    <div className={"flex gap-1"}>
                        <div>
                            <span>{item.year}</span>
                        </div>
                        {
                            item.totalNumber &&
                            <div>
                                <span>• {item.totalNumber} анги</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>


    )
}
