import React,{useState} from 'react'
import ArrowRight from "../../icons/ArrowRight";
import Login from "../../icons/Login";
import {Link} from "react-router-dom";

export default function HeaderCard(props) {
    const title = props.title ;
    const link = props.link ? props.link : "/" ;


    return (
        <div className="header-card">
            <div className="flex justify-between items-center flex-1">
                <div className="title">
                    {title}
                </div>
                <div>
                    {
                        props.link &&
                        <>
                            <Link to={link}>
                                <div className="button-more button-original-outline">
                                    Цааш үзэх
                                    <ArrowRight color="#DFDFDF"/>
                                </div>
                                <div className="button-mobile">
                                    <ArrowRight color="#A7A5A5"/>
                                </div>
                            </Link>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
