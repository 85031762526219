import React, {useEffect, useState} from 'react'
import HeaderCard from "../../common/header-author/Header-Card";
import CardCommon from "../../cards/CardCommon";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import CardOngoing from "../../cards/CardOngoing";
import CustomPopup from "../../common/pop-up/CustomPopup";
import MovieModal from "../../modal/MovieModal";

export default function Ghibli() {




    const [data, setData] = useState([]);


    useEffect(() => {
        getGhibliCollection();
    }, []);

    const getGhibliCollection = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/collection/15`);
            if ((await response).success) {
                setData((await response).payload.slice(0,6));
            } else {

            }
        } catch (e) {
        }
    }


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }


    return (
        <div className={"container"}>
            <div className={"common"}>
                <HeaderCard title={"Жибли цуглуулга"} link={`/author-collections/15`} />
                <div className={"mt-5 grid xl:grid-cols-6 lg:grid-cols-6 gap-4 md:grid-cols-3 sm:grid-cols-3 max-sm:grid-cols-3"}>

                    {
                        data.map((item, index) => (
                             <CardCommon key={index} item={item} onClick={() => {openDialog(item.movie)}} />
                        ))
                    }

                </div>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <MovieModal
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
            </div>
        </div>
    )
}
