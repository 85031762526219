import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import User from "../../components/icons/User";
import Signup from "./Signup";
import Signin from "./Signin";
import Login from "../../components/icons/Login";


export default function AuthTabs() {
    return (
        <div>
            <div className={"container-center"} style={{height: "80vh"}}>
                <Tabs className={"auth__tabs"} selectedTabClassName={"auth__tabs--selected"}>
                    <TabList className={"auth__tab-list"}>
                        <Tab className={"auth_react-tabs__tab"}>
                            <Login style={{ width: '20px', height: '20px' }}/>
                            Нэвтрэх
                        </Tab>
                        <Tab className={"auth_react-tabs__tab"}>
                            <User style={{ width: '20px', height: '20px' }}/>
                            Бүртгүүлэх
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <Signin/>
                    </TabPanel>
                    <TabPanel>
                        <Signup/>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}
