function Chain(props) {

    const style = props.style ? {...props.style} : {}
    const color = props.stroke ? props.stroke : "#A7A5A5"


    return (
        <svg style={style} width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.37508 0.59961H4.08341C2.47258 0.59961 1.16675 1.90545 1.16675 3.51628C1.16675 5.12711 2.47258 6.43294 4.08341 6.43294H5.25008C6.86091 6.43294 8.16675 5.12711 8.16675 3.51628M9.62508 6.43294H9.91675C11.5276 6.43294 12.8334 5.12711 12.8334 3.51628C12.8334 1.90545 11.5276 0.599609 9.91675 0.599609H8.75008C7.13925 0.599609 5.83342 1.90545 5.83342 3.51628" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Chain;



