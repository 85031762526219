import React, {useEffect, useState} from 'react'
import {get} from "sodon-http/API"
import {API_URL} from "../../constans/API_URL";
import CardCommon from "../../components/cards/CardCommon";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import MovieModal from "../../components/modal/MovieModal";
import ArrowLeft from "../../components/icons/ArrowLeft";
import {useNavigate} from "react-router-dom";
import ArrowRight from "../../components/icons/ArrowRight";

export default function PageAnimeOngoing() {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [pages, setPages] = useState(null);


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    useEffect(() => {
        fetchAnimeOngoing();
    }, []);

    const fetchAnimeOngoing = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?movieStatus=ONGOING`);
            if ((await response).success) {
                setData((await response).payload.thisPageElements);
                setPages((await  response).payload.totalNumberOfElements)
            } else {

            }
        } catch (e) {
        }
    }


    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }

    return (
        <>
            <div className={"container"}>
                <div className="ongoing">
                    <div className={"flex items-center gap-3"}>
                        <div className="button-mobile" onClick={() => navigate(-1)}>
                            <ArrowLeft />
                        </div>
                        <div className={"page-header-title"}>
                            Орчуулж байгаа анимэнууд
                        </div>
                    </div>
                    <div className={"grid mt-5 lg:grid-cols-8 gap-4 md:grid-cols-5 sm:grid-cols-4 max-sm:grid-cols-2"}>
                        {
                            data.map((item, index) => (
                                <>
                                    <CardCommon key={index} item={item}  onClick={() => {openDialog(item)}} />
                                </>
                            ))
                        }
                    </div>
                </div>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <MovieModal
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
            </div>


        </>

    )
}
