import React, {useState,useEffect} from 'react'
import {ReactSVG} from "react-svg";
import Copy from "../../icons/Copy";
import {useNavigate} from "react-router-dom";
import {useTokenStore, useUserStore} from "../../../constans/StoreUserData";
import CustomAlert from "../../common/CustomAlert";
import {handleSessionLogout} from "../../../utils/handleLogout";
import ClientExpireDate from "../../../pages/client/ClientExpireDate";
import {API_URL} from "../../../constans/API_URL";

export default function ClientInfo() {

    const { user, setUser,clearUser } = useUserStore();
    const { token, setToken,clearToken } = useTokenStore();



    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [message, setMessage] = useState("Амжилттай хуулагдлаа");
    const navigate = useNavigate();


    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                setAlertType('success');
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
            },
            (err) => {
                console.error('Failed to copy text: ', err);
            }
        );
    };

    const handleLogout = async () => {
        const logoutSuccess = await handleSessionLogout();
        console.log("logoutSuccess",logoutSuccess)
        if (logoutSuccess) {
            setUser(null);
            setToken(null);
            navigate('/auth')
        } else {
            setUser(null);
            setToken(null);
            navigate('/auth')
        }
    };


    return (
        <div className={"client mt-10"}>
            <CustomAlert isVisible={showAlert} type={alertType} message={message}/>
            <div className="img mb-4">
                {
                    user.image ?
                        <img
                            src={`${API_URL}/resource/${user.photo?.name}.${user.photo?.ext}`}
                            alt="profile"
                        />
                        :
                        <img src="images/profile.png" alt=""/>
                }
            </div>
            <div className={"name"}>
                <span>
                   {user.givenname}
                </span>
            </div>
            <div className={"flex gap-4 items-center m-3"}>
                <div className={"role"}>
                    {user?.role?.description}
                </div>
                <button className={"id"} onClick={() => copyToClipboard(user.id)}>
                    <span>
                        {user?.id}
                    </span>
                    <span>
                        <Copy/>
                    </span>
                </button>
            </div>
            <div className={"mb-10"}>
                <button className="button-md button-secondary" onClick={()=> handleLogout()}>
                    Системээс гарах
                    <ReactSVG src="icons/log-in-01.svg"/>
                </button>
            </div>

            <div
                className={"grid lg:grid-cols-3 gap-5 md:gap-5 max-sm:gap-2    md:grid-cols-3 sm:grid-cols-3 max-sm:grid-cols-3"}>
                <div className={"about"}>
                    <div>
                          <span>
                        Өдөр
                        </span>
                    </div>
                    <ClientExpireDate/>
                </div>
                <div className={"about"}>
                    <div>
                            <span>
                            Данс
                        </span>
                    </div>
                    <div>
                        <span>
                             {user.balance}₮
                        </span>
                    </div>
                </div>
                <div className={"about"}>
                    <div>
                        <span>
                            Оноо
                        </span>
                    </div>
                    <div>
                        <span>
                          0
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
