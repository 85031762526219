import React, {useEffect, useState} from 'react'
import HeaderCard from "../../common/header-author/Header-Card";
import CardCommon from "../../cards/CardCommon";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import CustomPopup from "../../common/pop-up/CustomPopup";
import MovieModal from "../../modal/MovieModal";

export default function Finished() {


    const [data, setData] = useState([]);

    useEffect(() => {
        getCompletedMovie();
    }, []);


    const getCompletedMovie = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?movieStatus=COMPLETED`);
            if ((await response).success) {
                setData((await response).payload.thisPageElements.slice(0,16));
            } else {
                console.warn("Token fetch error", (await response).message);
            }
        } catch (e) {
        }
    }




    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }



    return (
        <div className={"container"}>
            <HeaderCard title={"Дуусгасан төслүүд"} link={"/all-projects-completed"}/>
            <div className={"mt-5 grid xl:grid-cols-8 lg:grid-cols-8 gap-4 md:grid-cols-4 sm:grid-cols-3 max-sm:grid-cols-3"}>
                {
                    data.map((item, index) => (
                        <CardCommon key={index} item={item} onClick={() => {openDialog(item)}}/>
                    ))
                }
            </div>

            <CustomPopup open={isDialogOpen} close={handleClose}>
                <MovieModal
                    item={selectedItem}
                    shown={isDialogOpen}
                    setShown={value => setIsDialogOpen(value)}
                />
            </CustomPopup>
        </div>
    )
}
