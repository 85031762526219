import React from 'react'
import {Link, useNavigate} from "react-router-dom";
import Close from "../icons/Close";
import {useTokenStore, useUserStore} from "../../constans/StoreUserData";
import ClientExpireDate from "../../pages/client/ClientExpireDate";
import Qpay from "../icons/Qpay";
import Bank from "../icons/Bank";
import Settings from "../icons/Settings";
import Key from "../icons/Key";
import BookmarkAdd from "../icons/Bookmark-add";
import {handleSessionLogout} from "../../utils/handleLogout";
import {ReactSVG} from "react-svg";
import {API_URL} from "../../constans/API_URL";

export default function ProfileDropdownMobile({show}) {

    const { user, setUser,clearUser } = useUserStore();

    const navigate = useNavigate();
    const { token, setToken,clearToken } = useTokenStore();



    const handleLogout = async () => {
        const logoutSuccess = await handleSessionLogout();
        console.log("logoutSuccess",logoutSuccess)
        if (logoutSuccess) {
            setUser(null);
            setToken(null);
            navigate('/auth')
        } else {
            setUser(null);
            setToken(null);
            navigate('/auth')
        }
    };


    return (
        <div className={"profile-dropdown-mobile"}>
            <button className={"close"} onClick={show}>
                <Close stroke={"#fff"} />
            </button>
            <div className={""}>
                <Link to={"/client"} className={"flex gap-2"}>
                    <div className="img">
                        {
                            user.image ?
                                <img
                                    src={`${API_URL}/resource/${user.photo?.name}.${user.photo?.ext}`}
                                    alt="profile"
                                />
                                :
                                <img src="images/profile.png" alt=""/>
                        }
                    </div>
                    <div>
                        <div className={"name"}>
                            {user.givenname}
                        </div>
                        <div className={"role"}>
                            {user.role.description}
                        </div>
                    </div>
                </Link>
                <div>
                    <div className={"info"}>
                        <div>
                            Өдөр
                        </div>
                        <div>
                            <ClientExpireDate/>
                        </div>
                    </div>
                    <div className={"info"}>
                        <div>
                            Данс
                        </div>
                        <div>
                            {user.balance}₮
                        </div>

                    </div>
                    <div className={"info"}>
                        <div>
                            iD
                        </div>
                        <div>
                            {user.id}
                        </div>

                    </div>
                    <div className={"info"}>
                        <div>
                            Оноо
                        </div>
                        <div>
                            {user.point}
                        </div>
                    </div>
                </div>

            </div>
            <div className={""}>
                <div className={"list"}>
                    <div className={"head"}>
                        Төлбөр
                    </div>
                    <Link to={"/qpay"} className={"button"}>
                        <div>
                            <Qpay/>
                        </div>
                        <div>
                            QPay-р данс цэнэглэх
                        </div>
                    </Link>
                    <Link to={"/guide"} className={"button"}>
                        <div>
                            <Bank/>
                        </div>
                        <div>
                            Дансны заавар
                        </div>
                    </Link>
                    <Link to={"/transactions"} className={"button"}>
                        <div>
                            <Bank/>
                        </div>
                        <div>
                            Гүйлгээний хуулга
                        </div>
                    </Link>
                </div>
                <div className={"list"}>
                    <div className={"head"}>
                        Тохиргоо
                    </div>
                    <Link to={"/profile"} className={"button"}>
                        <div>
                            <Settings/>
                        </div>
                        <div>
                            Тохиргоо
                        </div>
                    </Link>
                    <Link to={"/password-change"} className={"button"}>
                        <div>
                            <Key/>
                        </div>
                        <div>
                            Нууц үг солих
                        </div>
                    </Link>
                    <button onClick={()=> handleLogout()}  className={"button"}>
                        <div>
                            <ReactSVG src="icons/log-in-01.svg"/>
                        </div>
                        <div>
                            Систем-с гарах
                        </div>
                    </button>
                </div>
                <div className={"list"}>
                    <div className={"head"}>
                        Контент
                    </div>
                    <Link to={"/saved"} className={"button"}>
                        <div>
                            <BookmarkAdd/>
                        </div>
                        <div>
                            Дараа үзэх
                        </div>
                    </Link>
                </div>
            </div>


        </div>

    )
}
