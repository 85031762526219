import React,{useState,useEffect} from 'react'
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import {useParams} from "react-router-dom";
import ChevronLeft from "../../components/icons/ChevronLeft";
import ChevronRight from "../../components/icons/ChevronRight";
import Select from 'react-select';
import {customStyles} from "../../components/common/SelectStyle";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import Slider from "react-slick";


export default function MangaPlayer() {


    const { mangaId, chapterId: chapterId } = useParams();



    const [chapterImages, setChapterImages] = useState([]);
    const [chapterData, setChapterData] = useState([])


    const [selectedChapterId, setSelectedChapterId] = useState(chapterId);
    const [selecterChapterName, setSelecterChapterName] = useState("Анги сонгох");




    const [next, setNext] = useState({});
    const [prev, setPrev] = useState({});
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        fetchImage();
        fetchNext();
        fetchPrev();
        fetchEpisodes();
    }, [chapterId, selectedChapterId]);





    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);


    const fetchImage = async () => {
        try {
            const response = await get(`${API_URL}/api/manga/chapter/${selectedChapterId}`);
            if ((await response).success) {
                setChapterImages((await response).payload)
            }
        } catch (e) {

        }
    }

    const fetchEpisodes = async () => {
        try {
            const response = await get(`${API_URL}/api/manga/chapter/listManga?mangaId=${mangaId}`);
            if (response.success) {
                const transformedData = response.payload.map(chapter => ({
                    value: chapter.id,
                    label: `${chapter.name} (E${chapter.episodeNumber})`
                }));
                setChapterData(transformedData);
            } else {
                console.error('Failed to fetch episodes:', response.error);
            }
        } catch (e) {
            console.error('Error fetching episodes:', e);
        }
    }


    const fetchPrev = async () => {
        try {
            const response = await get(`${API_URL}/api/manga/chapter/${selectedChapterId}/next?isNext=false`);
            if ((await response).success) {
                setPrev((await response).payload)

            }

            scrollToTop();
        } catch (e) {

        }
    }


    const fetchNext = async () => {
        try {
            const response = await get(`${API_URL}/api/manga/chapter/${selectedChapterId}/next?isNext=true`);
            if ((await response).success) {
                setNext((await response).payload)
            }
            scrollToTop();
        } catch (e) {

        }
    }

    const goToPreviousChapter = () => {
        setSelectedChapterId(prev.id);
    };


    const goToNextChapter = () => {
        setSelectedChapterId(next.id);
    };





    const selectChapter = (selectedOption) => {
        setSelectedChapterId(selectedOption.value);
        setSelecterChapterName(selectedOption.label); // Directly use selectedOption.label
    };


    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    };


    const [isDialogOpen, setIsDialogOpen] = useState(false);

    function openDialog() {
        setIsDialogOpen(true);

    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }



    return (
       <>
           <div className={"container"}>
               <div className={"manga-reader"}>
                   <div className={"mb-8"}>
                       <div className={"title"}>
                           {chapterImages[0] !== undefined && (
                               <div className="img">
                                   {chapterImages[0].chapter.manga.name} / {chapterImages[0].chapter.episodeNumber}
                               </div>
                           )}
                       </div>
                       <CustomPopup open={isDialogOpen} close={handleClose}>
                           <div style={{width: "300px", height: "300px", padding: "10px"}}>
                               <input
                                   style={{width: "100%"}}
                                   type="range"
                                   min="1"
                                   max="3"
                                   step="0.1"
                               />
                           </div>
                       </CustomPopup>
                      {/* <button onClick={() => openDialog()}>
                           asdas
                       </button>*/}
                       <div className={"flex justify-between items-center"}>
                           <div>
                               {chapterImages[0] !== undefined && (
                                   <div>
                                       <Select
                                           placeholder={selecterChapterName}
                                           onChange={selectChapter}
                                           options={chapterData}
                                           value={selecterChapterName}
                                           styles={customStyles}
                                       />
                                   </div>
                               )
                               }
                           </div>
                           <div className={"flex gap-2"}>
                               {
                                   prev.id &&
                                   <button className={"button-sm button-warring"} onClick={goToPreviousChapter}>
                                       <ChevronLeft/>
                                       Prev
                                   </button>
                               }
                               {
                                   next.id &&
                                   <button className={"button-sm button-warring"} onClick={goToNextChapter}>
                                       Next
                                       <ChevronRight/>
                                   </button>
                               }
                           </div>
                       </div>
                   </div>
                   <div className={"image-container"}>
                       <div>
                           {chapterImages.map((item, index) => (
                               <div key={item.id} className="img">
                                   <img
                                       src={`https://www.mnfansubs.net/resource/${item.image.name}.${item.image.ext}`}
                                       alt={item.name}
                                   />
                               </div>
                           ))}
                       </div>

                   </div>

                   <div className={"flex justify-between items-center mt-10"}>
                       <div>
                           {chapterImages[0] !== undefined && (
                               <div>
                                   <Select
                                       placeholder={selecterChapterName}
                                       onChange={selectChapter}
                                       options={chapterData}
                                       value={selecterChapterName}
                                       styles={customStyles}
                                   />
                               </div>
                           )
                           }
                       </div>
                       <div className={"flex gap-2"}>
                           {
                               prev.id &&
                               <button className={"button-sm button-warring"} onClick={goToPreviousChapter}>
                                   <ChevronLeft/>
                                   Prev
                               </button>
                           }
                           {
                               next.id &&
                               <button className={"button-sm button-warring"} onClick={goToNextChapter}>
                                   Next
                                   <ChevronRight/>
                               </button>
                           }
                       </div>
                   </div>
                   {isVisible && (
                       <button onClick={scrollToTop}
                               className={"fixed-button"}
                       >
                           <svg
                               fill="currentColor"
                               viewBox="0 0 16 16"
                               height="1em"
                               width="1em"
                           >
                               <path
                                   fillRule="evenodd"
                                   d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
                               />
                           </svg>
                       </button>
                   )}
               </div>
           </div>

       </>
    )
}
