function VolumeUp(props) {

    const style = props.style ? {...props.style} : {}
    const stroke = props.stroke ? props.stroke : "#A7A5A5"
    const fill =  props.fill ? props.fill : "" ;

    return (
        <svg
            viewBox="0 0 24 24"
            fill={stroke}
            height="1em"
            width="1em"
            style={style}
        >
            <path
                d="M16 21c3.527-1.547 5.999-4.909 5.999-9S19.527 4.547 16 3v2c2.387 1.386 3.999 4.047 3.999 7S18.387 17.614 16 19v2z"/>
            <path
                d="M16 7v10c1.225-1.1 2-3.229 2-5s-.775-3.9-2-5zM4 17h2.697l5.748 3.832a1.004 1.004 0 001.027.05A1 1 0 0014 20V4a1 1 0 00-1.554-.832L6.697 7H4c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2zm0-8h3c.033 0 .061-.016.093-.019a1.027 1.027 0 00.38-.116c.026-.015.057-.017.082-.033L12 5.868v12.264l-4.445-2.964c-.025-.017-.056-.02-.082-.033a.986.986 0 00-.382-.116C7.059 15.016 7.032 15 7 15H4V9z"/>
        </svg>
    )
}

export default VolumeUp;