import { create } from 'zustand';

export const useUserStore = create((set) => {
    // Safely try to load the user from localStorage
    let storedUser;
    try {
        const userString = localStorage.getItem('user');
        storedUser = userString ? JSON.parse(userString) : null; // Only parse if userString is not null
    } catch (e) {
        console.error("Error parsing user from localStorage:", e);
        storedUser = null; // Set storedUser to null if parsing fails
    }

    return {
        user: storedUser, // Initialize state with user from localStorage if available, or null
        setUser: (user) => {
            set({ user }); // Update Zustand store
            localStorage.setItem('user', JSON.stringify(user)); // Update localStorage
        },
        clearUser: () => {
            set({ user: null }); // Clear user in Zustand store
            localStorage.removeItem('user'); // Clear user in localStorage
        },
    };
});



/*export const useUserStore = create(set => ({
    user: null,
    setUser: user => set({ user }),
}));*/

export const useTokenStore = create((set => {
    let storedToken;
    try {
        const tempToken = localStorage.getItem("token");
        storedToken = tempToken && tempToken.length > 5 ? JSON.parse(tempToken) : null;
    } catch (e) {
        console.warn(e);
    }

    return {
        token: storedToken,
        setToken: token => {
            set({token});
            localStorage.setItem("token", JSON.stringify(token));
        },
        clearToken: () => {
            set({token: null});
            localStorage.removeItem("token");
        }
    }
}));

// Example function to call before navigation
export function storeScrollPosition() {
    const scrollPosition = window.scrollY; // or any specific calculation for the middle
    localStorage.setItem('savedScrollPosition', scrollPosition.toString());
}
