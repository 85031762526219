import React, {useState} from 'react'

export default function CardFetchPopular({onClick, item}) {
    return (
        <div className={"card-favorite"} onClick={onClick}>
            <div className={"year"}>
                {
                    item.year &&
                    <span>{item.year} он</span>
                }
            </div>
            <div className={"flex"}>
                {
                    item.movie.movieImage ?
                        <>
                            <div className="img">
                                <img
                                    src={`https://www.mnfansubs.net/resource/${item.movie.movieImage.path}.${item.movie.movieImage.ext}`}
                                    alt=""/>
                            </div>
                            <div className="img">
                                <img
                                    src={`https://www.mnfansubs.net/resource/${item.movie?.movieImage?.path}.${item.movie?.movieImage?.ext}`}
                                    alt=""/>
                            </div>
                        </>
                        :
                        <>
                            <div className="img">
                                <img src="/images/noimage.jpg"/>
                            </div>
                            <div className="img">
                                <img src="/images/noimage.jpg" />
                            </div>
                        </>
                }
            </div>
            <div className={"about"}>
                <div>
                    {
                        item.episodeNumber ?
                            <span>Нийт • {item.episodeNumber}</span>
                            :
                            <span>{item.name}</span>
                    }
                </div>
                <div>
                    <span>
                    Хадмал (MN)
                    </span>
                </div>
            </div>
        </div>
    )
}