export const customStyles = {
    control: (base, state) => ({
        ...base,
        backgroundColor: '#333',
        border: state.isFocused ? "1px solid #333" : "1px solid #333",
        boxShadow: state.isFocused ? "0 0 0 1px #333" : "none",
        "&:hover": {
            border: state.isFocused ? "1px solid #333" : "1px solid #333",
        },
        zIndex: 0,
    }),
    menu: (base) => ({
        ...base,
        backgroundColor: '#333',
    }),
    option: (base, { isFocused, isSelected }) => ({
        ...base,
        backgroundColor: isFocused ? 'darkgrey' : isSelected ? 'grey' : undefined,
        color: isFocused || isSelected ? 'white' : '#fff',
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: '#9b9b9b',
    }),
    singleValue: (defaultStyles) => ({
        ...defaultStyles,
        color: '#fff',
    }),
};

export const selectStyleSmall = {
    control: (base, state) => ({
        ...base,
        minHeight: '30px', // Adjusts the minimum height
        height: '50px', // Adjusts the height
        fontSize: '12px',
        backgroundColor: '#242424',
        borderRadius: "5px",
        border: state.isFocused ? "1px solid #333" : "1px solid #333",
        boxShadow: state.isFocused ? "0 0 0 1px #333" : "none",
        "&:hover": {
            border: state.isFocused ? "1px solid #000" : "1px solid #000",
        },
        zIndex: 0,
    }),
    menu: (base) => ({
        ...base,
        backgroundColor: '#000',
    }),
    option: (base, { isFocused, isSelected }) => ({
        ...base,
        backgroundColor: isFocused ? '#ccc' : isSelected ? '#000' : undefined,
        color: isFocused || isSelected ? 'white' : '#fff',
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: '#fff',
    }),
    singleValue: (defaultStyles) => ({
        ...defaultStyles,
        color: '#fff',
    }),
};