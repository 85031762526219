import React, {useEffect, useState} from 'react'
import {get} from "sodon-http/API"
import {API_URL} from "../../constans/API_URL";
import HeaderCard from "../../components/common/header-author/Header-Card";
import CardCommon from "../../components/cards/CardCommon";
import Paginate from "../../components/paginate/paginate";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import MovieModal from "../../components/modal/MovieModal";
import ArrowLeft from "../../components/icons/ArrowLeft";
import {useNavigate, useSearchParams} from "react-router-dom";
import PaginateTest from "../../components/paginate/paginate-test";

export default function PageAnimeCompleted() {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [pages, setPages] = useState(null);


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);



    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [lastPageNumber, setLastPageNumber] = useState(1);


    useEffect(() => {
        fetchAnimeCompleted();
        window.scrollTo(0, 0);
    }, [currentPageNumber]);

    const fetchAnimeCompleted = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?page=${currentPageNumber}&size=24&movieTypeAlias=anime&movieStatus=COMPLETED`);
            if ((await response).success) {
                setData((await response).payload.thisPageElements);
                setPages((await  response).payload.totalNumberOfElements)
                setLastPageNumber(response.payload.lastPageNumber);
            } else {

            }
        } catch (e) {
        }
    }


    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }

    return (
        <>
            <div className={"container"}>
                <div className="ongoing">
                    <div className={"flex items-center gap-3"}>
                        <div className="button-md button-primary" onClick={() => navigate(-1)}>
                            <ArrowLeft />
                        </div>
                        <div className={"page-header-title"}>
                            Орчуулж дууссан анимэнууд
                        </div>
                    </div>
                    <div className={"grid mt-5 lg:grid-cols-8 gap-4 md:grid-cols-5 sm:grid-cols-4 max-sm:grid-cols-2"}>
                        {
                            data.map((item, index) => (
                                <>
                                    <CardCommon key={index} item={item} onClick={() => {openDialog(item)}} />
                                </>
                            ))
                        }
                    </div>
                    <PaginateTest setCurrentPageNumber={setCurrentPageNumber} currentPageNumber={currentPageNumber} lastPageNumber={lastPageNumber}/>
                    <CustomPopup open={isDialogOpen} close={handleClose}>
                        <MovieModal
                            item={selectedItem}
                            shown={isDialogOpen}
                            setShown={value => setIsDialogOpen(value)}
                        />
                    </CustomPopup>
                </div>
            </div>


        </>

    )
}
