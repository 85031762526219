function Star(props) {

    const stroke = props.stroke ? props.stroke : ""

    const fill =  props.fill ? props.fill : "none"


    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill={fill}>
            <path
                d="M7.52175 2.48418C7.6754 2.17288 7.75223 2.01724 7.85653 1.96751C7.94728 1.92424 8.0527 1.92424 8.14345 1.96751C8.24775 2.01724 8.32458 2.17288 8.47823 2.48418L9.93602 5.43749C9.98138 5.52939 10.0041 5.57534 10.0372 5.61102C10.0666 5.6426 10.1018 5.6682 10.1409 5.68638C10.185 5.70692 10.2357 5.71433 10.3371 5.72915L13.598 6.20577C13.9414 6.25596 14.113 6.28106 14.1925 6.36492C14.2616 6.43789 14.2941 6.53816 14.281 6.63782C14.2659 6.75235 14.1416 6.87342 13.893 7.11555L11.5343 9.41291C11.4608 9.48453 11.424 9.52033 11.4003 9.56294C11.3793 9.60066 11.3658 9.64211 11.3606 9.68497C11.3547 9.73338 11.3634 9.78396 11.3807 9.88513L11.9373 13.13C11.996 13.4723 12.0253 13.6434 11.9702 13.745C11.9222 13.8333 11.8369 13.8953 11.738 13.9136C11.6244 13.9347 11.4707 13.8539 11.1634 13.6922L8.24823 12.1592C8.1574 12.1114 8.11199 12.0875 8.06414 12.0782C8.02178 12.0698 7.9782 12.0698 7.93584 12.0782C7.888 12.0875 7.84258 12.1114 7.75175 12.1592L4.8366 13.6922C4.52926 13.8539 4.37559 13.9347 4.26196 13.9136C4.1631 13.8953 4.07779 13.8333 4.0298 13.745C3.97465 13.6434 4.004 13.4723 4.0627 13.13L4.61924 9.88513C4.63659 9.78396 4.64526 9.73338 4.63939 9.68497C4.6342 9.64211 4.62072 9.60066 4.59972 9.56294C4.57599 9.52033 4.53923 9.48453 4.4657 9.41291L2.10702 7.11555C1.85842 6.87342 1.73412 6.75235 1.719 6.63782C1.70584 6.53816 1.73835 6.43789 1.80748 6.36492C1.88694 6.28106 2.05863 6.25596 2.40201 6.20577L5.66285 5.72915C5.76426 5.71433 5.81497 5.70692 5.85912 5.68638C5.89822 5.6682 5.93342 5.6426 5.96277 5.61102C5.99592 5.57534 6.0186 5.52939 6.06396 5.43749L7.52175 2.48418Z"
                stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Star;
