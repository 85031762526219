import React, {useEffect, useState} from 'react'
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import HeaderCard from "../../components/common/header-author/Header-Card";
import CardLatest from "../../components/cards/CardLatest";
import {Link} from "react-router-dom";
import PaginateTest from "../../components/paginate/paginate-test";

export default function PageAnimeLatestEp() {

    const [movieData, setMovieData] = useState([]);

    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastPageNumber, setLastPageNumber] = useState(1)

    useEffect(() => {
        fetchAnimeOngoing();
        window.scrollTo(0, 0);
    }, [currentPageNumber]);

    const fetchAnimeOngoing = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/latest?page=${currentPageNumber}&size=20`);
            if ((await response).success) {
                setMovieData((await response).payload);
            }
        } catch (e) {
        }
    }




    return (
        <>
            <div className={"container"}>
                <div className="latest">
                    <HeaderCard title={"Шинээр нэмэгдсэн"}  />
                    <div className={"mt-5 grid gap-4 lg:grid-cols-4  sm:grid-cols-3 max-sm:grid-cols-2"}>
                        {
                            movieData.map((item, index) => (
                                <CardLatest key={index} item={item} />
                            ))
                        }
                    </div>
                    <PaginateTest setCurrentPageNumber={setCurrentPageNumber} currentPageNumber={currentPageNumber} lastPageNumber={lastPageNumber}/>
                </div>

            </div>

        </>

    )
}
