import React, {useState, useEffect, useRef} from 'react'
import {Link} from "react-router-dom";
import User from "../icons/User";
import Menu from "../icons/Menu";
import Wallet from "../icons/Wallet";
import Eye from "../icons/Eye";
import BookmarkAdd from "../icons/Bookmark-add";
import MenuDropdown from "../menu-dropdown/Menu-Dropdown";
import Close from "../icons/Close";
import ProfileDropdown from "../menu-dropdown/Profile-Dropdown";
export default function FixedMenu() {

    const [menuShown, setMenuShown] = useState(false);
    const [showProfile, setShowProfile] = useState(false);

    const menuRef = useRef(null);
    const fixedMenuRef = useRef(null);
    const [fixedMenuRect, setFixedMenuRect] = useState(null);

    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });


    useEffect(() => {
        if (menuRef.current) {
            const menuRec = menuRef.current.getBoundingClientRect();
        }
    }, []);

    useEffect(() => {
        if (fixedMenuRef.current) {
            setFixedMenuRect(fixedMenuRef.current.getBoundingClientRect());
        }
    }, [fixedMenuRef]);

    useEffect(() => {
        toggleMenu()
    }, [menuShown])


    const toggleProfile = () => {
        if (!showProfile) {
            setShowProfile(true);
            setMenuShown(false)
        } else {
            setShowProfile(false)
        }
    };

    const toggleMenu = () => {
        // console.log(fixedMenuRef.current.getBoundingClientRect());
        if (fixedMenuRect) {
            if (menuShown) {
                showMenu();
            } else {
                hideMenu();
            }
        }
    };

    const showMenu = () => {
        if (menuRef.current && fixedMenuRect) {
            menuRef.current.style.zIndex = `11`;
            menuRef.current.style.position = "fixed";
            menuRef.current.style.bottom = `${fixedMenuRect.height + 20}px`;
            menuRef.current.style.left = `${fixedMenuRect.left}px`;

            menuRef.current.style.opacity = `1`;
            menuRef.current.style.pointerEvents = `fill`;
        }
    }

    const hideMenu = () => {
        if (menuRef.current && fixedMenuRect) {
            menuRef.current.style.zIndex = `10`;
            menuRef.current.style.bottom = `-${menuRef.current.offsetHeight + 20}px`;
            menuRef.current.style.left = `${fixedMenuRect.left}px`;
            menuRef.current.style.opacity = `0`;
            menuRef.current.style.pointerEvents = `none`;
        }
    }

    window.onresize = function (e) {
        if (menuRef.current && fixedMenuRef) {
            setFixedMenuRect(fixedMenuRef.current.getBoundingClientRect());
            menuRef.current.style.bottom = `${fixedMenuRect.height + 20}px`;
            menuRef.current.style.left = `${fixedMenuRect.left}px`;
        }
    }

    window.onscroll = function (ev) {
        // setFixedMenuRect(fixedMenuRef.current.getBoundingClientRect());
        if (menuRef.current && fixedMenuRect) {
            menuRef.current.style.bottom = `${fixedMenuRect.height + 20}px`;
            menuRef.current.style.left = `${fixedMenuRect.left}px`;
        }
    }


    return (
        <>
            <div>
                <div ref={fixedMenuRef} className={"fixed-menu"}>
                    <button className={"button-md button-secondary-outline button-fixed"} onClick={() => setMenuShown(!menuShown)}>
                        {
                            !menuShown ?
                                <>
                                    <Menu/>
                                    Цэснүүд
                                </>
                                :
                                <>
                                    <div style={{padding:"5px", backgroundColor:"#2e2e2e", borderRadius:"50%"}}>
                                        <Close stroke={"#fff"} />
                                    </div>
                                    Хураах
                                </>
                        }
                    </button>
                    <Link to="/guide" className="button-md button-secondary-outline button-fixed ">
                        <Wallet/>
                        Хандив
                    </Link>
                    <Link to="/saved" className="button-md button-secondary-outline button-fixed">
                        <BookmarkAdd />
                        Хадгалсан
                    </Link>
                    <Link to={"/manga-projects"} className="button-md button-secondary-outline button-fixed">
                        <Eye/>
                        Манга
                    </Link>
                    <button className={"button-md button-secondary-outline button-fixed"} onClick={toggleProfile}>
                        {
                            !showProfile ?
                                <>
                                    <User/>
                                    Минийх
                                </>
                                :
                                <>
                                    <div style={{padding:"5px", backgroundColor:"#2e2e2e", borderRadius:"50%"}}>
                                        <Close stroke={"#fff"} />
                                    </div>
                                    Хураах
                                </>
                        }
                    </button>
                </div>
                <MenuDropdown ref={menuRef}/>
                {showProfile &&
                    <div className={`fixed-menu-enter`}>
                        <ProfileDropdown  show={toggleProfile}/>
                    </div>
                }
            </div>


        </>

    )
}
