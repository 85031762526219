import React, {useEffect, useRef, useState} from 'react'
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import CardCommon from "../../components/cards/CardCommon";
import ArrowLeft from "../../components/icons/ArrowLeft";
import {Link, useNavigate} from "react-router-dom";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";


export default function  Transactions () {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [totalNumberOfElement, setTotalNumberOfElement] = useState(null);
    const [pageNumber, setPageNumber] = useState(1)
    const [lastPageNumber, setLastPageNumber] = useState(null)



    useEffect(() => {
        fetchTransaction();
    }, [pageNumber]);


    const fetchTransaction = async () => {
        try {
            const response = await get(`${API_URL}/api/client/transaction?page=${pageNumber}`);
            if ((await response).success) {
                setData((await response).payload.thisPageElements);
                setLastPageNumber(response.payload.lastPageNumber)
                setTotalNumberOfElement(response.payload.totalNumberOfElements)
                setPageNumber(response.payload.pageNumber)
            } else {

            }
        } catch (e) {
        }
    }


    function timestampToDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    const handleChange = (event) => {
        setPageNumber(event.target.value);
    };


    return (
        <div className={"container-lg"}>
            <div className={"mt-20"}>
                <div className={"flex items-center mb-3"}>
                    <button className={"button-mobile"} onClick={() => navigate(-1)}>
                        <ArrowLeft/>
                    </button>
                    <div style={{color:"#fff"}}>
                        Гүйлгээний түүх
                    </div>
                </div>
                <div className={"transactions"}>
                    <div className={"header grid grid-cols-6"}>
                        <div>Огноо</div>
                        <div className={"col-span-3"}>Тайлбар</div>
                        <div>Төрөл</div>
                        <div>Дүн</div>
                    </div>
                    {data.map((item, key) => (
                        <div key={item.id} className={"body grid grid-cols-6"}>
                            <div >
                                {timestampToDate(item.created)}
                            </div>
                            <div className={"col-span-3"}>{item.description}</div>
                            <div  style={{color: item.type === "INCOME" ? "green" : "red"}}>
                                {item.typeLabel}
                            </div>
                            <div style={{color: item.type === "INCOME" ? "green" : "red"}}>
                                {item.type === "INCOME" ? `+${item.income} ₮` : `-${item.expense} ₮`}
                            </div>
                        </div>
                    ))}
                   {/* <div className={"flex items-center mt-2"}>
                        <FormControl  sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-simple-select-label">Хуудас</InputLabel>
                            <Select

                                value={pageNumber}
                                label="Age"
                                onChange={handleChange}
                            >
                                <MenuItem value={1}>{pageNumber}</MenuItem>
                            </Select>
                        </FormControl>
                        <div>
                             {data.length} ээс {totalNumberOfElement} гүйлгээ
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>
    )
}
