import React, {useEffect, useState} from 'react'
import BookmarkAdd from "../../../components/icons/Bookmark-add";
import BookmarkMinus from "../../../components/icons/BookmarkMinus";
import {Link} from "react-router-dom";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import CustomAlert from "../../../components/common/CustomAlert";


export default function BaskedEpisode({item}) {


    const imageUri = item.moduleItemImage !== null
        ? `https://www.mnfansubs.net/resource/${item.moduleItemImage}`
        : null


    const [isLoading, setIsLoading] = useState(false)

    const [checkSaved, setCheckSaved] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");



    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await get(`${API_URL}/api/client/user/savedItem/check?module=movie-ep&moduleItemId=${item.moduleItemId}`);
                if (response.success) {
                    const data = response.payload;
                    if (typeof data === 'boolean') {
                        setCheckSaved(data);
                    }
                }
            } catch (error) {

            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [item.moduleItemId]);



    const handleSaveItem = async () => {
        setAlertVisible(true);
        try {
            const form = {
                module: 'MOVIE-EP',
                moduleItemId: item.moduleItemId,
                moduleItemName: item.moduleItemName,
                moduleItemDesc: item.moduleItemDesc,
                moduleItemImage: item.moduleItemImage,
            };
            // Make the POST request
            const response = await postJson(`${API_URL}/api/client/user/savedItem/saveOrDelete`, form);
            if (response.status === 200 && response.success) {
                setCheckSaved(!checkSaved);
                setAlertType(!checkSaved === true ? 'success' : 'warning');
                setAlertMessage(!checkSaved === true ? 'Амжилттай хадгалагдлаа ' : 'Амжилттай устгагдлаа')
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error saving item:", error);
            alert('Алдаа', error);
        }
    };


    if (item) {
        return (
            <div className={"liked"}>
                <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
                <div className="cart-latest">
                    <div className="ep-number">
                        <div>
                            {item.moduleItemDesc}-р анги
                        </div>
                    </div>
                    <div className="img">
                        <img src={imageUri} alt=""/>
                    </div>
                    <div className="icon">
                        <button onClick={handleSaveItem}>
                            {
                                checkSaved === false &&
                                <div className={""}>
                                    <BookmarkAdd color={"#fff"}/>
                                </div>
                            }
                            {
                                checkSaved === true &&
                                <div className={""}>
                                    <BookmarkMinus color={"green"}/>
                                </div>
                            }
                        </button>
                    </div>
                    <div className="wrapper">


                        <Link className="content" to={`/player/${item.id}`}>
                            <div className="title">
                                        <span>
                                            {item.moduleItemName}
                                        </span>
                            </div>
                            <div className="duration">
                                <div>
                                    {item.duration}
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="cart-latest-mobile">
                    <div className="ep-number">
                        <div>
                            {item.moduleItemDesc}-р анги
                        </div>
                    </div>
                    <Link to={`player/${item.moduleItemId}`} className="img">
                        <img src={`https://www.mnfansubs.net/resource/${item.moduleItemImage}`} alt=""/>
                        <div className="title">
                            {item.moduleItemName}
                        </div>
                    </Link>

                </div>
            </div>
        )
    }
}

