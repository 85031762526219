import Plus from "../icons/Plus";
import Play from "../icons/Play";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import CustomPopup from "../common/pop-up/CustomPopup";
import MovieModal from "../modal/MovieModal";
import CardCommon from "../cards/CardCommon";

function SlideParallaxContext({item}) {


    useEffect(() => {
        fetchMovieData();
    }, [item]);

    const [movieData, setMovieData] = useState([]);

    const fetchMovieData = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/${item.movie.id}`);
            if ((await response).success) {
                setMovieData((await response).payload)
            }
        } catch (e) {

        }
    }

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    function openDialog(movie) {
        setSelectedItem(movie);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }


    return (
        <div className="swiper-parallax-content">
            <div className="swiper-parallax-wrapper">
                <div className="swiper-parallax-movie-name">
                    {item.episodeName}
                </div>
                <div className="swiper-parallax-episode-name">
                    {item.name}
                </div>
                <div className="swiper-parallax-buttons">
                    <div onClick={() => {
                        openDialog(movieData)
                    }} className="button-md button-original">
                        <Play color="#fff"/>
                        Шууд үзэх
                    </div>
                </div>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <MovieModal
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
            </div>
        </div>
    )
}

export default SlideParallaxContext;
