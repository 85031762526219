import React, {useEffect, useState} from 'react'
import {get} from "sodon-http/API"
import {API_URL} from "../../constans/API_URL";
import HeaderCard from "../../components/common/header-author/Header-Card";
import CardCommon from "../../components/cards/CardCommon";
import Paginate from "../../components/paginate/paginate";

export default function PageMangaOngoing() {


    const [data, setData] = useState([]);
    const [pages, setPages] = useState(null);

    useEffect(() => {
        fetchMangaOngoing();
    }, []);


    const fetchMangaOngoing = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?movieStatus=ONGOING`);
            if ((await response).success) {
                setData((await response).payload.thisPageElements);
                setPages((await  response).payload.totalNumberOfElements)
            } else {

            }
        } catch (e) {
        }
    }

    return (
        <>
            <div className={"container"}>
                <div className="ongoing">
                    <HeaderCard  title={"Орчуулж байгаа манганууд"}/>
                    <div className={"grid mt-5 lg:grid-cols-8 gap-4 md:grid-cols-5 sm:grid-cols-4 max-sm:grid-cols-2"}>
                        {
                            data.map((item, index) => (
                                <>
                                    <CardCommon key={index} item={item} />
                                </>
                            ))
                        }

                    </div>
                </div>
            </div>


        </>

    )
}
