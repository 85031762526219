function Plus(props) {
    const color = props.color ? props.color : "#FF6600";
    const style = props.style ? props.style : {}
    return (
        <svg style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99999 4.16669V15.8334M4.16666 10H15.8333" stroke={color} strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Plus;
