function Menu(props) {
    const color = props.color ? props.color : "#a7a5a5";
    const style = props.style ? props.style : {};

    return (
        <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 2.5C1.02083 2.1875 1.1875 2.02083 1.5 2H14.5C14.8125 2.02083 14.9792 2.1875 15 2.5C14.9792 2.8125 14.8125 2.97917 14.5 3H1.5C1.1875 2.97917 1.02083 2.8125 1 2.5ZM1 7.5C1.02083 7.1875 1.1875 7.02083 1.5 7H14.5C14.8125 7.02083 14.9792 7.1875 15 7.5C14.9792 7.8125 14.8125 7.97917 14.5 8H1.5C1.1875 7.97917 1.02083 7.8125 1 7.5ZM14.5 13H1.5C1.1875 12.9792 1.02083 12.8125 1 12.5C1.02083 12.1875 1.1875 12.0208 1.5 12H14.5C14.8125 12.0208 14.9792 12.1875 15 12.5C14.9792 12.8125 14.8125 12.9792 14.5 13Z"
                fill={color}/>
        </svg>
    )
}

export default Menu;
