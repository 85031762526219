import React, {useEffect, useRef, useState} from 'react'
import HeaderCard from "../../common/header-author/Header-Card";
import CardFavorite from "../../cards/CardFavorite";
import ChevronLeft from "../../icons/ChevronLeft";
import ChevronRight from "../../icons/ChevronRight";
import Slider from "react-slick";
import CustomPopup from "../../common/pop-up/CustomPopup";
import MovieModal from "../../modal/MovieModal";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import CardFetchPopular from "../../cards/CardFetchPopular";

export default function Favorite() {

    const [data, setData] = useState([])


    useEffect(() => {
        fetchFeature();
    }, []);

    const fetchFeature = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/feature?positionAlias=popular`);
            if ((await response).success) {
                setData((await response).payload);
            } else {
                console.warn("fetchFeature", (await response));
            }
        } catch (e) {
        }
    }


    const [isSliding, setIsSliding] = useState(false);

    let settings = {
        dots: false,
        infinite: true,
        fade: false,
        arrows: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 4,
        autoplay: false,
        autoplaySpeed: 3500,
        className: "thisYear-slider-container",
        dotsClass: "thisYear-slider-pagination",
        beforeChange: () => setIsSliding(true),
        afterChange: () => setIsSliding(false),
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 5,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            }
        ]
    };

    const slickRef = useRef()

    const goNext = () => {
        if (slickRef.current)
            slickRef.current.slickNext()
    }
    const goPrev = () => {
        if (slickRef.current)
            slickRef.current.slickPrev()
    }

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }

    return (
        <div className={"container"}>
            <div className="this-year">
                <HeaderCard title={"Хоногшсон дурсамжит бүтээлүүд"}/>
                <Slider {...settings} ref={slickRef}>
                    {data.map((item, index) => (
                        <CardFetchPopular key={index} item={item} onClick={() => {
                            if (!isSliding) {
                                openDialog(item);
                            }
                        }} />
                    ))}
                </Slider>
                <div className="this-year-prev" onClick={goPrev}>
                    <ChevronLeft/>
                </div>
                <div className="this-year-next" onClick={goNext}>
                    <ChevronRight/>
                </div>
            </div>
            <CustomPopup open={isDialogOpen} close={handleClose}>
                <MovieModal
                    item={selectedItem}
                    shown={isDialogOpen}
                    setShown={value => setIsDialogOpen(value)}
                />
            </CustomPopup>

        </div>
    )
}
