import React from 'react'
import {Link} from "react-router-dom";
import Qpay from "../../icons/Qpay";
import ArrowRight from "../../icons/ArrowRight";
import Bank from "../../icons/Bank";
import Menu from "../../icons/Menu";
import Eye from "../../icons/Eye";
import Heart from "../../icons/Heart";
import BookmarkAdd from "../../icons/Bookmark-add";
import Settings from "../../icons/Settings";
import Key from "../../icons/Key";

export default function ClientButtons() {
    return (
        <div className={"mt-20 grid  gap-10 md:grid-cols-3 sm:grid-cols-2 max-sm:grid-cols-1"}>
            <div>
                <div className={"client-section"}>
                    Төлбөр
                </div>
                <div className={"flex flex-col gap-5"}>
                    <Link to={"/qpay"}>
                        <div className={"client-button button-secondary"}>
                            <div className={"flex gap-2 items-center"}>
                                <div>
                                    <Qpay/>
                                </div>
                                <div>
                                    QPay-р данс цэнэглэх
                                </div>
                            </div>
                            <div>
                                <ArrowRight/>
                            </div>
                        </div>
                    </Link>
                    <Link to={"/guide"}>
                        <div className={"client-button button-secondary"}>
                            <div className={"flex gap-2 items-center"}>
                                <div>
                                    <Bank/>
                                </div>
                                <div>
                                    Дансны заавар
                                </div>
                            </div>
                            <div>
                                <ArrowRight/>
                            </div>
                        </div>
                    </Link>
                    <Link to={"/transactions"} className={"client-button button-secondary"}>
                        <div className={"flex gap-2 items-center justify-between"}>
                            <div>
                                <Menu/>
                            </div>
                            <div>
                                Гүйлгээний хуулга
                            </div>
                        </div>
                        <div>
                            <ArrowRight/>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <div className={"client-section"}>
                    Контент
                </div>
                <div className={"flex flex-col gap-5"}>
                    <div className={"client-button button-secondary"}>
                        <div className={"flex gap-2 items-center"}>
                            <div>
                                <Eye/>
                            </div>
                            <div>
                                Сүүлд үзсэн
                            </div>
                        </div>
                        <div>
                            <ArrowRight/>
                        </div>
                    </div>
                {/*    <Link to={"/liked"}>
                        <div className={"client-button button-secondary"}>
                            <div className={"flex gap-2 items-center"}>
                                <div>
                                    <Heart fill={"#2E2E2E"}/>
                                </div>
                                <div>
                                    Таалагдсан
                                </div>
                            </div>
                            <div>
                                <ArrowRight/>
                            </div>
                        </div>
                    </Link>*/}
                    <Link to={"/saved"}>
                        <div className={"client-button button-secondary"}>
                            <div className={"flex gap-2 items-center"}>
                                <div>
                                    <BookmarkAdd/>
                                </div>
                                <div>
                                   Хадгалсан
                                </div>
                            </div>
                            <div>
                                <ArrowRight/>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <div className={"client-section"}>
                    Тохиргоо
                </div>
                <div className={"flex flex-col gap-5"}>
                    <Link to={"/profile"}>
                        <div className={"client-button button-secondary"}>
                            <div className={"flex gap-2 items-center"}>
                                <div>
                                    <Settings />
                                </div>
                                <div>
                                    Тохиргоо
                                </div>
                            </div>
                            <div>
                                <ArrowRight/>
                            </div>
                        </div>
                    </Link>
                    <Link to={"/password-change"}>
                        <div className={"client-button button-secondary"}>
                            <div className={"flex gap-2 items-center"}>
                                <div>
                                    <Key/>
                                </div>
                                <div>
                                    Нууц үг солих
                                </div>
                            </div>
                            <div>
                                <ArrowRight/>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
