import React, {useEffect, useRef, useState} from "react";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import Close from "../../icons/Close";
import CustomPopup from "../../common/pop-up/CustomPopup";
import MovieModal from "../../modal/MovieModal";

function SearchMobile() {

    const inputRef = useRef(null);
    const [query, setQuery] = useState('')


    const [searchData, setSearchData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showSearch, setShowSearch] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    useEffect(() => {
        searchMovie()
    }, [query])

    useEffect(() => {
        searchMovie()
    }, [])

    const searchMovie = async () => {
        setIsLoading(true)
        try {
            const response = await get(`${API_URL}/api/movie/list?q=${query}&pageSize=40`);
            if ((await response).success) {
                setSearchData((await response).payload.thisPageElements);
            }
        } catch (e) {

        }
        setIsLoading(false)
    }



    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }

    useEffect(() => {
        const closeSearch = () => {
            setShowSearch(false)
            setQuery("");
        };


        window.addEventListener('scroll', closeSearch);
        return () => window.removeEventListener('scroll', closeSearch);
    }, [showSearch]);


    const toggleSearch = () => {
        if (!showSearch) {
            setShowSearch(true);

        } else{
            setShowSearch(false);
        }
    };

    return (
        <>
            {
                showSearch &&
                <div className={"search-mobile-enter"}>
                    <input
                        ref={inputRef}
                        type="text"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Анимэ, Манга хайх..."
                        onFocus={() => setShowSearch(true)}
                    />
                    <button onClick={() => setShowSearch(false)}>
                        <Close stroke={"#fff"}/>
                    </button>
                </div>
            }
            <button className={"button-md button-warring"} id="search-button" onClick={toggleSearch}>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 17.5L14.375 13.875M9.66667 5C11.9679 5 13.8333 6.86548 13.8333 9.16667M16.3333 9.16667C16.3333 12.8486 13.3486 15.8333 9.66667 15.8333C5.98477 15.8333 3 12.8486 3 9.16667C3 5.48477 5.98477 2.5 9.66667 2.5C13.3486 2.5 16.3333 5.48477 16.3333 9.16667Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            {showSearch && query.trim() !== '' && searchData.length > 0 && (
                <div className={"search-mobile-result"}>
                    {searchData.map((item, index) => (
                        <ul className={"grid grid-cols-6 gap-2"} key={index} onClick={() => {openDialog(item)}}>
                            <div>
                                <img src={`https://www.mnfansubs.net/resource/${item.image?.name}.${item.image?.ext}`} alt={item.name}/>
                            </div>
                            <div className={"title col-span-4"}>
                                <span>{item.name}</span>
                            </div>
                        </ul>
                    ))}
                </div>
            )}
            <CustomPopup open={isDialogOpen} close={handleClose}>
                <MovieModal
                    item={selectedItem}
                    shown={isDialogOpen}
                    setShown={value => setIsDialogOpen(value)}
                />
            </CustomPopup>
        </>
    )
}

export default SearchMobile;
