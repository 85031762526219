import React, { useEffect, useState } from 'react'
import HeaderCard from "../../components/common/header-author/Header-Card";
import CardCommon from "../../components/cards/CardCommon";
import {API_URL} from "../../constans/API_URL";
import {get} from "sodon-http/API";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import MovieModal from "../../components/modal/MovieModal";
import PaginateTest from "../../components/paginate/paginate-test";

export default function PageOnlyMoviesAll() {

    const [movies, setMovies] = useState([]);


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastPageNumber, setLastPageNumber] = useState(1)

    useEffect(() => {
        fetchOngoing();
        window.scrollTo(0, 0);
    }, [currentPageNumber]);

    const fetchOngoing = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?page=${currentPageNumber}`);
            if ((await response).success) {
                setMovies((await response).payload.thisPageElements);
                setLastPageNumber((await response).payload.lastPageNumber);
            }
        } catch (e) {
        }
    }
    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }
console.log("cc",currentPageNumber)
    return (
        <div className={"container"}>
            <div>
                <div>
                    <HeaderCard title={"Бүх кино"}/>
                    <div
                        className={"mt-5 grid xl:grid-cols-6 lg:grid-cols-6 gap-4 md:grid-cols-5 sm:grid-cols-4 max-sm:grid-cols-2"}>
                        {movies.map((item, index) => (
                            <CardCommon key={item.id} item={item} index={index} onClick={() => {
                                openDialog(item)
                            }}/>
                        ))}
                    </div>
                </div>
                <PaginateTest setCurrentPageNumber={setCurrentPageNumber} currentPageNumber={currentPageNumber} lastPageNumber={lastPageNumber}/>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <MovieModal
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
            </div>
        </div>
    );
}
