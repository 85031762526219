import React, {useEffect, useState} from 'react'
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Play from "../../../components/icons/Play";
import Movie from "../../../components/icons/Movie";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import BaskedEpisode from "./BasketEpisode";
import BasketMovie from "./BasketMovie";
import CustomPopup from "../../../components/common/pop-up/CustomPopup";
import MovieModal from "../../../components/modal/MovieModal";
import Manga from "../../manga/Manga";

export default function TabBasket() {



    const [dataEpisode, setDataEpisode] = useState([]);
    const [dataMovies, setDataMovies] = useState([]);
    const [dataManga, setDataManga] = useState([])

    useEffect(() => {
        fetchSavedEp();
        fetchSavedMovies();
        fetchSavedMangas();
    }, []);


    const fetchSavedEp = async () => {
        try {
            const response = await get(`${API_URL}/api/client/user/savedItem?module=movie-ep`);
            if ((await response).success) {
                setDataEpisode((await response).payload);

            }
        } catch (e) {
        }
    }

    const fetchSavedMovies = async () => {
        try {
            const response = await get(`${API_URL}/api/client/user/savedItem?module=movie`);
            if ((await response).success) {
                setDataMovies((await response).payload);

            }
        } catch (e) {
        }
    }
    const fetchSavedMangas = async () => {
        try {
            const response = await get(`${API_URL}/api/client/user/savedItem?module=MANGA`);
            if ((await response).success) {
                setDataManga((await response).payload);
            }
        } catch (e) {
        }
    }


    const [tabIndex, setTabIndex] = useState(0);


    // Content based on the selected tab.

    const dynamicHeader = [
        <div>Хадгалсан төслүүд</div>,
        <div>Хадгалсан ангиуд</div>,
        <div>Хадгалсан манга</div>,
    ];

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }


    const [mangaOpen, setMangaOpen] = useState(false);

    function openManga(item) {
        setSelectedItem(item);
        setMangaOpen(true);
    }

    function handleCloseMange(e) {
        setMangaOpen(false);
    }

    return (
        <div className={"container"}>
            <div>
                <Tabs className={"tab_liked mt-5"} selectedTabClassName="tab_liked__selected" selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                    <TabList className={"flex items-center justify-between"}>
                        <div className={"page-header-title"}>
                            {dynamicHeader[tabIndex]}
                        </div>
                        <div className={"flex gap-2"}>
                            <Tab className={"button-md button-primary"}>
                                <Movie/>
                                Төсөл
                            </Tab>
                            <Tab className={"button-md button-primary"}>
                                <Play/>
                                Анги
                            </Tab>
                            <Tab className={"button-md button-primary"}>
                                <Movie/>
                                Манга
                            </Tab>
                        </div>
                    </TabList>
                    <div className={"mt-5"}>
                        <TabPanel>
                            <div className={"grid lg:grid-cols-6 gap-4 md:grid-cols-3 sm:grid-cols-3 max-sm:grid-cols-2"}>
                                {dataMovies.length > 0 ? (
                                    dataMovies.map(item => (
                                        <div key={item.id}>
                                            <BasketMovie item={item} onClick={() => openDialog(item)} />
                                        </div>
                                    ))
                                ) : (
                                    <div style={{ color: "#A7A5A5" }}>
                                        Хадгалсан төсөл байхгүй байна
                                    </div>
                                )}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={"grid lg:grid-cols-4 gap-4 md:grid-cols-3 sm:grid-cols-3 max-sm:grid-cols-2"}>
                                {dataEpisode.length > 0 ?
                                    <>
                                    {dataEpisode.map((item, index) => (
                                        <>
                                            <div >
                                                <BaskedEpisode key={index} item={item}/>
                                            </div>
                                        </>
                                    ))}
                                    </>
                                    :
                                    <div style={{color:"#A7A5A5"}}>
                                        Хадгалсан анги байхгүй байна
                                    </div>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={"grid lg:grid-cols-6 gap-4 md:grid-cols-3 sm:grid-cols-3 max-sm:grid-cols-2"}>
                                {dataManga.length > 0 ? (
                                    dataManga.map((item,index) => (
                                        <div >
                                            <BasketMovie key={index} item={item} onClick={() => openManga(item)} />
                                        </div>
                                    ))
                                ) : (
                                    <div style={{ color: "#A7A5A5" }}>
                                        Хадгалсан манга байхгүй байна
                                    </div>
                                )}
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <MovieModal
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
                <CustomPopup open={mangaOpen} close={handleCloseMange}>
                    <Manga
                        item={selectedItem}
                        shown={openManga}
                        setShown={value => setMangaOpen(value)}
                    />
                </CustomPopup>


            </div>
        </div>

    )
}

