function Copy(props) {

    const color = props.color ? props.color : "#a7a5a5";
    const style = props.style ? {...props.style} : {}

    return (
        <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 0H14C14.5625 0.0208333 15.0312 0.21875 15.4062 0.59375C15.7812 0.96875 15.9792 1.4375 16 2V9C15.9792 9.5625 15.7812 10.0312 15.4062 10.4062C15.0312 10.7812 14.5625 10.9792 14 11H7C6.4375 10.9792 5.96875 10.7812 5.59375 10.4062C5.21875 10.0312 5.02083 9.5625 5 9V2C5.02083 1.4375 5.21875 0.96875 5.59375 0.59375C5.96875 0.21875 6.4375 0.0208333 7 0ZM2 5H4V7H2V14H9V12H11V14C10.9792 14.5625 10.7812 15.0312 10.4062 15.4062C10.0312 15.7812 9.5625 15.9792 9 16H2C1.4375 15.9792 0.96875 15.7812 0.59375 15.4062C0.21875 15.0312 0.0208333 14.5625 0 14V7C0.0208333 6.4375 0.21875 5.96875 0.59375 5.59375C0.96875 5.21875 1.4375 5.02083 2 5Z"
                fill={color}/>
        </svg>
    )
}

export default Copy;
