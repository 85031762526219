function Bank(props) {

    const style = props.style ? {...props.style} : {}
    const stroke = props.stroke ? props.stroke : "#A7A5A5"
    const fill =  props.fill ? props.fill : "" ;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={fill}>
            <path
                d="M4.16667 7.50018V14.1668M7.91667 7.50018V14.1668M12.0833 7.50018V14.1668M15.8333 7.50018V14.1668M2.5 15.5002L2.5 16.1668C2.5 16.6336 2.5 16.8669 2.59083 17.0452C2.67072 17.202 2.79821 17.3295 2.95501 17.4094C3.13327 17.5002 3.36662 17.5002 3.83333 17.5002H16.1667C16.6334 17.5002 16.8667 17.5002 17.045 17.4094C17.2018 17.3295 17.3293 17.202 17.4092 17.0452C17.5 16.8669 17.5 16.6336 17.5 16.1668V15.5002C17.5 15.0335 17.5 14.8001 17.4092 14.6219C17.3293 14.4651 17.2018 14.3376 17.045 14.2577C16.8667 14.1668 16.6334 14.1668 16.1667 14.1668H3.83333C3.36662 14.1668 3.13327 14.1668 2.95501 14.2577C2.79821 14.3376 2.67072 14.4651 2.59083 14.6219C2.5 14.8001 2.5 15.0335 2.5 15.5002ZM9.71076 2.56446L3.54409 3.93483C3.17154 4.01761 2.98527 4.05901 2.84622 4.15918C2.72358 4.24754 2.62727 4.36759 2.56762 4.50648C2.5 4.66395 2.5 4.85477 2.5 5.23641L2.5 6.16685C2.5 6.63356 2.5 6.86691 2.59083 7.04517C2.67072 7.20197 2.79821 7.32946 2.95501 7.40935C3.13327 7.50018 3.36662 7.50018 3.83333 7.50018H16.1667C16.6334 7.50018 16.8667 7.50018 17.045 7.40935C17.2018 7.32946 17.3293 7.20197 17.4092 7.04517C17.5 6.86691 17.5 6.63356 17.5 6.16685V5.23641C17.5 4.85477 17.5 4.66395 17.4324 4.50649C17.3727 4.36759 17.2764 4.24754 17.1538 4.15918C17.0147 4.05901 16.8285 4.01762 16.4559 3.93483L10.2892 2.56446C10.1813 2.54047 10.1273 2.52848 10.0728 2.52369C10.0244 2.51945 9.97564 2.51945 9.9272 2.52369C9.87267 2.52848 9.8187 2.54047 9.71076 2.56446Z"
                stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Bank;
