import {useUserStore} from "../constans/StoreUserData";
import {get} from "sodon-http/API";
import {API_URL} from "../constans/API_URL";

export const checkUserSession = async () => {

    try {


        const response = await get(`${API_URL}/api/core/signedUser`);
        // const {user, setUser} = useUserStore();
        // server дээр хэрэглэгч нэвтрэлттэй байна
        if (response.status === 200 && response.payload) {
            // console.log("RESPONSE", await response.payload);
            // console.log("USER", await user);
            return response.payload;
        }
        // server дээрх хэрэглэгчийн session дууссан
        if (response.status === 403) {
            // console.log("RESPONSE", response.status);
            return null;
        }
    } catch (e) {
        console.warn("Error occured while checking user", e);
    }
}
