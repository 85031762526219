function Phone(props) {

    const color = props.stroke  ?? "#A7A5A5";

    return (
        <svg style={props.style ?? props.style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.1562 10.3438C15.4688 10.4896 15.6979 10.7083 15.8438 11C15.9896 11.2917 16.0312 11.6042 15.9688 11.9375L15.2812 14.9063C15.1979 15.2396 15.0312 15.5 14.7812 15.6875C14.5312 15.8958 14.2396 16 13.9062 16C11.3229 15.9792 8.98958 15.3438 6.90625 14.0938C4.80208 12.8646 3.13542 11.1979 1.90625 9.09375C0.65625 7.01042 0.0208333 4.67709 0 2.09375C0 1.76042 0.104167 1.46875 0.3125 1.21875C0.5 0.968753 0.760417 0.802086 1.09375 0.718753L4.0625 0.0312531C4.39583 -0.0312469 4.70833 0.0104197 5 0.156253C5.29167 0.302086 5.51042 0.531253 5.65625 0.843753L7.03125 4.03125C7.26042 4.67709 7.125 5.22917 6.625 5.6875L5.375 6.71875C6.29167 8.40625 7.59375 9.70834 9.28125 10.625L10.3125 9.375C10.7708 8.875 11.3229 8.73959 11.9688 8.96875L15.1562 10.3438ZM14.3125 14.6875L15 11.7188C15.0208 11.5104 14.9375 11.3542 14.75 11.25L11.5625 9.875C11.375 9.8125 11.2188 9.85417 11.0938 10L9.78125 11.5938C9.61458 11.7813 9.41667 11.8229 9.1875 11.7188C8.125 11.1979 7.17708 10.5104 6.34375 9.65625C5.48958 8.82292 4.80208 7.875 4.28125 6.8125C4.17708 6.58334 4.21875 6.38542 4.40625 6.21875L6 4.90625C6.14583 4.78125 6.1875 4.625 6.125 4.4375L4.75 1.25C4.66667 1.08334 4.54167 1 4.375 1C4.33333 1 4.30208 1 4.28125 1L1.3125 1.6875C1.125 1.75 1.02083 1.88542 1 2.09375C1.02083 4.48959 1.60417 6.65625 2.75 8.59375C3.91667 10.5313 5.46875 12.0833 7.40625 13.25C9.34375 14.3958 11.5104 14.9792 13.9062 15C14.1146 14.9792 14.25 14.875 14.3125 14.6875Z"
                fill={color}/>
        </svg>
    )
}

export default Phone;
