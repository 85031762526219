import React,{useState,useEffect} from "react";
import Close from "../icons/Close";
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";


const SlideDrawer = ({ show,toggle, fetchAnimes ,setCurrentPageNumber}) => {

    let drawerClasses = show ? "side-drawer open" : "side-drawer";


    const [categories, setCategories] = useState([]);
    const [movieType, setMovieType] = useState([]);
    const [movieYear, setMovieYear] = useState([]);


    const [collectedParams, setCollectedParams] = useState(null);
    const [collectedCategories, setCollectedCategories] = useState([])
    const [collectedYear, setCollectedYear] = useState([]);
    const [collectedType, setCollectedType] = useState([])


    useEffect(() => {
        refactorParameters()
    }, [collectedCategories, collectedYear,collectedType])





    useEffect(() => {
        fetchMovieTypes();
        fetchMovieCategories();

        const now = new Date().getFullYear();
        const prev = 1979;
        const yearsArray = Array.from(new Array(now - prev + 1), (val, index) => {
            const year = now - index;
            return { id: year, name: year.toString() };
        });
        setMovieYear(yearsArray);
        return () => {

        }
    }, []);



    const refactorParameters = () => {
        const categoryIds = collectedCategories.length > 0 ? collectedCategories.join('_') : '';
        let queryStringParts = [];
        if (collectedYear) queryStringParts.push(`year=${collectedYear}`);
        if (categoryIds) queryStringParts.push(`categories=${categoryIds}`);
        if (collectedType) queryStringParts.push(`type=${collectedType}`);
        let queryString = '?' + queryStringParts.join('&');
        setCollectedParams(queryString);
    }

    const fetchMovieCategories = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/category`);
            if ((await response).success) {
                setCategories((await response).payload);
            }
        } catch (e) {
        }
    }

    const fetchMovieTypes = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/type`);
            if ((await response).success) {
                setMovieType((await response).payload);
            }
        } catch (e) {
        }
    }



    const handleChangeType = (value) => {
        if (value !== collectedType && value !== '') { setCollectedType(value) } else {
            if (collectedType !== '') { setCollectedType('') } else { setCollectedType(value) }
        }
        setCurrentPageNumber(1)
    }

     const handleCategory = (item) => {
        const arr = [...collectedCategories]
        const categoryId = item.id
        const index = arr.indexOf(categoryId)
        if (index > -1) {
            arr.splice(index, 1)
        } else {
            arr.push(categoryId)
        }
        setCollectedCategories(arr)
        setCurrentPageNumber(1)
    }

    const handleChangeYear = (value) => {
        if (value !== collectedYear && value !== '') { setCollectedYear(value) } else {
            if (collectedYear !== '') { setCollectedYear('') } else { setCollectedYear(value) }
        }
        setCurrentPageNumber(1)
    }



    const handleClear = () => {
        setCollectedCategories([]);
        setCollectedYear([]);
        setCollectedType([]);
        setCollectedParams(null);
        fetchAnimes();
    };




    return (
        <div className={drawerClasses}>
            <div className={"header"}>
                <span>
                    Шүүлтүүр
                </span>
                <button  onClick={toggle} className={"close"}>
                    <Close stroke={"#000"} />
                </button>
            </div>
            <div className={"body"}>
                <div className={"gap-2 grid"}>
                    {
                        movieType.map((item) => (
                            <div  onClick={()=> handleChangeType(item.id)}>
                                {
                                    collectedType === item.id ?
                                        <>
                                            <div className={"type-section"} style={{background:"#0E0E0E"}}>
                                                <input type="radio" id="radioButton" name="radio" checked hidden />
                                                <label htmlFor="radioButton" className="custom-radio"></label>
                                                <div>
                                                    {item.name}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className={"type-section"} >
                                                <input type="radio" id="radioButton" name="radio" hidden />
                                                <label htmlFor="radioButton" className="custom-radio"></label>
                                                <div>
                                                    {item.name}
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        ))
                    }
                </div>
                <div>
                    <div className={"header-title"}>
                        Ангилал
                    </div>
                    <div className={"categories"}>
                        <ul>
                            {categories.map(item => (
                                <button onClick={()=>handleCategory(item)}>
                                    {
                                        collectedCategories.map(x => x).includes(item.id)
                                            ? <>
                                                <li key={item.id} style={{background:"#FF6600"}}>
                                                    <a>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            </>
                                            : <>
                                                <li key={item.id}>
                                                    <a>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            </>
                                    }
                                </button>

                            ))}
                        </ul>
                    </div>
                </div>
                <div>
                    <div className={"header-title"}>
                        Гарсан он
                    </div>
                    <div className={"categories"}>
                        <ul>
                            {movieYear.map(item => (
                                <div  onClick={()=> handleChangeYear(item.id)}>
                                    {
                                        collectedYear === item.id ?
                                            <>
                                                <li key={item.id} style={{background:"#FF6600"}}>
                                                    <a>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            </>
                                            :
                                            <>
                                                <li key={item.id}>
                                                    <a>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            </>
                                    }
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"footer-button grid grid-cols-2"}>
                <div className={" button-md button-secondary-outline"} onClick={()=>handleClear()}>
                    <Close stroke={"#fff"}/>
                    Арилгах
                </div>
                <button onClick={()=> fetchAnimes(collectedParams)} className={"button-md button-secondary"}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16667 17.5L4.16667 12.5M4.16667 12.5C5.08714 12.5 5.83333 11.7538 5.83333 10.8333C5.83333 9.91286 5.08714 9.16667 4.16667 9.16667C3.24619 9.16667 2.5 9.91286 2.5 10.8333C2.5 11.7538 3.24619 12.5 4.16667 12.5ZM4.16667 5.83333V2.5M10 17.5V12.5M10 5.83333V2.5M10 5.83333C9.07953 5.83333 8.33333 6.57953 8.33333 7.5C8.33333 8.42047 9.07953 9.16667 10 9.16667C10.9205 9.16667 11.6667 8.42047 11.6667 7.5C11.6667 6.57953 10.9205 5.83333 10 5.83333ZM15.8333 17.5V14.1667M15.8333 14.1667C16.7538 14.1667 17.5 13.4205 17.5 12.5C17.5 11.5795 16.7538 10.8333 15.8333 10.8333C14.9129 10.8333 14.1667 11.5795 14.1667 12.5C14.1667 13.4205 14.9129 14.1667 15.8333 14.1667ZM15.8333 7.5V2.5" stroke="#A7A5A5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Шүүх
                </button>
            </div>
        </div>
    );
};

export default SlideDrawer;
