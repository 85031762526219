import React, {useRef, useState,useEffect} from 'react'
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";


export default function Banner({alias}) {



    const [data, setData] = useState([])


    useEffect(() => {
        getBanner();
    }, []);

    const getBanner = async () => {
        try {
            const response = await get(`${API_URL}/api/banner/${alias}?type=BANNER`);
            if ((await response).success) {
                setData((await response).payload)
            }
        } catch (e) {

        }
    }

    return (
        <div className={"container"}>
            {data.map((item, index) => (
                <div className={"banner"} style={{ display:"flex", justifyContent:"center"}}>
                    <a key={index} href={item.url} target={item.target} rel="noopener noreferrer">
                        <img
                            src={`https://www.mnfansubs.net/resource/${item.file.path}`}
                            alt={item.name}
                        />
                    </a>
                </div>

            ))}
        </div>
    )
}
