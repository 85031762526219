import React, {useEffect, useState} from 'react'
import Star from "../icons/Star";
import Play from "../icons/Play";
import BookmarkAdd from "../icons/Bookmark-add";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import BookmarkMinus from "../icons/BookmarkMinus";
import CustomAlert from "../common/CustomAlert";
import {Link} from "react-router-dom";
import ArrowRight from "../icons/ArrowRight";


export default function CardYear({key, item, onClick}) {


    const [isLoading, setIsLoading] = useState(false)

    const [checkSaved, setCheckSaved] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await get(`${API_URL}/api/client/user/savedItem/check?module=movie&moduleItemId=${item.id}`);
                if (response.success) {
                    const data = response.payload;
                    if (typeof data === 'boolean') {
                        setCheckSaved(data);
                    }
                }
            } catch (error) {

            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [item.id]);




    const handleSaveItem = async () => {
        setAlertVisible(true);
        try {
            const form = {
                module: 'MOVIE',
                moduleItemId: item.id,
                moduleItemName: item.name,
                moduleItemDesc: item.description,
                moduleItemImage: `${item.image?.name}.${item.image?.ext}`
            };
            // Make the POST request
            const response = await postJson(`${API_URL}/api/client/user/savedItem/saveOrDelete`, form);
            if (response.status === 200 && response.success) {
                setCheckSaved(!checkSaved);
                setAlertType(!checkSaved === true ? 'success' : 'warning');
                setAlertMessage(!checkSaved === true ? 'Амжилттай хадгалагдлаа ' : 'Амжилттай устгагдлаа')
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error saving item:", error);
            alert('Алдаа', error);
        }
    };


    return (
        <>
            <div key={key} className={"cart-year"}>
                <div className="img" onClick={onClick}>
                    <img src={`https://www.mnfansubs.net/resource/${item.image?.name}_m.${item?.image?.ext}`} />
                </div>
                <div className={"about"}>
                    {/*<div className={"flex items-center gap-1"}>
                        <Star stroke={"#FF6600"}/>
                        <div className={"rating"}>
                        <span>
                            4.7 (2k)
                        </span>
                        </div>
                    </div>*/}
                    <div className={"title"}>
                        <a>
                            {item.name}
                        </a>
                    </div>
                    <div onClick={handleSaveItem} className={"mt-5"}>
                        {
                            checkSaved ===false &&
                            <>
                                <div className={"button-more  button-primary-outline"}>
                                    <BookmarkAdd color={"#A7A5A5"}/>
                                    Хадгалах
                                </div>
                                <div className="button-mobile">
                                    <BookmarkAdd color={"#A7A5A5"}/>
                                    save
                                </div>
                            </>
                        }
                        {
                            checkSaved ===true &&
                            <>
                                <div className={"button-more button-success"}>
                                    <BookmarkMinus color={"#fff"} />
                                    Хадгалсан
                                </div>
                                <div className="button-mobile button-success">
                                    <BookmarkAdd stroke={"#FFF"}/>
                                    saved
                                </div>
                            </>
                        }
                    </div>
                    <div onClick={onClick} className={"button-more button-invi"}>
                        <div >
                            <Play color={"#A7A5A5"}/>
                        </div>
                        <span>
                        Танилцуулга
                        </span>
                    </div>
                    <div onClick={onClick} className="button-mobile mt-2">
                        <Play color={"#A7A5A5"}/>
                        play
                    </div>

                </div>
            </div>
        </>

    )
}
