function User(props) {
    const stroke = props.stroke ? props.stroke : "#A7A5A5"

    const fill =  props.fill ? props.fill : "none"

    const style = props.style ? props.style : {};


    return (
        <svg  style={style} width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 11C12.125 11.0208 13.0729 11.4062 13.8438 12.1562C14.5938 12.9271 14.9792 13.875 15 15C15 15.2917 14.9062 15.5312 14.7188 15.7188C14.5312 15.9062 14.2917 16 14 16H2C1.70833 16 1.46875 15.9062 1.28125 15.7188C1.09375 15.5312 1 15.2917 1 15C1.02083 13.875 1.40625 12.9271 2.15625 12.1562C2.92708 11.4062 3.875 11.0208 5 11H11ZM6.78125 15H9.21875L8 13.7188L6.78125 15ZM10.625 15H14C13.9792 14.1458 13.6875 13.4375 13.125 12.875C12.5625 12.3125 11.8542 12.0208 11 12H5C4.14583 12.0208 3.4375 12.3125 2.875 12.875C2.3125 13.4375 2.02083 14.1458 2 15H5.375L7.625 12.6562C7.875 12.4479 8.125 12.4479 8.375 12.6562L10.625 15ZM3.78125 9.1875C3.30208 9.04167 3.04167 8.72917 3 8.25V0.5C3.02083 0.1875 3.1875 0.0208333 3.5 0H4C4.3125 0.0208333 4.47917 0.1875 4.5 0.5C4.47917 0.8125 4.3125 0.979167 4 1V2.15625C4.4375 1.51042 5 0.989583 5.6875 0.59375C6.375 0.197917 7.14583 0 8 0C9.3125 0.0208333 10.4062 0.458333 11.2812 1.3125C12.1771 2.14583 12.6771 3.20833 12.7812 4.5L13 8.15625C13 8.69792 12.7396 9.04167 12.2188 9.1875L8.5 10H7.5L3.78125 9.1875ZM12 8.21875L11.875 6.09375C11.5 6.26042 11.1042 6.5625 10.6875 7C10.2708 7.41667 9.97917 7.97917 9.8125 8.6875L12 8.21875ZM11.8125 5.03125V5H8.5V8.96875L8.78125 8.90625C8.94792 7.82292 9.34375 6.95833 9.96875 6.3125C10.5938 5.6875 11.2083 5.26042 11.8125 5.03125ZM8 1C7.0625 1.02083 6.26042 1.30208 5.59375 1.84375C4.92708 2.40625 4.5 3.125 4.3125 4H11.6875C11.5 3.125 11.0729 2.40625 10.4062 1.84375C9.73958 1.30208 8.9375 1.02083 8 1ZM4.1875 5V5.03125C4.79167 5.26042 5.40625 5.6875 6.03125 6.3125C6.65625 6.95833 7.05208 7.82292 7.21875 8.90625L7.5 8.96875V5H4.1875ZM4.125 6.09375L4 8.21875L6.1875 8.65625C6.02083 7.94792 5.72917 7.38542 5.3125 6.96875C4.875 6.55208 4.47917 6.26042 4.125 6.09375Z"
                fill={stroke}/>
        </svg>
    )
}

export default User;
