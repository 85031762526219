import React, {useState,useEffect} from 'react'
import {post} from "axios";
import {API_URL, FAKE_API} from "../../constans/API_URL";
import {get, postJson} from "sodon-http/API";
import User from "../../components/icons/User";
import Close from "../../components/icons/Close";
import CustomAlert from "../../components/common/CustomAlert";




export default function SubscriptionPayment({item, setShown}) {

    const [invoiceId, setInvoiceId] = useState('')
    const [invoice, setInvoice] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState("")




    useEffect(() => {
        if (item && item.id) {
            createSubscription(item.id);
        }
    }, [item]);

    const createSubscription = async () => {
        try {
            const response = await postJson(`${API_URL}/api/client/movie/subscription/create?typeId=${item.id}`);
            if (response.success) {
                setInvoice(response.payload);
            } else {
                console.log("Error", response.message);
            }
        } catch (e) {
            console.error("Error creating subscription", e);
        }
    }



    function handleInvoiceCheckResponse(response) {
        if (response.success) {
            const payload = response.payload
            if (response.status === 200) {
                if (payload.type === 'ERROR'){
                    setAlertType("error")
                    setAlertMessage('Төлбөр төлөгдөөгүй байна')
                    setAlertVisible(true);
                } else {
                    setAlertType("success")
                    setAlertMessage('Төлбөр амжилттай төлөгдсөн байна')
                    setAlertVisible(true);

                }
            }
        } else {
            setAlertType("error")
            setAlertMessage( 'Төлбөр шалгах боломжгүй байна')
            setAlertVisible(true);

        }
        setTimeout(() => {
            setAlertVisible(false);
        }, 2000);
    }


    async function checkInvoice() {
        try {
            const response = await get(`${API_URL}/api/transaction/qpay2/check/${invoice.id}`);
            handleInvoiceCheckResponse(response);
        } catch (error) {
            console.error("Error checking invoice", error);
        }
    }



    return (
        <div className={"subscription"}>
            <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
            <button
                style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: 2,
                    background:"#202020",
                    padding:"10px",
                    borderRadius:"50%"
                }}
                onClick={() => setShown(false)}
            >
                <Close
                    stroke={"#fff"}
                    style={{width: 10, height: 10}}
                />
            </button>
            <div className={"title"}>
                {item.price} ₮
            </div>
            <div className={"img"}>
                {invoice ? (
                    <div>
                        <img
                            src={`data:image/png;base64,${invoice.qrImage}`}
                            alt="Invoice QR Code"
                        />
                    </div>
                ) : (
                    <p>Түр хүлээнэ үү...</p>
                )}
            </div>
            <div className={"info"}>
                Та хандиваа шилжүүлсний дараа доорх хэсэгт байгаа "Хандив баталгаажуулах" товчин дээр дарна уу.
            </div>
            <div className={"button-md button-primary mt-5"} onClick={checkInvoice}>
                <User/>
                Хандив баталгаажуулах
            </div>
        </div>

    )
}
