import React from 'react';

function Close(props) {
    const style = props.style ? {...props.style} : {}
    const color = props.color ? props.color : "#a7a5a5";

    const stroke = props.stroke ? props.stroke : "none" ;

    return (
        <svg width="14" height="14" style={style} viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13M1 1L13 13" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}

export default Close;
