import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "../../components/form/form.css";
import User from "../../components/icons/User";
import Key from "../../components/icons/Key";
import Phone from "../../components/icons/Phone";
import Mail from "../../components/icons/Mail";
import {postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";

function Signup() {

    const navigate = useNavigate();
    const [loginname, setLoginname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("")


    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [errors, setErrors] = useState({})

    const [error, setError] = useState("");
    const [passwordError, setPasswordError] = useState("");



    const postRequest = async (e) => {
        e.preventDefault();
        try {
            const form = {
                loginname: loginname,
                phone: phone,
                email: email,
                pword: password,
                rpword: confirmPassword,
            }
            const response = await postJson(`${API_URL}/api/core/signup`, form);
            if (response.success && response.status === 200) {
                alert("Амжилттай бүртгүүлсэн. Нэвтрэнэ үү.");
                setErrors({})
                navigate("/Auth");
            } else if (response.status === 400) {
                const _errors = {}
                const data = await response.payload
                data?.errors?.forEach(error => {
                    _errors[error.field] = error.defaultMessage;
                })
                console.log(_errors);
                console.log(response.text);
                setErrors(_errors);
                console.warn("WARN RESPONSE:", response);
            }
        } catch (e) {
            // console.error(`RORR: ${e}`);
        }
    }

    const ReturnCorrespondingErrorMessage = ({field}) => {
        if (errors[field]) {
            return (
                <div>
                    <label htmlFor={field} className="errorLabel"
                           style={{fontSize: "14px", lineHeight: 1}}>{errors[field]}</label>
                </div>
            )
        } else {
            return <></>
        }
    }


    return (
        <>
            <div className="container-log">
                <form onSubmit={postRequest} className={"login"}>
                    <div>
                        <div className={errors["loginname"] ? "input-container error" : "input-container"}>
                            <User stroke={"#8F8D8D"}/>
                            <input
                                id="loginname"
                                value={loginname}
                                placeholder="Нэвтрэх нэр"
                                onChange={ev => setLoginname(ev.target.value)}
                                className={"input"}/>
                        </div>
                        <ReturnCorrespondingErrorMessage field="loginname"/>
                    </div>
                    <div>
                        <div className={errors["phone"] ? "input-container error" : "input-container"}>
                            <Phone stroke={"#8F8D8D"}/>
                            <input
                                id="phone"
                                type="text" // Changed to text to manually handle numeric input
                                value={phone}
                                placeholder="Утасны дугаар"
                                onChange={ev => {
                                    // Allow only digits to be input
                                    const value = ev.target.value;
                                    const numbersOnly = value.replace(/\D/g, ''); // Remove non-digits
                                    setPhone(numbersOnly);
                                }}
                                className={"input"}
                            />
                        </div>
                        <ReturnCorrespondingErrorMessage field="phone"/>
                    </div>
                    <div>
                        <div className={errors["email"] ? "input-container error" : "input-container"}>
                            <Mail stroke={"#8F8D8D"} fill={"#202020"}/>
                            <input
                                id="email"
                                type="email" // Correct the type attribute to "email"
                                value={email}
                                placeholder="И-мэйл"
                                onChange={ev => setEmail(ev.target.value)}
                                className={"input"}
                            />
                        </div>
                        <ReturnCorrespondingErrorMessage field="email"/>
                    </div>
                    <div>
                        <div className={"dashed"}>
                        <p>
                             Нууц үг оруулах
                         </p>
                        </div>
                        <div className={errors["pword"] ? "input-container error" : "input-container"}>
                            <Key stroke={"#8F8D8D"}/>
                            <input
                                id="pword"
                                value={password}
                                placeholder="Нууц үг"
                                onChange={ev => setPassword(ev.target.value)}
                                className={"input"}/>
                        </div>
                        <ReturnCorrespondingErrorMessage field="pword"/>
                    </div>
                    <div>
                        <div className={errors["rpword"] ? "input-container error" : "input-container"}>
                            <Key stroke={"#8F8D8D"}/>
                            <input
                                id="rpword"
                                value={confirmPassword}
                                placeholder="Нууц үг давтах"
                                onChange={ev => setConfirmPassword(ev.target.value)}
                                className={"input"}
                            />
                        </div>
                        <ReturnCorrespondingErrorMessage field="rpword"/>
                    </div>
                    <button className="button-md button-primary">
                        Бүртгүүлэх
                    </button>
                </form>
            </div>
        </>
    );
}

export default Signup;
