export const fakeData = [
    {
        id: "1",
        name: "bleach",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BZjE0YjVjODQtZGY2NS00MDcyLThhMDAtZGQwMTZiOWNmNjRiXkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_FMjpg_UX1000_.jpg",
        },
        kind: "TV",
        episodes: 366,
        episodes_aired: 366,
        score: "7.92",
    },
    {
        id: "2",
        name: "black_clover",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BNTAzYTlkMWEtOTNjZC00ZDU0LWI5ODUtYTRmYzY0MTAzYWZlXkEyXkFqcGdeQXVyMzgxODM4NjM@._V1_FMjpg_UX1000_.jpg",
        },
        kind: "TV",
        episodes: 170,
        episodes_aired: 170,
        score: "7.16",
    },
    {
        id: "3",
        name: "dragon_ball",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BMGMyOThiMGUtYmFmZi00YWM0LWJiM2QtZGMwM2Q2ODE4MzhhXkEyXkFqcGdeQXVyMjc2Nzg5OTQ@._V1_FMjpg_UX1000_.jpg",
        },
        kind: "TV",
        episodes: 153,
        episodes_aired: 153,
        score: "8.68",
    },
    {
        id: "4",
        name: "jujutsu_kaisen",
        image: {
            original:
                "https://static.wikia.nocookie.net/jujutsu-kaisen/images/8/88/Anime_Key_Visual_2.png/revision/latest?cb=20201212034001",
        },
        kind: "TV",
        episodes: 24,
        episodes_aired: 24,
        score: "8.78",
    },
    {
        id: "5",
        name: "fma_brotherhood",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BZmEzN2YzOTItMDI5MS00MGU4LWI1NWQtOTg5ZThhNGQwYTEzXkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
        },
        kind: "TV",
        episodes: 64,
        episodes_aired: 64,
        score: "9.24",
    },
    {
        id: "6",
        name: "naruto",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BZmQ5NGFiNWEtMmMyMC00MDdiLTg4YjktOGY5Yzc2MDUxMTE1XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
        },
        kind: "TV",
        episodes: 220,
        episodes_aired: 220,
        score: "8.3",
    },
    {
        id: "7",
        name: "gintama",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BMDkxZTJjZTEtMDRjMy00Yzk1LWI5YjItMjIwYmVlYzhlZWZhXkEyXkFqcGdeQXVyNDQxNjcxNQ@@._V1_FMjpg_UX1000_.jpg",
        },
        kind: "TV",
        episodes: 367,
        episodes_aired: 367,
        score: "9.0",
    },
    {
        id: "9",
        name: "one_piece",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BODcwNWE3OTMtMDc3MS00NDFjLWE1OTAtNDU3NjgxODMxY2UyXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_.jpg",
        },
        kind: "TV",
        episodes: 1030,
        episodes_aired: 1030,
        score: "8.58",
    },
    {
        id: "10",
        name: "demon_slayer",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BZjZjNzI5MDctY2Y4YS00NmM4LTljMmItZTFkOTExNGI3ODRhXkEyXkFqcGdeQXVyNjc3MjQzNTI@._V1_.jpg",
        },
        kind: "TV",
        episodes: 26,
        episodes_aired: 26,
        score: "8.6",
    },
    {
        id: "11",
        name: "attack_on_titan",
        image: {
            original: "https://flxt.tmsimg.com/assets/p10701949_b_v8_ah.jpg",
        },
        kind: "TV",
        episodes: 75,
        episodes_aired: 75,
        score: "9.16",
    },
    {
        id: "12",
        name: "hunter_x_hunter",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BZjNmZDhkN2QtNDYyZC00YzJmLTg0ODUtN2FjNjhhMzE3ZmUxXkEyXkFqcGdeQXVyNjc2NjA5MTU@._V1_FMjpg_UX1000_.jpg",
        },
        kind: "TV",
        episodes: 148,
        episodes_aired: 148,
        score: "9.1",
    },
    {
        id: "13",
        name: "boku_no_hero_academia",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BZjNmZDhkN2QtNDYyZC00YzJmLTg0ODUtN2FjNjhhMzE3ZmUxXkEyXkFqcGdeQXVyNjc2NjA5MTU@._V1_FMjpg_UX1000_.jpg",
        },
        kind: "TV",
        episodes: 114,
        episodes_aired: 114,
        score: "8.39",
    },
];

export const fakeMovie = [
    {
        created: 1705457703,
        createdby: {
            id: 22,
            loginname: "ryu",
            givenname: "Ryu",
            image: {
                name: "mnfansubs/user/2020/12/01/k4dr3y9r6qdlcy1i/18194664_1026277647505885_1112450406727532234_n",
                ext: "jpg",
                ratio: 1
            },
            email: "oppositemng@gmail.com"
        },
        updated: 1705457703,
        updatedby: {
            id: 22,
            loginname: "ryu",
            givenname: "Ryu",
            image: {
                name: "mnfansubs/user/2020/12/01/k4dr3y9r6qdlcy1i/18194664_1026277647505885_1112450406727532234_n",
                ext: "jpg",
                ratio: 1
            },
            email: "oppositemng@gmail.com"
        },
        id: 268,
        type: {
            id: 1,
            name: "Анимэ",
            alias: "anime",
            ordering: 1,
            description: null,
            status: "sodon.admin.common.status.ENABLED"
        },
        name: "Луут Бөмбөлөг ЖиТи",
        nameEn: "Dragon Ball GT",
        alias: null,
        year: 1996,
        description: null,
        totalNumber: "64",
        image: {
            name: "mnfansubs/image/2024/01/17/abdkjrq0s1eq3gai/",
            ext: "jpg",
            ratio: 1.58
        },
        horiImage: null,
        trailer: null,
        readCount: 0,
        movieStatus: "ONGOING",
        status: "ENABLED",
        useSection: false,
        logoImage: null,
        categoryIds: "18 40 1 2 6 ",
        categories: [
            {
                id: 1,
                name: "Адал явдалт",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 2,
                name: "Инээдмийн",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 6,
                name: "Тулаан",
                alias: "Action",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 18,
                name: "Шоонэн",
                alias: "Shounen",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 40,
                name: "Онцгой хүч",
                alias: "Supernatural",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            }
        ],
        subscriptionLevel: 0
    },
    {
        created: 1699940804,
        createdby: {
            id: 21,
            loginname: "infi",
            givenname: "InFi",
            image: {
                name: "mnfansubs/user/2021/02/08/ey4pg72wugbx8qm6/mnf_logo_for_OP",
                ext: "png",
                ratio: 0.29
            },
            email: "sugaraab59@gmail.com"
        },
        updated: 1703929388,
        updatedby: {
            id: 21,
            loginname: "infi",
            givenname: "InFi",
            image: {
                name: "mnfansubs/user/2021/02/08/ey4pg72wugbx8qm6/mnf_logo_for_OP",
                ext: "png",
                ratio: 0.29
            },
            email: "sugaraab59@gmail.com"
        },
        id: 257,
        type: {
            id: 4,
            name: "Цуврал",
            alias: "movieSerial",
            ordering: 1,
            description: null,
            status: "sodon.admin.common.status.ENABLED"
        },
        name: "Гэр минь",
        nameEn: "Sweet Home",
        alias: "Sweet Home",
        year: 2020,
        description: "Ча Хён Сү нь 19 настай залуу юм. Тэрээр эцэг эх нь нас барснаас хойш гэртээ бүгж хичээлдээ ч явахаа больсон бөгөөд удалгүй нэгэн шинэ байранд нүүж ирнэ. Өдөр бүрийг видео тоглоом тоглож амиа хорлох тухай бодож өнгөрөөх түүний гэрийн үүдийг нэг хүн тогшиж муухайгаар орилж байгаад нүдэн дээр нь мангас болж хувирна. Ийнхүү түүний шинээр нүүж ирсэн орон сууцны гадна юу болох нь мэдэгдэхгүй мангасууд олноор гарч ирж орон сууцных нь оршин суугчид ч нэг нэгээрээ мангас болон хувирч эхэлнэ.",
        totalNumber: null,
        image: {
            name: "mnfansubs/image/2023/11/14/j7f6iv9a3r6p3ya5/",
            ext: "jpg",
            ratio: 1.5
        },
        horiImage: null,
        trailer: "https://youtu.be/7rI56NmD33Y?si=tjF9gMrjIRpsz63v",
        readCount: 0,
        movieStatus: "ONGOING",
        status: "ENABLED",
        useSection: true,
        logoImage: null,
        categoryIds: "13 45 19 ",
        categories: [
            {
                id: 13,
                name: "Цус нөжтэй",
                alias: "Horror",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 19,
                name: "Аймшгийн",
                alias: "Thriller",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 45,
                name: "Солонгос",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            }
        ],
        subscriptionLevel: 0
    },
    {
        created: 1699095973,
        createdby: {
            id: 22,
            loginname: "ryu",
            givenname: "Ryu",
            image: {
                name: "mnfansubs/user/2020/12/01/k4dr3y9r6qdlcy1i/18194664_1026277647505885_1112450406727532234_n",
                ext: "jpg",
                ratio: 1
            },
            email: "oppositemng@gmail.com"
        },
        updated: 1699095973,
        updatedby: {
            id: 22,
            loginname: "ryu",
            givenname: "Ryu",
            image: {
                name: "mnfansubs/user/2020/12/01/k4dr3y9r6qdlcy1i/18194664_1026277647505885_1112450406727532234_n",
                ext: "jpg",
                ratio: 1
            },
            email: "oppositemng@gmail.com"
        },
        id: 256,
        type: {
            id: 1,
            name: "Анимэ",
            alias: "anime",
            ordering: 1,
            description: null,
            status: "sodon.admin.common.status.ENABLED"
        },
        name: "Хагацлын дараах аялал",
        nameEn: "Frieren: Beyond Journey's End",
        alias: null,
        year: 2023,
        description: "Баатар, Химмел, дайчин Айзен, санваартан Хайтер, шидтэн Фрирен нар 10 жилийн турш хамтдаа аялж чөтгөрийн хааныг даржээ. Дэлхийд энх тайван авчирсан сайхь дөрвийн бүлэг гэртээ хариад тарцгаана. Шидтэн Фрирен өөрийн аян замдаа гарч нөхдөө орхин явна. Фрирен нь элф бөгөөд элфүүд нь маш урт настай юм. Он цаг өнгөрөхийн эрхээр түүний найзууд хөгширч, нэг нэгээрээ өтөл насанд хорвоогийн мөнх бусыг үзүүлнэ. Санваартан Хайтер үхэхийнхээ өмнө Фриренд өөрийн туслах охиныг шидтэнээр сургахыг гуйх ба Фрирен дотны найзынхаа хүсэлтээс цааргалсан боловч үгүй гэж хэлж чадалгүй өөрийн шавиа болгоно.",
        totalNumber: "28",
        image: {
            name: "mnfansubs/image/2023/11/04/yloxg1yx8g600c9t/Хагацлын дараах аялал",
            ext: "jpg",
            ratio: 1.5
        },
        horiImage: null,
        trailer: null,
        readCount: 0,
        movieStatus: "ONGOING",
        status: "ENABLED",
        useSection: false,
        logoImage: null,
        categoryIds: "42 1 14 ",
        categories: [
            {
                id: 1,
                name: "Адал явдалт",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 14,
                name: "Ид шид",
                alias: "Magic",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 42,
                name: "Супер хүч",
                alias: "Superpower",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            }
        ],
        subscriptionLevel: 0
    },
    {
        created: 1698248117,
        createdby: {
            id: 22,
            loginname: "ryu",
            givenname: "Ryu",
            image: {
                name: "mnfansubs/user/2020/12/01/k4dr3y9r6qdlcy1i/18194664_1026277647505885_1112450406727532234_n",
                ext: "jpg",
                ratio: 1
            },
            email: "oppositemng@gmail.com"
        },
        updated: 1698248187,
        updatedby: {
            id: 22,
            loginname: "ryu",
            givenname: "Ryu",
            image: {
                name: "mnfansubs/user/2020/12/01/k4dr3y9r6qdlcy1i/18194664_1026277647505885_1112450406727532234_n",
                ext: "jpg",
                ratio: 1
            },
            email: "oppositemng@gmail.com"
        },
        id: 253,
        type: {
            id: 1,
            name: "Анимэ",
            alias: "anime",
            ordering: 1,
            description: null,
            status: "sodon.admin.common.status.ENABLED"
        },
        name: "Үхэшгүй, азгүй хоёр",
        nameEn: "Undead Unluck",
        alias: null,
        year: 2023,
        description: "Дуртай мангагаа уншиж дууссаны дараа Изүмо Фуко амьдралаа дуусгамаар санагдана. Сүүлийн 10 жилийн турш Фукод хүн хүрсэн тохиолдолд асар ноцтой азгүйдэл ирдэг бөгөөд эргэн тойрноо бэртээгээд зогсохгүй бүр эцэг эхээ үхэлд хүргэсэн удаа бий. Галт тэрэг дайрч өнгөрдөг гүүрэн дээр амиа хорлох гэж зогсохдоо нэгэн хачирхалтай эртэй таардаг бөгөөд Үхэшгүй гэдэг тэр эр хэдэн зуун жилийн турш амьдарч, үхлийг эрэлхийлж явж байлаа. Олон зуун жилийн амьдралаас залхсан тэр Фуког өөрийг нь үхүүлж чадаж магадгүй гэж сэтгэсэн Үхэшгүй Фуког өөрийг нь үхүүлэх тэр том азгүйдлийг авчрахаар энэ хоёрын хөгжилтэй түүх эхэлнэ.",
        totalNumber: null,
        image: {
            name: "mnfansubs/image/2023/10/25/mmcc47h2b5mfpucr/Үхэшгүй азгүй хоёр",
            ext: "jpg",
            ratio: 1.5
        },
        horiImage: null,
        trailer: null,
        readCount: 0,
        movieStatus: "ONGOING",
        status: "ENABLED",
        useSection: false,
        logoImage: null,
        categoryIds: "6 17 13 10 2 40 1 ",
        categories: [
            {
                id: 1,
                name: "Адал явдалт",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 2,
                name: "Инээдмийн",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 6,
                name: "Тулаан",
                alias: "Action",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 10,
                name: "Эччи",
                alias: "Ecchi",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 13,
                name: "Цус нөжтэй",
                alias: "Horror",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 17,
                name: "Хайр дурлал",
                alias: "Romance",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 40,
                name: "Онцгой хүч",
                alias: "Supernatural",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            }
        ],
        subscriptionLevel: 1
    },
    {
        created: 1681836778,
        createdby: {
            id: 22,
            loginname: "ryu",
            givenname: "Ryu",
            image: {
                name: "mnfansubs/user/2020/12/01/k4dr3y9r6qdlcy1i/18194664_1026277647505885_1112450406727532234_n",
                ext: "jpg",
                ratio: 1
            },
            email: "oppositemng@gmail.com"
        },
        updated: 1681836780,
        updatedby: {
            id: 22,
            loginname: "ryu",
            givenname: "Ryu",
            image: {
                name: "mnfansubs/user/2020/12/01/k4dr3y9r6qdlcy1i/18194664_1026277647505885_1112450406727532234_n",
                ext: "jpg",
                ratio: 1
            },
            email: "oppositemng@gmail.com"
        },
        id: 244,
        type: {
            id: 1,
            name: "Анимэ",
            alias: "anime",
            ordering: 1,
            description: null,
            status: "sodon.admin.common.status.ENABLED"
        },
        name: "Харайгч, сэлгүүцэгч хоёр",
        nameEn: "Skip and Loafer",
        alias: null,
        year: 2023,
        description: "Сурлагаар толгой цохидог хөдөөний бяцхан хотын сурагч охин Ивакура Мицүми Токиогийн ахлах сургуульд суралцахаар болно. Том зорилготой тэрээр шилдэг их сургуульд орж, улсаа хөгжүүлэх дэрвүүн хүсэлтэй байсан ч олон хөлийн Токио гэдэг түүний хувьд цоо шинэ туршлага болоод зогсохгүй, хүнтэй харилцах нь амаргүй даваа байв. Хичээлийн шинэ жилийн нээлтэд хоцорч явахдаа хайнга боловч царайлаг Шима гэх хөвгүүнтэй танилцах ба анги хамт олон, шинэ орчинд дасан зохицох гэх хүндхэн бэрхшээлтэй учирдаг.",
        totalNumber: "12",
        image: {
            name: "mnfansubs/image/2023/04/18/ozv70n6l959fucrz/",
            ext: "jpg",
            ratio: 1.42
        },
        horiImage: null,
        trailer: null,
        readCount: 0,
        movieStatus: "ONGOING",
        status: "ENABLED",
        useSection: false,
        logoImage: null,
        categoryIds: "43 17 37 2 35 36 ",
        categories: [
            {
                id: 2,
                name: "Инээдмийн",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 17,
                name: "Хайр дурлал",
                alias: "Romance",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 35,
                name: "Шоожо",
                alias: "Shoujo",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 36,
                name: "Шоожо дурлал",
                alias: "Shoujo-ai",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 37,
                name: "Өдөр тутмын амьдрал",
                alias: "Slice of Life",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 43,
                name: "Нөхөрлөл",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            }
        ],
        subscriptionLevel: 1
    },
    {
        created: 1667509210,
        createdby: {
            id: 21,
            loginname: "infi",
            givenname: "InFi",
            image: {
                name: "mnfansubs/user/2021/02/08/ey4pg72wugbx8qm6/mnf_logo_for_OP",
                ext: "png",
                ratio: 0.29
            },
            email: "sugaraab59@gmail.com"
        },
        updated: 1683472716,
        updatedby: {
            id: 21,
            loginname: "infi",
            givenname: "InFi",
            image: {
                name: "mnfansubs/user/2021/02/08/ey4pg72wugbx8qm6/mnf_logo_for_OP",
                ext: "png",
                ratio: 0.29
            },
            email: "sugaraab59@gmail.com"
        },
        id: 219,
        type: {
            id: 1,
            name: "Анимэ",
            alias: "anime",
            ordering: 1,
            description: null,
            status: "sodon.admin.common.status.ENABLED"
        },
        name: "Аркнайтс: Шинэ эриний оршил",
        nameEn: "Arknights Prelude to Dawn",
        alias: "Arknights",
        year: 2022,
        description: "Учир шалтгаан нь үл мэдэгдэх байгалийн гамшгууд ар, араасаа цувран гарч, үүнээс зугтаж буй хүмүүс хөдөлгөөнт хотуудад олноороо амьдардаг болжээ. Сүйрлийн голомтуудад үлдсэн Орижинум гэх чулууны энергийн ачаар нийгэм, технологи асар хурдтай хөгжив. Гэвч үүний төлөөс нь Орипати байлаа. Орипати гэх эдгэршгүй, хүний биеийг талстжуулдаг халдварт өвчин дэгдсэнээр маш олон улсуудад уг өвчнийг туссан хүмүүсийг гадуурхаж эхлэв. Засгийн газрууд хүртэл өвчин туссан хүмүүсийн эсрэг үйл ажиллагаа явуулж байсан учир тэд бослого гаргаж эхэллээ. Родес арлын эрдэмтэд уг өвчинд эмчилгээ олохын тулд судалгаа хийн, бүх хүмүүсийг аврахыг хичээнэ.",
        totalNumber: "8",
        image: {
            name: "mnfansubs/image/2023/05/07/ufxk7zfw92l85v2j/Нойргүйдэгсэд хичээлийн дараа",
            ext: "jpg",
            ratio: 1.51
        },
        horiImage: null,
        trailer: "https://www.youtube.com/watch?v=_Q4zGuEqi8c&feature=share&utm_source=EJGixIgBCJiu2KjB4oSJEQ",
        readCount: 0,
        movieStatus: "ONGOING",
        status: "ENABLED",
        useSection: false,
        logoImage: null,
        categoryIds: "11 6 ",
        categories: [
            {
                id: 6,
                name: "Тулаан",
                alias: "Action",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 11,
                name: "Уран зөгнөлт",
                alias: "Fantasy",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            }
        ],
        subscriptionLevel: 0
    },
    {
        created: 1665780356,
        createdby: {
            id: 21,
            loginname: "infi",
            givenname: "InFi",
            image: {
                name: "mnfansubs/user/2021/02/08/ey4pg72wugbx8qm6/mnf_logo_for_OP",
                ext: "png",
                ratio: 0.29
            },
            email: "sugaraab59@gmail.com"
        },
        updated: 1665864263,
        updatedby: {
            id: 244,
            loginname: "KiL0",
            givenname: "KiL0",
            image: null,
            email: "erdene6at@gmail.com"
        },
        id: 215,
        type: {
            id: 1,
            name: "Анимэ",
            alias: "anime",
            ordering: 1,
            description: null,
            status: "sodon.admin.common.status.ENABLED"
        },
        name: "Ядаргаатай харийнхан",
        nameEn: "Urusei Yatsura",
        alias: "Urusei_Yatsura",
        year: 2022,
        description: null,
        totalNumber: "23",
        image: {
            name: "mnfansubs/image/2022/10/16/2zzq1oo390hlvkc8/Ядаргаатай харийнхан",
            ext: "jpg",
            ratio: 1.51
        },
        horiImage: null,
        trailer: "https://www.youtube.com/watch?v=unlvnzHlBbk&feature=share&utm_source=EJGixIgBCJiu2KjB4oSJEQ",
        readCount: 0,
        movieStatus: "ONGOING",
        status: "ENABLED",
        useSection: false,
        logoImage: null,
        categoryIds: "2 7 17 33 ",
        categories: [
            {
                id: 2,
                name: "Инээдмийн",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 7,
                name: "Харь гарагийн",
                alias: "Alien",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 17,
                name: "Хайр дурлал",
                alias: "Romance",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 33,
                name: "Шинжлэх ухааны уран зөгнөлт",
                alias: "Sci-Fi",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            }
        ],
        subscriptionLevel: 0
    },
    {
        created: 1634613663,
        createdby: {
            id: 21,
            loginname: "infi",
            givenname: "InFi",
            image: {
                name: "mnfansubs/user/2021/02/08/ey4pg72wugbx8qm6/mnf_logo_for_OP",
                ext: "png",
                ratio: 0.29
            },
            email: "sugaraab59@gmail.com"
        },
        updated: 1667500622,
        updatedby: {
            id: 21,
            loginname: "infi",
            givenname: "InFi",
            image: {
                name: "mnfansubs/user/2021/02/08/ey4pg72wugbx8qm6/mnf_logo_for_OP",
                ext: "png",
                ratio: 0.29
            },
            email: "sugaraab59@gmail.com"
        },
        id: 163,
        type: {
            id: 1,
            name: "Анимэ",
            alias: "anime",
            ordering: 1,
            description: null,
            status: "sodon.admin.common.status.ENABLED"
        },
        name: "Бүрэг Коми",
        nameEn: "Komi-san wa, Comyushou desu.",
        alias: "komi",
        year: 2021,
        description: "Коми Шооко нь ахлах сургуулийн эхний өдрөө ялдам дүр төрхөөрөө хүмүүсийн харцыг булаан сургуулийнхаа Мадонна гэх тодотголыг авна. Төгс төгөлдөр Комид нэгэн нууц асуудал байсан нь байлаа. Тэрхүү нууцыг нь Тадано Хитохито гэх хөвгүүн санаандгүй олж мэдсэн ч Комид 100 найзтай болоход нь туслахаар болно.",
        totalNumber: null,
        image: {
            name: "mnfansubs/image/2021/10/19/ua63a6focyjph9hu/Бүрэг Коми",
            ext: "jpg",
            ratio: 1.51
        },
        horiImage: null,
        trailer: "https://youtu.be/3snByVaQUF0",
        readCount: 0,
        movieStatus: "ONGOING",
        status: "ENABLED",
        useSection: false,
        logoImage: null,
        categoryIds: "2 32 37 ",
        categories: [
            {
                id: 2,
                name: "Инээдмийн",
                alias: null,
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 32,
                name: "Сургуулийн амьдрал",
                alias: "School",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            },
            {
                id: 37,
                name: "Өдөр тутмын амьдрал",
                alias: "Slice of Life",
                description: null,
                status: "sodon.admin.common.status.ENABLED"
            }
        ],
        subscriptionLevel: 0
    },
]

export const fakeLatest = [
    {
        id: 5815,
        name: "Боннигийн уй гашуу! Ирээдүйн аралд ойртох харанхуй",
        movie: {
            id: 1,
            name: "Уан Пийс",
            type: {
                id: 1,
                name: "Анимэ",
                alias: "anime"
            }
        },
        section: {
            id: 223,
            name: "Эггхээд (1086~)"
        },
        type: {
            id: 1,
            name: "Episode",
            alias: "episode"
        },
        image: {
            name: "mnfansubs/image/2024/01/28/juymj2by6plj4pcw/",
            ext: "jpg",
            path: "mnfansubs/image/2024/01/28/juymj2by6plj4pcw/_m.jpg",
            ratio: 0.56
        },
        horiImage: null,
        year: 2024,
        description: null,
        episodeNumber: 1092,
        status: "sodon.admin.common.status.ENABLED",
        published: "2024-01-28T05:00:00Z",
        isFree: false
    },
    {
        id: 5814,
        name: "18-р анги",
        movie: {
            id: 162,
            name: "Цаг хугацааны төлөөлөгч",
            type: {
                id: 1,
                name: "Анимэ",
                alias: "anime"
            }
        },
        section: {
            id: 222,
            name: "2-р бүлэг (11-23)"
        },
        type: {
            id: 1,
            name: "Episode",
            alias: "episode"
        },
        image: {
            name: "mnfansubs/image/2024/01/27/dlrm5m5gdsd9xexq/",
            ext: "jpg",
            path: "mnfansubs/image/2024/01/27/dlrm5m5gdsd9xexq/_m.jpg",
            ratio: 0.56
        },
        horiImage: null,
        year: 2023,
        description: null,
        episodeNumber: 18,
        status: "sodon.admin.common.status.ENABLED",
        published: "2024-01-27T08:07:00Z",
        isFree: false
    },
    {
        id: 5813,
        name: "Нэгэн өдөр Цайзат хотод болсон явдал",
        movie: {
            id: 247,
            name: "Хар хошоонгор",
            type: {
                id: 1,
                name: "Анимэ",
                alias: "anime"
            }
        },
        section: {
            id: 229,
            name: "Шидтэн хүлэг баатрын элсэлт (1-13)"
        },
        type: {
            id: 1,
            name: "Episode",
            alias: "episode"
        },
        image: {
            name: "mnfansubs/image/2024/01/25/71mpqvzqr7ar94t5/Black_Clover_011",
            ext: "jpg",
            path: "mnfansubs/image/2024/01/25/71mpqvzqr7ar94t5/Black_Clover_011_m.jpg",
            ratio: 0.56
        },
        horiImage: null,
        description: null,
        episodeNumber: 11,
        status: "sodon.admin.common.status.ENABLED",
        published: "2024-01-26T13:33:00Z",
        isFree: false
    },
    {
        id: 5812,
        name: "Хамгаалах хүмүүс",
        movie: {
            id: 247,
            name: "Хар хошоонгор",
            type: {
                id: 1,
                name: "Анимэ",
                alias: "anime"
            }
        },
        section: {
            id: 229,
            name: "Шидтэн хүлэг баатрын элсэлт (1-13)"
        },
        type: {
            id: 1,
            name: "Episode",
            alias: "episode"
        },
        image: {
            name: "mnfansubs/image/2024/01/25/rr17kiao255rgw8p/Black_Clover_010",
            ext: "jpg",
            path: "mnfansubs/image/2024/01/25/rr17kiao255rgw8p/Black_Clover_010_m.jpg",
            ratio: 0.56
        },
        horiImage: null,
        description: null,
        episodeNumber: 10,
        status: "sodon.admin.common.status.ENABLED",
        published: "2024-01-26T11:31:00Z",
        isFree: false
    },
    {
        id: 5811,
        name: "Мангасууд",
        movie: {
            id: 247,
            name: "Хар хошоонгор",
            type: {
                id: 1,
                name: "Анимэ",
                alias: "anime"
            }
        },
        section: {
            id: 229,
            name: "Шидтэн хүлэг баатрын элсэлт (1-13)"
        },
        type: {
            id: 1,
            name: "Episode",
            alias: "episode"
        },
        image: {
            name: "mnfansubs/image/2024/01/25/l9k11741767diycz/Black_Clover_009",
            ext: "jpg",
            path: "mnfansubs/image/2024/01/25/l9k11741767diycz/Black_Clover_009_m.jpg",
            ratio: 0.56
        },
        horiImage: null,
        description: null,
        episodeNumber: 9,
        status: "sodon.admin.common.status.ENABLED",
        published: "2024-01-26T11:30:00Z",
        isFree: false
    },
    {
        id: 5810,
        name: "Урагшаа! Урагшаа! Анхны даалгавар",
        movie: {
            id: 247,
            name: "Хар хошоонгор",
            type: {
                id: 1,
                name: "Анимэ",
                alias: "anime"
            }
        },
        section: {
            id: 229,
            name: "Шидтэн хүлэг баатрын элсэлт (1-13)"
        },
        type: {
            id: 1,
            name: "Episode",
            alias: "episode"
        },
        image: {
            name: "mnfansubs/image/2024/01/25/0d710drftg3anvob/Black_Clover_008",
            ext: "jpg",
            path: "mnfansubs/image/2024/01/25/0d710drftg3anvob/Black_Clover_008_m.jpg",
            ratio: 0.56
        },
        horiImage: null,
        description: null,
        episodeNumber: 8,
        status: "sodon.admin.common.status.ENABLED",
        published: "2024-01-26T11:24:00Z",
        isFree: false
    },
    {
        id: 5809,
        name: "Өөр нэгэн шинэ элсэгч",
        movie: {
            id: 247,
            name: "Хар хошоонгор",
            type: {
                id: 1,
                name: "Анимэ",
                alias: "anime"
            }
        },
        section: {
            id: 229,
            name: "Шидтэн хүлэг баатрын элсэлт (1-13)"
        },
        type: {
            id: 1,
            name: "Episode",
            alias: "episode"
        },
        image: {
            name: "mnfansubs/image/2024/01/25/g0i9xckw1ooiey7z/Black_Clover_007",
            ext: "jpg",
            path: "mnfansubs/image/2024/01/25/g0i9xckw1ooiey7z/Black_Clover_007_m.jpg",
            ratio: 0.56
        },
        horiImage: null,
        description: null,
        episodeNumber: 7,
        status: "sodon.admin.common.status.ENABLED",
        published: "2024-01-26T11:23:00Z",
        isFree: false
    },
    {
        id: 5808,
        name: "Хар бух",
        movie: {
            id: 247,
            name: "Хар хошоонгор",
            type: {
                id: 1,
                name: "Анимэ",
                alias: "anime"
            }
        },
        section: {
            id: 229,
            name: "Шидтэн хүлэг баатрын элсэлт (1-13)"
        },
        type: {
            id: 1,
            name: "Episode",
            alias: "episode"
        },
        image: {
            name: "mnfansubs/image/2024/01/25/hjjckr1iybzl0y64/Black_Clover_006",
            ext: "jpg",
            path: "mnfansubs/image/2024/01/25/hjjckr1iybzl0y64/Black_Clover_006_m.jpg",
            ratio: 0.56
        },
        horiImage: null,
        description: null,
        episodeNumber: 6,
        status: "sodon.admin.common.status.ENABLED",
        published: "2024-01-26T11:19:00Z",
        isFree: false
    }
]





export const fakeUser = [
    {
        "id": 12345,
        "username": "johndoe",
        "surename" : "telubek",
        image: {
            original:
                "https://m.media-amazon.com/images/M/MV5BMGMyOThiMGUtYmFmZi00YWM0LWJiM2QtZGMwM2Q2ODE4MzhhXkEyXkFqcGdeQXVyMjc2Nzg5OTQ@._V1_FMjpg_UX1000_.jpg",
        },
        "email": "johndoe@example.com",
        "firstName": "John",
        "lastName": "Doe",
        "gender": "male",
        "birthday":"1998-10-10",
        "age": 30,
        "address": {
            "street": "123 Main St",
            "city": "Anytown",
            "state": "CA",
            "zipCode": "12345"
        },
        "phoneNumber": "555-5678",
        "isActive": true,
        "registrationDate": "2023-01-15T14:22:00Z"
    }
];
