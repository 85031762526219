import React, {useEffect, useState} from 'react'
import {get} from "sodon-http/API";
import HeaderCard from "../../components/common/header-author/Header-Card";
import {API_URL} from "../../constans/API_URL";
import CardCommon from "../../components/cards/CardCommon";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import MovieModal from "../../components/modal/MovieModal";
import ArrowLeft from "../../components/icons/ArrowLeft";
import {useNavigate} from "react-router-dom";
import PaginateTest from "../../components/paginate/paginate-test";

export default function PageAllProjectsCompleted() {



    const navigate = useNavigate();

    const [movieData, setMovieData] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastPageNumber, setLastPageNumber] = useState(1)

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);



    useEffect(() => {
        getCompletedMovie();
        window.scrollTo(0, 0);
    }, [currentPageNumber]);


    const getCompletedMovie = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/list?page=${currentPageNumber}&movieStatus=COMPLETED&size=24`);
            if ((await response).success) {
                setMovieData((await response).payload.thisPageElements);
                setLastPageNumber((await response).payload.lastPageNumber);
            }
        } catch (e) {
        }
    }


    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }


    return (
        <div className={"container"}>
            <div className={"flex items-center gap-3"}>
                <div className="button-md button-primary" onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </div>
                <div className={"page-header-title"}>
                    Дуусгасан төслүүд
                </div>
            </div>
            <div className={"mt-5 grid xl:grid-cols-8 lg:grid-cols-8 gap-4 md:grid-cols-4 sm:grid-cols-3 max-sm:grid-cols-2"}>
                {
                    movieData.map((item, index) => (
                        <CardCommon key={index} item={item}   onClick={() => {openDialog(item)}}/>
                    ))
                }
            </div>
            <PaginateTest setCurrentPageNumber={setCurrentPageNumber} currentPageNumber={currentPageNumber} lastPageNumber={lastPageNumber}/>
            <CustomPopup open={isDialogOpen} close={handleClose}>
                <MovieModal
                    item={selectedItem}
                    shown={isDialogOpen}
                    setShown={value => setIsDialogOpen(value)}
                />
            </CustomPopup>
        </div>
    )
}
