import React, {useEffect, useState} from 'react'
import CardCommon from "../../components/cards/CardCommon";
import {API_URL} from "../../constans/API_URL";
import {get} from "sodon-http/API";
import SlideDrawer from "../../components/filter/SlideDrawer";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import MovieModal from "../../components/modal/MovieModal";
import PaginateTest from "../../components/paginate/paginate-test";

export default function PageAnimeAllProjects() {


    const [drawerOpen, setDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [movieData, setMovieData] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastPageNumber, setLastPageNumber] = useState(1)


    useEffect(() => {
        fetchAnimes();
        window.scrollTo(0, 0);
    }, [currentPageNumber]);

    const fetchAnimes = async (collectedParams) => {
        let baseURL = `${API_URL}/api/movie/list`
        let urlWithParams = collectedParams !== undefined ? `${baseURL}${collectedParams}` : baseURL;
        let pageQueryParam = collectedParams !== undefined ? "&page=" : "?page=";
        let finalURL = `${urlWithParams}${pageQueryParam}${currentPageNumber}&size=30`;
        try {
            const response = await get(finalURL);
            if (response.success) {
                setMovieData(response.payload.thisPageElements);
                setLastPageNumber(response.payload.lastPageNumber);
            }
        } catch (e) {

        }
    }


    function handleOpenDrawerButton() {
        setDrawerOpen(!drawerOpen);
    }

    function handleBackdropClick() {
        setDrawerOpen(false);
    }

    let drawerClasses = drawerOpen ? "side-drawer open" : "side-drawer";


    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }

    return (
        <div className={"container"}>
            <div>
                <div className={"flex justify-between"}>
                    <div className={"page-header-title"}>
                        Бүх төсөлүүд
                    </div>
                    <button onClick={handleOpenDrawerButton} className={"button-md button-primary"}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16667 17.5L4.16667 12.5M4.16667 12.5C5.08714 12.5 5.83333 11.7538 5.83333 10.8333C5.83333 9.91286 5.08714 9.16667 4.16667 9.16667C3.24619 9.16667 2.5 9.91286 2.5 10.8333C2.5 11.7538 3.24619 12.5 4.16667 12.5ZM4.16667 5.83333V2.5M10 17.5V12.5M10 5.83333V2.5M10 5.83333C9.07953 5.83333 8.33333 6.57953 8.33333 7.5C8.33333 8.42047 9.07953 9.16667 10 9.16667C10.9205 9.16667 11.6667 8.42047 11.6667 7.5C11.6667 6.57953 10.9205 5.83333 10 5.83333ZM15.8333 17.5V14.1667M15.8333 14.1667C16.7538 14.1667 17.5 13.4205 17.5 12.5C17.5 11.5795 16.7538 10.8333 15.8333 10.8333C14.9129 10.8333 14.1667 11.5795 14.1667 12.5C14.1667 13.4205 14.9129 14.1667 15.8333 14.1667ZM15.8333 7.5V2.5" stroke="#A7A5A5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Шүүлтүүр
                    </button>
                </div>
                <div className={"container-center"}>
                    <div className={"mt-5 grid xl:grid-cols-6 lg:grid-cols-6 gap-4 md:grid-cols-5 sm:grid-cols-4 max-sm:grid-cols-2"}>
                        {movieData.map((item, index) => (
                            <CardCommon key={item.id} item={item} index={index} onClick={() => {openDialog(item)}} />
                        ))}
                    </div>
                </div>
                <PaginateTest setCurrentPageNumber={setCurrentPageNumber} currentPageNumber={currentPageNumber} lastPageNumber={lastPageNumber}/>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <MovieModal
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
                <SlideDrawer toggle={handleOpenDrawerButton} show={drawerOpen} fetchAnimes={val => fetchAnimes(val)} setCurrentPageNumber={setCurrentPageNumber}  />
                {drawerOpen && <div className="backdrop" onClick={handleBackdropClick}></div> }
            </div>
        </div>
    );
}
