import React from 'react'

export default function NotFound() {
    return (
        <div className={"container"}>
            <div className={"container-center"}>
                <div className={"flex"}>
                    <div className="flex-initial w-80">
                        <div style={{fontSize: "64px", color: "#fff"}}>
                            Oops
                        </div>
                        <div style={{fontSize: "24px", color: "#fff"}}>
                            We cant seem to find the page you are looking for
                        </div>
                        <div style={{fontSize: "15px", color: "#fff", fontWeight:700, marginTop: "10px"}}>
                            Error code : 404
                        </div>
                    </div>
                    <div className="flex-initial w-25">
                        <img src="images/404.svg" alt="" style={{width: '100%', height: '100%', borderRadius: '5px'}}/>
                    </div>


                    </div>


                </div>
            </div>
            )
}
