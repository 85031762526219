import React, {useState} from 'react'
import {useUserStore} from "../../constans/StoreUserData";
import ProfileInfo from "../../components/pages/profile/ProfileInfo";

export default function Profile() {


    const { user, setUser } = useUserStore();

    return (
        <>
            <div className={"container-sm"}>
                <ProfileInfo user={user}/>
            </div>
        </>

    )
}
