function Mail(props) {

    const stroke = props.stroke ? props.stroke : "#A7A5A5"
    const fill =  props.fill ? props.fill : "" ;


    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={fill}>
            <path
                d="M17.917 15L12.3813 10M7.61937 10L2.08369 15M1.66699 5.83334L8.47109 10.5962C9.02207 10.9819 9.29756 11.1747 9.59721 11.2494C9.8619 11.3154 10.1387 11.3154 10.4034 11.2494C10.7031 11.1747 10.9786 10.9819 11.5296 10.5962L18.3337 5.83334M5.66699 16.6667H14.3337C15.7338 16.6667 16.4339 16.6667 16.9686 16.3942C17.439 16.1545 17.8215 15.7721 18.0612 15.3017C18.3337 14.7669 18.3337 14.0668 18.3337 12.6667V7.33334C18.3337 5.93321 18.3337 5.23315 18.0612 4.69837C17.8215 4.22796 17.439 3.84551 16.9686 3.60583C16.4339 3.33334 15.7338 3.33334 14.3337 3.33334H5.66699C4.26686 3.33334 3.5668 3.33334 3.03202 3.60583C2.56161 3.84551 2.17916 4.22796 1.93948 4.69837C1.66699 5.23315 1.66699 5.93321 1.66699 7.33334V12.6667C1.66699 14.0668 1.66699 14.7669 1.93948 15.3017C2.17916 15.7721 2.56161 16.1545 3.03202 16.3942C3.5668 16.6667 4.26686 16.6667 5.66699 16.6667Z"
                stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Mail;
