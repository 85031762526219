import React from "react";
import {Link} from "react-router-dom";
import SearchPop from "./search-popup/Search-pop";
import User from "../icons/User";
import MenuDropdown from "../menu-dropdown/Menu-Dropdown";
import Wallet from "../icons/Wallet";
import BookmarkAdd from "../icons/Bookmark-add";
import Eye from "../icons/Eye";
import Menu from "../icons/Menu";
import LogoSvg from "../icons/LogoSvg";


class Navbar extends React.Component {

    showMenu = false;

    menu;
    menuRect;
    navRect;
    rightRect;
    menuButton;

    constructor(props) {
        super(props);
        this.navRef = React.createRef();
        this.navRightRef = React.createRef();
        this.menuRef = React.createRef();
        this.menuButtonRef = React.createRef();
    }

    componentDidMount() {
        this.menu = this.menuRef.current;
        this.menuButton = this.menuButtonRef.current;
        this.menuRect = this.menuRef.current.getBoundingClientRect();
        this.navRect = this.navRef.current.getBoundingClientRect();
        this.rightRect = this.navRightRef.current.getBoundingClientRect();
    }

    toggleMenu() {
        if (!this.showMenu) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
    }

    openMenu() {
        console.log(this.navRef);
        console.log(this.navRightRef);
        console.log(this.menu);
        this.menu.style.opacity = 1;
        this.menu.style.pointerEvents = "fill";
        this.menu.style.top = `${(this.navRect.height + this.navRect.top) + 20}px`;
        if (window.innerWidth <= 1440) {
            this.menu.style.right = `20px`;
        } else {
            this.menu.style.right = `${this.navRef.current.offsetLeft}px`;
        }
        this.showMenu = true;
        setTimeout(() => {
            document.onclick = this.clickOutsideMenu;
        }, 300);
    }

    closeMenu() {
        this.menu.style.opacity = 0;
        this.menu.style.pointerEvents = "none";
        this.menu.style.top = `${(this.navRect.height + this.navRect.top) + 20}px`;
        this.menu.style.right = `-100%`;
        this.showMenu = false;
        document.onclick = null;
    }

    clickOutsideMenu = (e) => {
        console.log("Outside", this.menu);
        if (this.menu && this.menuButton) {
            if (e.composedPath() && !e.composedPath().includes(this.menu)) {
                this.closeMenu();
            }
        }
    }

    render() {
        return (
            <>
                <nav className='navbar'>
                    <div ref={this.navRef} className='navbar-container' >
                        <Link to="/" className="logo" >
                           <LogoSvg/>
                        </Link>
                        <div ref={this.navRightRef} className="navbar-right" style={{position: "relative"}}>
                            <div className="nav-group">
                                <SearchPop />
                                <button ref={this.menuButtonRef} className="button-nav" onClick={() => this.toggleMenu()}>
                                    <Menu color={"#fff"}/>
                                </button>
                                <Link to="/saved" className="button-nav">
                                    <BookmarkAdd stroke={"#fff"}/>
                                </Link>
                                <Link to="/qpay" className="button-nav">
                                    <Wallet stroke={"#fff"}/>
                                </Link>
                                <Link to="/watched" className="button-nav">
                                    <Eye stroke={"#fff"}/>
                                </Link>
                            </div>
                            <div>
                                <Link to={"/client"}  className="button-md button-original">
                                    <User stroke={"#fff"}/>
                                    Миний
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>
                <MenuDropdown ref={this.menuRef} close={() => this.closeMenu()}/>
            </>
        )
    }
}

export default Navbar;
