function BookmarkDouble(props) {

    const style = props.style ? {...props.style} : {}
    const color = props.color  ? props.color : "#A7A5A5";

    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="34" height="43" viewBox="0 0 34 43" fill="none">
            <path d="M16.8016 34.7042L0.5 41.7523V1.01318H33.5V41.7523L17.1984 34.7042L17 34.6185L16.8016 34.7042Z" fill="#2E2E2E" stroke={color}/>

            <g transform="translate(7 8.5)">
                <path d="M9.99999 4.67993V16.3466M4.16666 10.5133H15.8333" stroke="#00b3ff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>

    )
}

export default BookmarkDouble;
