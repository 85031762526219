function ArrowRight(props) {

    const style = props.style ? {...props.style} : {}
    const color = props.color ? props.color : "#a7a5a5";

    return (
        <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.875 8.34375L9.6875 13.8438C9.5625 13.9479 9.4375 14 9.3125 14C9.16667 14 9.04167 13.9583 8.9375 13.875C8.72917 13.6458 8.71875 13.4062 8.90625 13.1562L13.3125 8.5H1.53125C1.19792 8.45833 1.02083 8.30208 1 8.03125C1.02083 7.71875 1.19792 7.54167 1.53125 7.5H13.3125L8.90625 2.84375C8.71875 2.59375 8.72917 2.35417 8.9375 2.125C9.1875 1.9375 9.4375 1.94792 9.6875 2.15625L14.875 7.65625C15.0417 7.88542 15.0417 8.11458 14.875 8.34375Z"
                fill={color}/>
        </svg>
    )
}

export default ArrowRight;
