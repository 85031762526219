import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {Alert} from "@mui/material";

const CustomAlert = ({ isVisible, type, message }) => {

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    initial={{ opacity: 0, x: 50, scale: 0.3 }} // Start from the right
                    animate={{ opacity: 1, x: 0, scale: 1 }}   // Move to its original position
                    exit={{ opacity: 0, x: -50, scale: 0.5, transition: { duration: 0.2 } }} // Exit to the left
                    style={{
                        position: 'fixed',
                        top: '1%',
                        right: '1%',
                        zIndex: 1000,
                    }}
                >
                    <Alert
                        variant="filled"
                        severity={type}
                    >
                        <div style={{ color: "#FFF" }}>
                            {message}
                        </div>
                    </Alert>
                </motion.div>
            )}
        </AnimatePresence>

    );
};

export default CustomAlert;
