import './cards.css'
import React, {useEffect, useState} from "react";
import BookmarkAdd from "../icons/Bookmark-add";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import BookmarkMinus from "../icons/BookmarkMinus";
import CustomAlert from "../common/CustomAlert";
import {Link} from "react-router-dom";


function CardLatest({item}){



    // hadgalah button
    const [isLoading, setIsLoading] = useState(false)
    const [checkSaved, setCheckSaved] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");


    const handleSaveItem = async () => {
        setAlertVisible(true);
        try {
            const form = {
                module: 'MOVIE-EP',
                moduleItemId: item.id,
                moduleItemName: item.name,
                moduleItemDesc: item.episodeNumber,
                moduleItemImage: `${item.image?.name}.${item.image?.ext}`
            };
            // Make the POST request
            const response = await postJson(`${API_URL}/api/client/user/savedItem/saveOrDelete`, form);
            if (response.status === 200 && response.success) {
                setCheckSaved(!checkSaved);
                setAlertType(!checkSaved === true ? 'success' : 'warning');
                setAlertMessage(!checkSaved === true ? 'Амжилттай хадгалагдлаа ' : 'Амжилттай устгагдлаа')
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error saving item:", error);
            alert('Алдаа', error);
        }
    };



    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await get(`${API_URL}/api/client/user/savedItem/check?module=movie-ep&moduleItemId=${item.id}`);
                if (response.success) {
                    const data = response.payload;
                    if (typeof data === 'boolean') {
                        setCheckSaved(data);
                    }
                }
            } catch (error) {

            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [item.id]);

    let imageUri;

    if (item.image?.path) {
        imageUri = `https://www.mnfansubs.net/resource/${item.image?.path}`;
    }  else if (item.image) {
        imageUri = `https://www.mnfansubs.net/resource/${item.image?.name}_s.${item.image?.ext}`;
    } else {
        imageUri = ''
    }


    return(
        <>
            <div>
                <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
                <div className="cart-latest">
                    <div className="ep-number">
                        <div>
                            {item.episodeNumber}-р анги
                        </div>
                    </div>
                    <div className="img">
                        <img src={imageUri} alt=""/>
                    </div>
                    <div className="icon">
                        <button onClick={handleSaveItem}>
                            {
                                checkSaved === false &&
                                <div className={""}>
                                    <BookmarkAdd color={"#fff"}/>
                                </div>
                            }
                            {
                                checkSaved === true &&
                                <div className={""}>
                                    <BookmarkMinus color={"green"}/>
                                </div>
                            }
                        </button>
                    </div>
                    <Link to={`/player/episode/${item.id}`}
                        movieId={item.movie.id}
                        className="wrapper"
                    >
                        <div className="content">
                            <div className="title">
                                <span>
                                    {item.movie.name}
                                </span>
                            </div>
                            <div className="duration">
                                <div>
                                    {item.duration}
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="cart-latest-mobile">
                    <div className="ep-number">
                        {item.episodeNumber}-р анги
                    </div>
                    <Link to={`/player/episode/${item.id}`}>
                        <div className="img">
                            <img src={`https://www.mnfansubs.net/resource/${item.image?.name}.${item.image?.ext}`} alt=""/>
                        </div>
                        <div className="title">{item.name}</div>
                    </Link>
                    <div className="duration">{item.duration}</div>
                </div>
            </div>
        </>

    )

}

export default CardLatest
