function Calendar(props) {

    const style = props.style ? {...props.style} : {}
    const color =  props.color ? props.color : "#A7A5A5" ;


    return (
        <svg style={style} width="29" height="32" viewBox="0 0 29 32" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 0C8.125 0.0416667 8.45833 0.375 8.5 1V4H20.5V1C20.5417 0.375 20.875 0.0416667 21.5 0C22.125 0.0416667 22.4583 0.375 22.5 1V4H24.5C25.625 4.04167 26.5625 4.4375 27.3125 5.1875C28.0625 5.9375 28.4583 6.875 28.5 8V28C28.4583 29.125 28.0625 30.0625 27.3125 30.8125C26.5625 31.5625 25.625 31.9583 24.5 32H4.5C3.375 31.9583 2.4375 31.5625 1.6875 30.8125C0.9375 30.0625 0.541667 29.125 0.5 28V8C0.541667 6.875 0.9375 5.9375 1.6875 5.1875C2.4375 4.4375 3.375 4.04167 4.5 4H6.5V1C6.54167 0.375 6.875 0.0416667 7.5 0ZM26.5 12H20V16.5H26.5V12ZM26.5 18.5H20V23.5H26.5V18.5ZM26.5 25.5H20V30H24.5C25.0833 30 25.5625 29.8125 25.9375 29.4375C26.3125 29.0625 26.5 28.5833 26.5 28V25.5ZM18 23.5V18.5H11V23.5H18ZM11 30H18V25.5H11V30ZM9 23.5V18.5H2.5V23.5H9ZM2.5 25.5V28C2.5 28.5833 2.6875 29.0625 3.0625 29.4375C3.4375 29.8125 3.91667 30 4.5 30H9V25.5H2.5ZM2.5 16.5H9V12H2.5V16.5ZM11 16.5H18V12H11V16.5ZM24.5 6H4.5C3.91667 6 3.4375 6.1875 3.0625 6.5625C2.6875 6.9375 2.5 7.41667 2.5 8V10H26.5V8C26.5 7.41667 26.3125 6.9375 25.9375 6.5625C25.5625 6.1875 25.0833 6 24.5 6Z"
                fill={color}/>
        </svg>

    )
}

export default Calendar;
