function Settings(props) {

    const stroke = props.stroke ? props.stroke : "#A7A5A5"
    const fill =  props.fill ? props.fill : "" ;


    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill={fill}>
            <path
                d="M8.49587 16.1429L8.98291 17.2383C9.12769 17.5644 9.36397 17.8414 9.66309 18.0358C9.96222 18.2303 10.3113 18.3337 10.6681 18.3337C11.0249 18.3337 11.374 18.2303 11.6731 18.0358C11.9722 17.8414 12.2085 17.5644 12.3533 17.2383L12.8403 16.1429C13.0137 15.7542 13.3053 15.4302 13.6736 15.217C14.0443 15.0032 14.4731 14.9121 14.8986 14.9568L16.0903 15.0837C16.445 15.1212 16.803 15.055 17.1209 14.8931C17.4387 14.7312 17.7028 14.4806 17.8811 14.1716C18.0595 13.8628 18.1446 13.5089 18.1258 13.1527C18.1071 12.7966 17.9854 12.4535 17.7755 12.1651L17.0699 11.1957C16.8187 10.8479 16.6845 10.4293 16.6866 10.0003C16.6865 9.57248 16.822 9.15562 17.0736 8.80959L17.7792 7.84014C17.9891 7.55179 18.1108 7.20872 18.1295 6.85255C18.1483 6.49639 18.0632 6.14244 17.8848 5.83366C17.7065 5.52469 17.4424 5.27407 17.1246 5.11218C16.8067 4.9503 16.4487 4.8841 16.094 4.92162L14.9023 5.04847C14.4768 5.09317 14.048 5.00209 13.6773 4.78829C13.3083 4.57387 13.0166 4.24812 12.844 3.85773L12.3533 2.76236C12.2085 2.4363 11.9722 2.15925 11.6731 1.96482C11.374 1.77039 11.0249 1.66693 10.6681 1.66699C10.3113 1.66693 9.96222 1.77039 9.66309 1.96482C9.36397 2.15925 9.12769 2.4363 8.98291 2.76236L8.49587 3.85773C8.32331 4.24812 8.0316 4.57387 7.66253 4.78829C7.29188 5.00209 6.86309 5.09317 6.43754 5.04847L5.24216 4.92162C4.88744 4.8841 4.52945 4.9503 4.21159 5.11218C3.89374 5.27407 3.62968 5.52469 3.45142 5.83366C3.27294 6.14244 3.18791 6.49639 3.20665 6.85255C3.22538 7.20872 3.34708 7.55179 3.55698 7.84014L4.26253 8.80959C4.51415 9.15562 4.64965 9.57248 4.64957 10.0003C4.64965 10.4282 4.51415 10.845 4.26253 11.1911L3.55698 12.1605C3.34708 12.4489 3.22538 12.7919 3.20665 13.1481C3.18791 13.5043 3.27294 13.8582 3.45142 14.167C3.62985 14.4758 3.89395 14.7263 4.21176 14.8882C4.52956 15.05 4.88747 15.1163 5.24216 15.079L6.43383 14.9522C6.85939 14.9075 7.28818 14.9986 7.65883 15.2124C8.02927 15.4262 8.32234 15.752 8.49587 16.1429Z"
                stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M10.6666 12.5003C12.0473 12.5003 13.1666 11.381 13.1666 10.0003C13.1666 8.61961 12.0473 7.50033 10.6666 7.50033C9.28589 7.50033 8.1666 8.61961 8.1666 10.0003C8.1666 11.381 9.28589 12.5003 10.6666 12.5003Z"
                stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Settings;
