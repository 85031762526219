import React,{useState,useEffect} from 'react'
import Payment from "../../components/icons/Payment";
import Calendar from "../../components/icons/Calendar";
import ArrowRight from "../../components/icons/ArrowRight";
import {Link} from "react-router-dom";
import ArrowLeft from "../../components/icons/ArrowLeft";
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";


export default function QpayGuest() {




    const [types, setTypes] = useState([]);

    useEffect(() => {
        getSubscriptionTypes();
    }, []);



    const getSubscriptionTypes = async () => {
        try {
            const response = await get(`${API_URL}/api/user/subscription/type`);
            if ((await response).success) {
                setTypes((await response).payload);
            } else {
                console.warn("getOngoingMovie", (await response).message);
            }
        } catch (e) {
        }
    }








    return (
        <div>
            <div className={"container-center"} style={{height: "50vh"}}>
                <div className={"qpay"}>
                    <div className={"header"}>
                        <Link to={"/guide/public"} className={"button-md button-primary"}>
                            <ArrowLeft/>
                        </Link>
                        <div>
                          <span>
                              Төлбөрийн мэдээлэл
                          </span>
                        </div>
                    </div>
                    <div className={"grid lg:grid-cols-3 gap-3 md:grid-cols-3 sm:grid-cols-2 max-sm:grid-cols-2"}>
                        {
                            types.map((item) => {
                                return (
                                    <div>
                                        <div className={"body"}>
                                            <div className={"gap-1 flex flex-col"}>
                                                <div>
                                                    <Calendar />
                                                </div>
                                                <div className={"year"}>
                                                    <span>
                                                       {item.alias.substring(0, 1)} сар
                                                    </span>
                                                </div>
                                                <div className={"price"}>
                                                    <span>
                                                        {item.price}₮
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
