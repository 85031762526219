import React ,{useState} from 'react'
import Plus from "../../components/icons/Plus";
import User from "../../components/icons/User";
import {Link, useNavigate} from "react-router-dom";
import ArrowLeft from "../../components/icons/ArrowLeft";
import Eye from "../../components/icons/Eye";
import EyeClosed from "../../components/icons/EyeClosed";
import {postForm, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import CustomAlert from "../../components/common/CustomAlert";

export default function PasswordChange() {

    const navigate = useNavigate();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("");

    const [errors, setErrors] = useState('');
    const [passwordType, setPasswordType] = useState("password");

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState();
    const [message, setMessage] = useState("");




    const postRequest = async (e) => {
        e.preventDefault();
        try {
            const form = {
                currentPassword: currentPassword,
                password: newPassword,
                passwordConfirm: confirmPassword,
            }
            const response = await postJson(`${API_URL}/api/core/profile/password2`, form);
            if (response.success && response.status === 200) {
                setMessage("Нууц үг амжилттай солигдлоо")
                setAlertType('success');
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                navigate('/client');
            } else if (response.payload.type.toLowerCase() === "error") {
                const _errors = {}
                const data = await response.payload
                data?.errors?.forEach(error => {
                    _errors[error.field] = error.defaultMessage;
                })
                setErrors(_errors);
                console.log("error", errors)
            } else {
                setMessage("Error updating password")
                setAlertType('warning');
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
            }
        } catch (e) {
            setErrors({ general: "An error occurred. Please try again." });
        }
    };


    const ReturnCorrespondingErrorMessage = ({field}) => {
        if (errors[field]) {
            return (
                <div>
                    <label htmlFor={field} className="errorLabel" style={{fontSize: "14px", lineHeight: 1}}>{errors[field]}</label>
                </div>
            )
        } else {
            return <></>
        }
    }

    const handleFocus = (field) => {
        setErrors({ ...errors, [field]: '' });
    };

    const togglePassword = (e) => {
        e.preventDefault();
        setPasswordType(passwordType === 'password' ? 'text' : 'password');
    };

    return (
        <div className={"container-md"}>
            <CustomAlert isVisible={showAlert} type={alertType} message={message}/>
            <div className={"profile-edit"}>
                <div className={"flex gap-3 items-center mb-3"}>
                    <Link to={"/client"}>
                        <div className={"button-md button-primary"}>
                            <ArrowLeft/>
                        </div>
                    </Link>
                    <div className={"header"}>
                        Нууц үг солих
                    </div>
                </div>
                <form onSubmit={postRequest}>
                    <div className={"body"} >
                        <div className={"about sm:grid-cols-3 grid mb-2"}>
                            <div>
                                Хуучин нууц үг
                            </div>
                            <div className={"col-span-2"}>
                                <input
                                    id={"currentPassword"}
                                    className={"input"}
                                    type={passwordType}
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    onFocus={() => handleFocus('currentPassword')}
                                    autoComplete="off"
                                />
                                <ReturnCorrespondingErrorMessage field="currentPassword"/>
                            </div>
                        </div>
                        <div className={"about sm:grid-cols-3 grid mb-2"}>
                            <div>
                                Шинэ нууц үг
                            </div>
                            <div className={"col-span-2"}>
                                <input
                                    className={"input"}
                                    type={passwordType}
                                    id={"password"}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    autoComplete="off"
                                    onFocus={() => handleFocus('password')}
                                />
                                <ReturnCorrespondingErrorMessage field="password"/>
                            </div>
                        </div>
                        <div className={"about sm:grid-cols-3 grid"}>
                            <div>
                                Шинэ нууц үг давтах
                            </div>
                            <div className={"col-span-2 flex  justify-between  input"}>
                                <input
                                    type={passwordType}
                                    value={confirmPassword}
                                    id={"confirmPassword"}
                                    autoComplete="off"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    onFocus={() => handleFocus('confirmPassword')}
                                />
                                <button type="button" onClick={(e) => togglePassword(e)}>
                                    {
                                        passwordType === "password" ?
                                            <Eye/>
                                            :
                                            <EyeClosed/>
                                    }

                                </button>
                            </div>
                        </div>
                        <ReturnCorrespondingErrorMessage field="passwordConfirmed"/>
                    </div>
                    <button className={"button-md button-primary mt-5 w-full"}>
                        <User/>
                        Хадгалах
                    </button>
                </form>
            </div>
        </div>
    )
}
