import React from 'react';

function PauseCircle(props) {

    const color = props.color ? props.color : "#a7a5a5";
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003ZM3.33268 10.0003C3.33268 6.31843 6.31745 3.33366 9.99935 3.33366C13.6812 3.33366 16.666 6.31843 16.666 10.0003C16.666 13.6822 13.6812 16.667 9.99935 16.667C6.31745 16.667 3.33268 13.6822 3.33268 10.0003ZM8.33268 5.83366C8.79292 5.83366 9.16602 6.20675 9.16602 6.66699V13.3337C9.16602 13.7939 8.79292 14.167 8.33268 14.167C7.87245 14.167 7.49935 13.7939 7.49935 13.3337V6.66699C7.49935 6.20675 7.87245 5.83366 8.33268 5.83366ZM12.4993 6.66699C12.4993 6.20675 12.1263 5.83366 11.666 5.83366C11.2058 5.83366 10.8327 6.20675 10.8327 6.66699V13.3337C10.8327 13.7939 11.2058 14.167 11.666 14.167C12.1263 14.167 12.4993 13.7939 12.4993 13.3337V6.66699Z"
                fill={color}/>
        </svg>

    );
}

export default PauseCircle;
