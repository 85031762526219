import React, {useEffect, useRef, useState} from 'react'
import ArrowLeft from "../../components/icons/ArrowLeft";
import User from "../../components/icons/User";
import {Link, useNavigate} from "react-router-dom";
import {useUserStore} from "../../constans/StoreUserData";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import Select from "react-select";
import {customStyles,} from "../../components/common/SelectStyle";
import CustomAlert from "../../components/common/CustomAlert";
import Calendar from 'react-calendar';
import "./Calendar.css"


export default function ProfileEdit() {

    const {user, setUser} = useUserStore();
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);


    //Alerts
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState();
    const [message, setMessage] = useState("");

    const [loginname, setLoginname] = useState("");
    const [surname, setSurname] = useState("");
    const [givenname, setGivenname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [birthDate, setBirthDate] = useState(new Date());


    const [profilePhotoUri, setProfilePhotoUri] = useState(null);
    const [newPhotoUri, setNewPhotoUri] = useState(null);
    const fileInputRef = useRef(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [chosenImage, setChosenImage] = useState(null);
    const [imageDataUrl, setImageDataUrl] = useState(null);
    const [imageBlob, setImageBlob] = useState(null);


    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedBirthday, setSelectedBirthday] = useState('Төрсөн өдөр сонгох');
    const today = new Date(birthDate);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get(`${API_URL}/api/user/${user.id}`);
                if (response.success) {
                    setLoginname(response.payload.loginname);
                    setSurname(response.payload.surname);
                    setGender(response.payload.gender)
                    setBirthDate(response.payload.birthDate)
                    setGivenname(response.payload.givenname);
                    setEmail(response.payload.email);
                    setPhone(response.payload.phone);
                    if (response.payload.photo)
                        setProfilePhotoUri(`${API_URL}resource/${response.payload.photo.name}.${response.payload.photo.ext}`)
                    setNewPhotoUri(null);
                }
            } catch (error) {
                console.log(error)
            }
        };
        fetchData();
    }, [user.id]);



    const profileChange = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));
        try {
            const form = {
                givenname: givenname,
                gender: gender,
                birthDate: birthDate,
                phone: phone,
                surname: surname,
                email: email,
            }
            const response = await postJson(`${API_URL}/api/client/profile/edit`, form);
            if (response.status === 200) {
                console.log("success", response);
                setMessage(response.payload.text)
                setAlertType('success');
                setShowAlert(true);
                setTimeout(() => {
                    navigate("/profile");
                    setShowAlert(false);
                }, 500);

            } else if (response.status === 400) {
                const _errors = {}
                const data = await response.payload
                data?.errors?.forEach(error => {
                    _errors[error.field] = error.defaultMessage;
                })
                setErrors(_errors);
                console.log("errors", errors)

            }
        } catch (e) {
            console.error("Error during profile change:", e);
        }
    };


    const [genderOptions] = useState([
        {value: 'MALE', label: 'Эрэгтэй'},
        {value: 'FEMALE', label: 'Эмэгтэй'},
        {value: 'OTHER', label: 'Бусад'},
    ]);

    const ReturnCorrespondingErrorMessage = ({field}) => {
        if (errors[field]) {
            return (
                <div>
                    <label htmlFor={field} className="errorLabel" style={{fontSize: "14px", lineHeight: 1}}>{errors[field]}</label>
                </div>
            )
        }
    }

    const clearError = (field) => {
        setErrors({...errors, [field]: ''});
    };


    const handleGenderChange = (selectedOption) => {
        setGender(selectedOption.value);
    };

    const selectedGender = genderOptions.find(option => option.value === gender);


    const toggleCalendar = (e) => {
        e.preventDefault();
        setIsCalendarOpen(!isCalendarOpen);
    };

    const onChangeBirthday = (newDate) => {
        const formattedDate = `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`;
        setBirthDate(formattedDate);
        setIsCalendarOpen(false);
        const formattedDisplayDate = newDate.toLocaleDateString('mn-MN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
        setSelectedBirthday(formattedDisplayDate);
    };


    return (
        <div className={"container-sm"}>
            <CustomAlert isVisible={showAlert} type={alertType} message={message}/>
            <div className={"container-center"}>
                <div className={"profile-edit"}>
                    <div className={"flex gap-3 items-center mb-3"}>
                        <Link to={"/profile"}>
                            <div className={"button-md button-primary"}>
                                <ArrowLeft/>
                            </div>
                        </Link>
                        <div className={"header"}>
                            Миний тохиргоо
                        </div>
                    </div>
                    <form onSubmit={profileChange} className={"body"}>
                        <div className={"about sm:grid-cols-3 grid"}>
                            <div>
                                Нэвтрэх нэр
                            </div>
                            <div className={"col-span-2 "}>
                                <input
                                    disabled={true}
                                    className={"disabled-input"}
                                    type="text"
                                    name="title"
                                    value={loginname ?? ""}
                                    onChange={e => setLoginname(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={"about sm:grid-cols-3 grid"}>
                            <div>
                                Овог
                            </div>
                            <div className={"col-span-2 "}>
                                <input
                                    className={"input"}
                                    type="text"
                                    name="title"
                                    value={surname ?? ""}
                                    onChange={e => setSurname(e.target.value)}
                                />
                                <ReturnCorrespondingErrorMessage field="surname"/>
                            </div>
                        </div>
                        <div className={"about sm:grid-cols-3 grid"}>
                            <div>
                                Өөрийн нэр
                            </div>
                            <div className={"col-span-2"}>
                                <input
                                    className={"input"}
                                    type="text"
                                    name="title"
                                    value={givenname ?? ""}
                                    onChange={e => setGivenname(e.target.value)}
                                    autocomplete="off"
                                    onFocus={() => clearError('phone')}
                                />
                                <ReturnCorrespondingErrorMessage field="givenname"/>
                            </div>
                        </div>
                        <div className={"about sm:grid-cols-3 grid"}>
                            <div>
                                Хүйс
                            </div>
                            <Select
                                options={genderOptions}
                                value={selectedGender}
                                onChange={handleGenderChange}
                                placeholder="Хүйс сонгох"
                                styles={customStyles}
                            />
                        </div>
                        <div className={"about sm:grid-cols-3 grid"}>
                            <div>
                                Төрсөн өдөр
                            </div>
                            <div className={"col-span-2"}>
                                <div className={"input"} onClick={toggleCalendar}>
                                    {selectedBirthday} {/* Display the selected or default date string */}
                                </div>
                                {isCalendarOpen && (
                                    <div>
                                        <Calendar
                                            onChange={onChangeBirthday}
                                            value={birthDate ?? ""}
                                            defaultActiveStartDate={today}
                                        />
                                    </div>

                                )}

                            </div>
                        </div>
                        <div className={"about sm:grid-cols-3 grid"}>
                            <div>
                                И-мэйл
                            </div>
                            <div className={"col-span-2"}>
                                <input
                                    className={"input"}
                                    type="text"
                                    name="title"
                                    value={email ?? ""}
                                    autocomplete="off"
                                    onChange={e => setEmail(e.target.value)}
                                    onFocus={() => clearError('email')}
                                />
                                <ReturnCorrespondingErrorMessage field="email"/>
                            </div>

                        </div>
                        <div className={"about sm:grid-cols-3 grid"}>
                            <div>
                                Гар утас
                            </div>
                            <div className={"col-span-2"}>
                                <input
                                    className={"input"}
                                    type="text"
                                    name="title"
                                    value={phone ?? ""}
                                    autocomplete="off"
                                    onFocus={() => clearError('phone')}
                                    onChange={e => {
                                        const value = e.target.value;
                                        const numbersOnly = value.replace(/\D/g, ''); // Remove non-digits
                                        setPhone(numbersOnly);
                                    }}


                                />
                                <ReturnCorrespondingErrorMessage field="phone"/>
                            </div>
                        </div>
                        <button className={"button-md button-primary mt-5"}>
                            <User/> Хадгалах
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
