import FeaturedSwiper from "./swiper/FeaturedSwiper";
import './../App.css'
import React, {useEffect, useState} from "react";
import Latest from "./pages/Cards/Latest";
import Ongoing from "./pages/Cards/Ongoing";
import ThisYear from "./pages/Cards/ThisYear";
import Favorite from "./pages/Cards/Favorite";
import Popular from "./pages/Cards/Popular";
import Author from "./pages/Cards/Author";
import Ghibli from "./pages/Cards/Ghibli";
import Finished from "./pages/Cards/Finished";
import FixedMenu from "./Menu/FixedMenu";
import FixedMenuMobile from "./Menu/FixedMenuMobile";
import Banner from "./common/banner/banner";

function HomePage() {


    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        const documentHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
        const eightyPercentPosition = documentHeight * 0.7;
        if (typeof scrollPosition !== 'undefined' && scrollPosition >= eightyPercentPosition) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };



    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div>
            <FeaturedSwiper/>
            <Latest/>
            <Banner alias={"leader"}/>
            <Ongoing/>
            <ThisYear/>
            <Favorite/>
            <Popular/>
            <Author/>
            <Ghibli/>
            <Finished/>
            <Banner alias={"footer"}/>
            <FixedMenu/>
            <FixedMenuMobile/>
            {isVisible && (
                <button onClick={scrollToTop} className={'home-fixed-button'}>
                    <svg
                        fill="#00b3ff"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                    >
                        <path
                            fillRule="evenodd"
                            d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
                        />
                    </svg>
                </button>

            )}

        </div>
    )
}

export default HomePage;
