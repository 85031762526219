import React, {useEffect, useRef, useState} from 'react'
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import {useLocation, useParams} from "react-router-dom";

export default function PaymentBanks() {


    const {id} = useParams();
    const [invoice, setInvoice] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (id) {
            createSubscription(id);
            getSubscriptionTypes();
        }
    }, [id])

    const banks = [
        {
            name: 'khanbank',
            url: 'khanbank://',
            imageURI: 'https://qpay.mn/q/logo/khanbank.png'
        },
        {
            name: 'statebank',
            url: 'statebank://',
            imageURI: 'https://qpay.mn/q/logo/statebank.png'
        },
        {
            name: 'xacbank',
            url: 'xacbank://',
            imageURI: 'https://qpay.mn/q/logo/xacbank.png'
        },
        {
            name: 'tdbbank',
            url: 'tdbbank://',
            imageURI: 'https://qpay.mn/q/logo/tdbbank.png'
        },
        {
            name: 'most',
            url: 'most://',
            imageURI: 'https://qpay.mn/q/logo/most.png'
        }
    ]

    const createSubscription = async () => {
        setIsLoading(true);
        try {
            const response = await postJson(`${API_URL}/api/client/movie/subscription/create?typeId=${id}`);
            if (response.success) {
                setInvoice(response.payload);
            }
        } catch (error) {
            console.error("Error creating subscription:", error);
        }
        setIsLoading(false);
    };
    const [types, setTypes] = useState([]);


    const getSubscriptionTypes = async (id) => {
        try {
            const response = await get(`${API_URL}/api/user/subscription/type`);
            if (response.success) {
                setTypes(response.payload)
            }
        } catch (e) {
        }
    }


    return (
        <div className={"container-md"}>
            {isLoading ? (
               <div>
                   loading
               </div>
            ) : invoice && (
                <>
                    <div>
                        {
                            types.map(item => (
                                <div>
                                    {item.id === parseInt(id) &&
                                    <div style={{color:"#FFF", fontSize: 18}}>
                                        {item.name}
                                    </div>
                                    }
                                </div>
                            ))
                        }
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 10,
                        justifyContent: "center"
                    }}>
                        <img src={`data:image/png;base64,${invoice.qrImage}`} alt="QR Code"
                             style={{
                                 width: '100%',
                             }}
                        />
                        {banks.map((bank, index) => (
                            <a key={index}
                               href={`${bank.url}q?qPay_QRcode=${invoice.qrText}`}
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <img src={bank.imageURI} alt="Bank Logo"
                                     style={{
                                    width: '1000%',
                                }}/>
                            </a>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}
