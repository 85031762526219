import React,{useState, useEffect} from 'react'
import HeaderCard from "../../common/header-author/Header-Card";
import CardLatest from "../../cards/CardLatest";
import {fakeLatest} from "../../data/fakeData";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import movie from "../../icons/Movie";
import movieModal from "../../modal/MovieModal";

export default function Latest() {


    const [movieData, setMovieData] = useState([]);

    useEffect(() => {
        getLatestEpisodes();
    }, []);

    const getLatestEpisodes = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/latest?size=8`);
            if ((await response).success) {
                setMovieData((await response).payload);
            }
        } catch (e) {
        }
    }

    return (
        <div className={"container"}>
            <HeaderCard title={"Шинээр нэмэгдсэн"} link={"latest-episodes"} />
            <div className={"mt-5 grid lg:gap-4  sm:gap-3 max-sm:gap-2  lg:grid-cols-4  sm:grid-cols-3 max-sm:grid-cols-2"}>
                {
                    movieData.map((item, index) => (
                        <CardLatest key={index} item={item} />
                    ))
                }
            </div>
        </div>
    )
}
