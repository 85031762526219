import React, { useEffect, useState } from 'react';
import "./react-paginate.css"
import ArrowLeft from "../icons/ArrowLeft";
import ArrowRight from "../icons/ArrowRight";

export default function PaginateTest({lastPageNumber, currentPageNumber ,setCurrentPageNumber }) {


    const numbers = Array.from({ length: lastPageNumber }, (_, i) => i + 1);



    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= lastPageNumber; i++) {
            if (i === 1 || i === lastPageNumber) {
                pageNumbers.push(
                    <div
                        key={i}
                        onClick={() => setCurrentPageNumber(i)}
                        style={{
                            backgroundColor: currentPageNumber === i ? '#0E0E0E' : '#2E2E2E',
                            color: currentPageNumber === i ? '#948f8f' : 'white',
                        }}
                    >
                        {i}
                    </div>
                );
            }
            else if (i === currentPageNumber - 1 || i === currentPageNumber || i === currentPageNumber + 1) {
                pageNumbers.push(
                    <div
                        key={i}
                        onClick={() => setCurrentPageNumber(i)}
                        style={{
                            backgroundColor: currentPageNumber === i ? '#0E0E0E' : '#2E2E2E',
                            color: currentPageNumber === i ? '#948f8f' : 'white',
                        }}
                    >
                        {i}
                    </div>
                );
            }
            else if (i === 2 && currentPageNumber > 3) {
                pageNumbers.push(<div key="ell1">
                    <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.974 12.3837C2.24467 12.3837 2.43133 12.4397 2.534 12.5517C2.646 12.6544 2.702 12.8411 2.702 13.1117V13.7837C2.702 14.0544 2.646 14.2457 2.534 14.3577C2.43133 14.4604 2.24467 14.5117 1.974 14.5117H1.442C1.17133 14.5117 0.98 14.4604 0.868 14.3577C0.765333 14.2457 0.714 14.0544 0.714 13.7837V13.1117C0.714 12.8411 0.765333 12.6544 0.868 12.5517C0.98 12.4397 1.17133 12.3837 1.442 12.3837H1.974ZM5.39197 12.3837C5.66264 12.3837 5.8493 12.4397 5.95197 12.5517C6.06397 12.6544 6.11997 12.8411 6.11997 13.1117V13.7837C6.11997 14.0544 6.06397 14.2457 5.95197 14.3577C5.8493 14.4604 5.66264 14.5117 5.39197 14.5117H4.85997C4.5893 14.5117 4.39797 14.4604 4.28597 14.3577C4.1833 14.2457 4.13197 14.0544 4.13197 13.7837V13.1117C4.13197 12.8411 4.1833 12.6544 4.28597 12.5517C4.39797 12.4397 4.5893 12.3837 4.85997 12.3837H5.39197ZM8.80994 12.3837C9.0806 12.3837 9.26727 12.4397 9.36994 12.5517C9.48194 12.6544 9.53794 12.8411 9.53794 13.1117V13.7837C9.53794 14.0544 9.48194 14.2457 9.36994 14.3577C9.26727 14.4604 9.0806 14.5117 8.80994 14.5117H8.27794C8.00727 14.5117 7.81594 14.4604 7.70394 14.3577C7.60127 14.2457 7.54994 14.0544 7.54994 13.7837V13.1117C7.54994 12.8411 7.60127 12.6544 7.70394 12.5517C7.81594 12.4397 8.00727 12.3837 8.27794 12.3837H8.80994Z" fill="#DFDFDF"/>
                    </svg>

                </div>);
            }
            else if (i === lastPageNumber - 1 && currentPageNumber < lastPageNumber - 2) {
                pageNumbers.push(<div key="ell2">
                    <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.974 12.3837C2.24467 12.3837 2.43133 12.4397 2.534 12.5517C2.646 12.6544 2.702 12.8411 2.702 13.1117V13.7837C2.702 14.0544 2.646 14.2457 2.534 14.3577C2.43133 14.4604 2.24467 14.5117 1.974 14.5117H1.442C1.17133 14.5117 0.98 14.4604 0.868 14.3577C0.765333 14.2457 0.714 14.0544 0.714 13.7837V13.1117C0.714 12.8411 0.765333 12.6544 0.868 12.5517C0.98 12.4397 1.17133 12.3837 1.442 12.3837H1.974ZM5.39197 12.3837C5.66264 12.3837 5.8493 12.4397 5.95197 12.5517C6.06397 12.6544 6.11997 12.8411 6.11997 13.1117V13.7837C6.11997 14.0544 6.06397 14.2457 5.95197 14.3577C5.8493 14.4604 5.66264 14.5117 5.39197 14.5117H4.85997C4.5893 14.5117 4.39797 14.4604 4.28597 14.3577C4.1833 14.2457 4.13197 14.0544 4.13197 13.7837V13.1117C4.13197 12.8411 4.1833 12.6544 4.28597 12.5517C4.39797 12.4397 4.5893 12.3837 4.85997 12.3837H5.39197ZM8.80994 12.3837C9.0806 12.3837 9.26727 12.4397 9.36994 12.5517C9.48194 12.6544 9.53794 12.8411 9.53794 13.1117V13.7837C9.53794 14.0544 9.48194 14.2457 9.36994 14.3577C9.26727 14.4604 9.0806 14.5117 8.80994 14.5117H8.27794C8.00727 14.5117 7.81594 14.4604 7.70394 14.3577C7.60127 14.2457 7.54994 14.0544 7.54994 13.7837V13.1117C7.54994 12.8411 7.60127 12.6544 7.70394 12.5517C7.81594 12.4397 8.00727 12.3837 8.27794 12.3837H8.80994Z" fill="#DFDFDF"/>
                    </svg>
                </div>);
            }
        }
        return pageNumbers;
    };

    const prevPage = () => {
        if (currentPageNumber > 1) {
            setCurrentPageNumber(currentPageNumber - 1);
        }
    };



    const nextPage = () => {
        if (currentPageNumber < lastPageNumber) {
            setCurrentPageNumber(currentPageNumber + 1);
        }
    };

    return (
        <>
            <div className={"paginate-common"}>
                <div onClick={prevPage}>
                    <ArrowLeft stroke={ currentPageNumber === 1  ? '#444343' : '#FFF'}/>
                </div>
                {renderPageNumbers()}
                <div onClick={nextPage}>
                    <ArrowRight color={currentPageNumber === lastPageNumber  ? '#444343' : '#FFF'}/>
                </div>
            </div>

        </>
    )
}


