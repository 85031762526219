import React, {useEffect, useState} from "react";
import ReactPlayer from 'react-player'
import Plus from "../../components/icons/Plus";
import Heart from "../../components/icons/Heart";
import Chain from "../../components/icons/Chain";
import ArrowRight from "../../components/icons/ArrowRight";
import ArrowDown from "../../components/icons/ArrowDown";
import EpisodeSection from "../../pages/episode/EpisodeSection";
import {get, postJson} from "sodon-http/API";
import {API_URL, FAKE_API} from "../../constans/API_URL";
import {Link, useParams} from 'react-router-dom';
import Close from "../../components/icons/Close";

import BookmarkMinus from "../../components/icons/BookmarkMinus";
import Play from "../../components/icons/Play";
import CustomAlert from "../../components/common/CustomAlert";


function Manga({item, shown, setShown}) {

    const [selectedSection, setSelectedSection] = useState('');
    const [movieData, setMovieData] = useState([]);



    const [movieEpisodes, setMovieEpisodes] = useState([])
    const [sectionEpisodes, setSectionEpisodes] = useState("")
    const [selectedItem, setSelectedItem] = useState(null);



    // хадгалах товч
    const [isLoading, setIsLoading] = useState(false)
    const [checkSaved, setCheckSaved] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");



    useEffect(() => {
        fetchManga();
        fetchEpisodes();
    }, [item]);


    useEffect(() => {
        if (selectedSection) {
            fetchEpisodes('episode', selectedSection)
        }
    }, [selectedSection])


    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await get(`${API_URL}/api/client/user/savedItem/check?module=manga&moduleItemId=${item.moduleItemId ? item.moduleItemId : item.id}`);
                if (response.success) {
                    const data = response.payload;
                    if (typeof data === 'boolean') {
                        setCheckSaved(data);
                    }
                }
            } catch (error) {

            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [item.id]);

    const fetchEpisodes = async () => {
        try {
            const response = await get(`${API_URL}/api/manga/chapter/listManga?mangaId=${item.moduleItemId ? item.moduleItemId : item.id}`);
            if ((await response).success) {
                setMovieEpisodes((await response).payload)
            } else {

            }
        } catch (e) {

        }
    }




    const fetchManga = async () => {
        try {
            const response = await get(`${API_URL}/api/manga/${item.moduleItemId ? item.moduleItemId : item.id}`);
            if ((await response).success) {
                setMovieData((await response).payload)
            } else {

            }
        } catch (e) {

        }
    }



    const handleSaveItem = async () => {
        setAlertVisible(true);
        try {
            const form = {
                module: 'MANGA',
                moduleItemId: item.moduleItemId ? item.moduleItemId : item.id,
                moduleItemName: item.moduleItemName ? item.moduleItemName : item.name,
                moduleItemDesc: item.moduleItemDesc ? item.moduleItemDesc : item.desc,
                moduleItemImage:  item.moduleItemImage ? item.moduleItemImage : `${item.image?.name}.${item.image?.ext}`
            };
            // Make the POST request
            const response = await postJson(`${API_URL}/api/client/user/savedItem/saveOrDelete`, form);
            if (response.status === 200 && response.success) {
                setCheckSaved(!checkSaved);
                setAlertType(!checkSaved === true ? 'success' : 'warning');
                setAlertMessage(!checkSaved === true ? 'Амжилттай хадгалагдлаа ' : 'Амжилттай устгагдлаа')
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error saving item:", error);
            alert('Алдаа', error);
        }
    };

    const fetchSectionChapters = async (sectionId) => {
        if (selectedItem !== sectionId) {
            setSelectedItem(sectionId);
            try {
                const response = await get(`${API_URL}/api/manga/${item.id}/${sectionId}/chapter`);
                if (response.success) {
                    setSectionEpisodes(response.payload);
                } else {
                    console.error("Failed to fetch section episodes");
                }
            } catch (e) {
                console.error("Error fetching section episodes:", e);
            }
        } else {
            setSelectedItem(null);
        }
    };

    if (movieData) {
        return (
            <>
                <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
                <button className={"button-close"} onClick={() => setShown(false)}>
                    <Close
                        stroke={"#000"}
                        style={{width: 15, height: 15}}
                    />
                </button>
                <div className={"movie-modal-container"}>
                    <div className={"movie-modal gap-5 grid grid-cols-12 w-full"}>
                        <div className={"content col-span-12 lg:col-span-5"}>
                            <div >
                                {
                                    movieData.image &&
                                    <div className={"cover"}>
                                        <img
                                            src={`https://www.mnfansubs.net/resource/${movieData.image.name}.${movieData.image.ext}`}
                                            alt=""/>
                                    </div>
                                }
                                <div className={"mt-2 gap-2 flex flex-col"}>
                                    <button className="button-md button-warring w-full">
                                        <Play color={"#fff"}/>
                                        Эхлүүлэх
                                    </button>
                                    {
                                        checkSaved === false &&
                                        <>
                                            <button onClick={handleSaveItem} className="button-md button-primary w-full">
                                                <Plus color={"#DFDFDF"}/>Хадгалах
                                            </button>
                                        </>
                                    }
                                    {
                                        checkSaved === true &&
                                        <>
                                            <button onClick={handleSaveItem} className="button-md button-success">
                                                <BookmarkMinus color={"#FFF"} />
                                                Хадгалсан
                                            </button>
                                        </>
                                    }
                                </div>
                                <div className={"categories"}>
                                    <div className={"header"}>
                                        Төрөл:
                                    </div>
                                    <ul>
                                        {movieData.categories && movieData.categories.map(item => (
                                            <li key={item.id}>
                                                <Chain/>
                                                {item.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={"info"}>
                                    <div>
                                    <span>
                                        Гарч эхэлсэн:
                                    </span>
                                        <span>
                                        {movieData.year}
                                    </span>
                                    </div>
                                    <div>
                                    <span>
                                     Англи нэр:
                                    </span>
                                        <span>
                                    {movieData.nameEn}
                                    </span>
                                    </div>
                                    <div>
                                    <span>
                                        Ангийн тоо:
                                    </span>
                                        <span>
                                      {movieData.totalNumber}
                                    </span>
                                    </div>
                                    <div>
                                    <span>
                                    Төлөв:
                                    </span>
                                        {movieData.movieStatus === "ONGOING" &&
                                            <span>
                                         Гарч байгаа
                                        </span>
                                        }
                                        {movieData.movieStatus === "COMPLETED" &&
                                            <span>
                                         Дууссан
                                        </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-span-12 lg:col-span-7"}>
                            <div className={"title"}>
                                {movieData.name}
                            </div>
                            <div className={"desc"}>
                                {movieData.description}
                            </div>
                            <div className={" mt-4"}>
                                <div>
                                    {movieData.sections && movieData.sections.map(section => (
                                        <div key={section.id}>
                                            <div className={"section"} onClick={() => fetchSectionChapters(section.id)}>
                                                <ul>
                                                    <li>{section.name}</li>
                                                    {selectedItem === section.id ? <ArrowDown /> : <ArrowRight />}
                                                </ul>
                                            </div>
                                            {sectionEpisodes && selectedItem === section.id && (
                                                <div className={"manga-section-dropdown"}>
                                                    {sectionEpisodes.map(chapter => (
                                                        <Link to={`/manga/${item.id}/chapter/${chapter.id}`}>
                                                            <ul>
                                                                <div>
                                                                    {chapter.episodeNumber}
                                                                </div>
                                                                <div>
                                                                    {chapter.name}
                                                                </div>
                                                            </ul>
                                                        </Link>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    {
                                        movieData.sections && movieData.sections.length === 0 &&
                                        <div className={"manga-section-dropdown"}>
                                            {movieEpisodes && movieEpisodes.map(chapter => (
                                                <Link to={`/manga/${item.moduleItemId ? item.moduleItemId : item.id}/chapter/${chapter.id}`}>
                                                    <ul>
                                                        <li>{chapter.episodeNumber} chapter</li>
                                                    </ul>
                                                </Link>

                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


}

export default Manga;
