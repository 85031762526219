import React from "react";
import {Link} from "react-router-dom";
import SearchPop from "./search-popup/Search-pop";
import User from "../icons/User";
import MenuDropdown from "../menu-dropdown/Menu-Dropdown";
import Wallet from "../icons/Wallet";
import Eye from "../icons/Eye";
import Menu from "../icons/Menu";
import Bookmark from "../icons/Bookmark";
import LogoSvg from "../icons/LogoSvg";


class NavbarCommon extends React.Component {

    showMenu = false;

    menu;
    menuRect;
    navRect;
    rightRect;
    menuButton;

    constructor(props) {
        super(props);
        this.navRef = React.createRef();
        this.navRightRef = React.createRef();
        this.menuRef = React.createRef();
        this.menuButtonRef = React.createRef();
        this.navigate = this.navigate.bind(this);
    }

    navigate() {
        this.props.navigate()
    }


    componentDidMount() {
        this.menu = this.menuRef.current;
        this.menuButton = this.menuButtonRef.current;
        this.menuRect = this.menuRef.current.getBoundingClientRect();
        this.navRect = this.navRef.current.getBoundingClientRect();
        this.rightRect = this.navRightRef.current.getBoundingClientRect();
    }

    toggleMenu() {
        if (!this.showMenu) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
    }


    openMenu() {
        console.log(this.navRef);
        console.log(this.navRightRef);
        console.log(this.menu);
        this.menu.style.opacity = 1;
        this.menu.style.pointerEvents = "fill";
        this.menu.style.top = `${(this.navRect.height + this.navRect.top) + 20}px`;
        if (window.innerWidth <= 1440) {
            this.menu.style.right = `20px`;
        } else {
            this.menu.style.right = `${this.navRef.current.offsetLeft}px`;
        }
        this.showMenu = true;
        setTimeout(() => {
            document.onclick = this.clickOutsideMenu;
        }, 300);
    }

    closeMenu() {
        this.menu.style.opacity = 0;
        this.menu.style.pointerEvents = "none";
        this.menu.style.top = `${(this.navRect.height + this.navRect.top) + 20}px`;
        this.menu.style.right = `-100%`;
        this.showMenu = false;
        document.onclick = null;
    }

    clickOutsideMenu = (e) => {
        if (this.menu && this.menuButton) {
            if (e.composedPath() && !e.composedPath().includes(this.menu)) {
                this.closeMenu();
            }
        }
    }



    render() {
        return (
            <>
                <div ref={this.navRef} className={"container"}>
                    <div className={"header-common"}>
                        <div>
                            <Link to="/" className="logo">
                                <LogoSvg/>
                            </Link>
                           {/* <div className="button-header button-mobile">
                                <ArrowLeft color="#A7A5A5"/>
                            </div>*/}
                        </div>
                        <div className="navbar-right" ref={this.navRightRef}  style={{position:"relative"}}>
                            <SearchPop />
                            <button ref={this.menuButtonRef} className="button-header" onClick={() => this.toggleMenu()}>
                                <Menu color={"#fff"}/>
                            </button>
                            <div className="button-header">
                                <Link to="/Client" >
                                    <User/>
                                </Link>
                            </div>
                            <div className="button-header">
                                <Link  to="/saved">
                                    <Bookmark/>
                                </Link>
                            </div>
                            <div className="button-header">
                                <Link to="/watched">
                                    <Eye/>
                                </Link>
                            </div>
                            <div className="button-header">
                                <Link to="/qpay" >
                                    <Wallet stroke={"#8F8D8D"}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <MenuDropdown ref={this.menuRef} close={() => this.closeMenu()}/>
            </>
        )
    }
}

export default NavbarCommon;
