import React from 'react';

function PlayCircle(props) {
    const color = props.color ? props.color : "#a7a5a5";
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699ZM9.99935 3.33366C6.31745 3.33366 3.33268 6.31843 3.33268 10.0003C3.33268 13.6822 6.31745 16.667 9.99935 16.667C13.6812 16.667 16.666 13.6822 16.666 10.0003C16.666 6.31843 13.6812 3.33366 9.99935 3.33366ZM8.75257 5.94718L13.7526 9.28051C14.2714 9.58316 14.3019 10.3065 13.8441 10.6587L8.75257 14.0535C8.19703 14.3775 7.49935 13.9768 7.49935 13.3337V6.66699C7.49935 6.02383 8.19703 5.62311 8.75257 5.94718Z"
                fill={color}
            />
        </svg>


    );
}

export default PlayCircle;
