function Play(props) {

    const color = props.color  ?? "#A7A5A5";
    const fill = props.fill ? "#FF4418" : "none"

    const style = props.style ? props.style : {}

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={fill}>
            <path d="M4.16663 4.15803C4.16663 3.34873 4.16663 2.94407 4.33537 2.72101C4.48237 2.52669 4.70706 2.40644 4.95029 2.39192C5.22949 2.37525 5.56618 2.59971 6.23956 3.04863L15.0025 8.89061C15.5589 9.26154 15.8371 9.44701 15.9341 9.68078C16.0188 9.88516 16.0188 10.1149 15.9341 10.3192C15.8371 10.553 15.5589 10.7385 15.0025 11.1094L6.23956 16.9514C5.56618 17.4003 5.22949 17.6248 4.95029 17.6081C4.70706 17.5936 4.48237 17.4733 4.33537 17.279C4.16663 17.0559 4.16663 16.6513 4.16663 15.842V4.15803Z"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export default Play;
