import {API_URL} from "../constans/API_URL";

export const handleSessionLogout = async () => {
    try {
        let localCsrf = localStorage.getItem("token");
        let csrf;
        if (localCsrf && localCsrf.length > 5)
            csrf = await JSON.parse(localCsrf);
        if (csrf) {
            await fetch(`${API_URL}/signout`, {
                method: "POST",
                headers: {
                    "X-CSRF-TOKEN": csrf.token,
                },
                credentials: "include",
            });
            localStorage.removeItem("token"); // Clear token from localStorage
            return true; // Indicate successful logout
        } else {
            // If csrf is not valid, still consider as a failure to logout securely
            console.error("Logout failed due to invalid CSRF token");
            return false; // Explicitly return false here
        }
    } catch (e) {
        console.error("Logout failed", e);
        return false; // Indicate logout failure
    }
};
