function Bookmark(props) {
    const style = props.style ? {...props.style} : {}
    const color = props.stroke  ?? "#A7A5A5";

    return (
        <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 0C12.5625 0.0208333 13.0312 0.21875 13.4062 0.59375C13.7812 0.96875 13.9792 1.4375 14 2V15.4688C14 15.6771 13.9167 15.8333 13.75 15.9375C13.6667 15.9792 13.5833 16 13.5 16C13.3958 16 13.3021 15.9688 13.2188 15.9062L8 12.5625L2.78125 15.9062C2.61458 15.9896 2.4375 15.9896 2.25 15.9062C2.08333 15.8021 2 15.6562 2 15.4688V2C2.02083 1.4375 2.21875 0.96875 2.59375 0.59375C2.96875 0.21875 3.4375 0.0208333 4 0H12ZM13 14.5938V2C13 1.70833 12.9062 1.46875 12.7188 1.28125C12.5312 1.09375 12.2917 1 12 1H4C3.70833 1 3.46875 1.09375 3.28125 1.28125C3.09375 1.46875 3 1.70833 3 2V14.5938L7.71875 11.5938C7.80208 11.5312 7.89583 11.5 8 11.5C8.08333 11.5 8.17708 11.5312 8.28125 11.5938L13 14.5938Z"
                fill={color}/>
        </svg>
    )
}

export default Bookmark;
