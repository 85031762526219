import React, {forwardRef} from 'react'
import {Link} from "react-router-dom";
import Close from "../icons/Close";

const MenuDropdown = forwardRef((props, ref) => {
    return (
        <div ref={ref} className={"menu-dropdown"}>
            {
                props.close &&
                <button className={"close"} onClick={() => props.close()}>
                    <Close stroke={"#fff"}/>
                </button>
            }
            <div className={"grid xl:grid-cols-4 gap-5 lg:grid-cols-4 sm:grid-cols-4 max-sm:grid-cols-2"}>
            <div className={"body"}>
                    <div className={"section"}>
                        Цэс
                    </div>
                    <div className={"title"}>
                        <div>
                            <Link to={"/"}>
                                Нүүр хуудас
                            </Link>
                        </div>
                        <div>
                            <Link to={"/guide"}>
                                Хандив өгөх заавар
                            </Link>
                        </div>
                        <div>
                            <Link to={"/qpay"}>
                                Хандив
                            </Link>
                        </div>
                        {/*<div>
                            <Link to={"/"}>
                                Блог
                            </Link>
                        </div>
                        <div>
                            <Link to={"/"}>
                                Хуваарь
                            </Link>
                        </div>*/}
                        <div>
                            <Link to={"https://www.messenger.com/t/MNFansubs"}>
                                Холбоо барих
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"body"}>
                    <div className={"section"}>
                        Анимэ
                    </div>
                    <div className={"title"}>
                        <div>
                            <Link to={"/all-projects"}>
                                Бүх анимэ
                            </Link>
                        </div>
                        <div>
                            <Link to={"/latest-episodes"}>
                                Шинэ ангиуд
                            </Link>
                        </div>
                        <div>
                            <Link to={"/anime-ongoing"}>
                                Орчуулж байгаа
                            </Link>
                        </div>
                        <div>
                            <Link to={{pathname: "/anime-completed"}}>
                                Орчуулж дууссан
                            </Link>
                        </div>
                        <div>
                            <Link to={"/anime-movies"}>
                                Анимэ Кино
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"body"}>
                    <div className={"section"}>
                        Манга
                    </div>
                    <div className={"title"}>
                        <div>
                            <Link to={"/manga-projects"}>
                                Бүх манга
                            </Link>
                        </div>
                        {/*  <div>
                            <Link to={"/manga-ongoing"}>
                                Орчуулж байгаа
                            </Link>
                        </div>
                        <div>
                            <Link to={"/manga-completed"}>
                                Орчуулж дууссан
                            </Link>
                        </div>*/}
                    </div>
                </div>
                <div>
                    <div className={"section"}>
                        Кино
                    </div>
                    <div className={"title"}>
                        <div>
                            <Link to={"/netflix"}>
                                Netflix
                            </Link>
                        </div>
                        <div>
                            <Link to={"/all-movies"}>
                                Бүх төслүүд
                            </Link>
                        </div>
                        <div>
                            <Link to={"/movie-series"}>
                                Цуврал
                            </Link>
                        </div>
                        <div>
                            <Link to={"/movies"}>
                                Кино
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default MenuDropdown;
