import React, {useEffect, useState} from 'react'
import {get} from "sodon-http/API"
import {API_URL} from "../../constans/API_URL";
import HeaderCard from "../../components/common/header-author/Header-Card";
import CardCommon from "../../components/cards/CardCommon";
import {ReactSVG} from "react-svg";
import Manga from "../manga/Manga";
import Select from "react-select";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import {customStyles} from "../../components/common/SelectStyle";
import {type} from "@testing-library/user-event/dist/type";


export default function PageMangaAllProjects() {


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    const [data, setData] = useState([]);
    const [pages, setPages] = useState(null);
    const [query, setQuery] = useState('')





    useEffect(() => {
        fetchMangas();
    }, [query]);


    const fetchMangas = async () => {
        try {
            const response = await get(`${API_URL}/api/manga/list?q=${query}`);
            if ((await response).success) {
                setData((await response).payload.thisPageElements);
                setPages((await  response).payload.totalNumberOfElements)
            }
        } catch (e) {
        }
    }

    function openDialog(item) {
        setSelectedItem(item);
        setIsDialogOpen(true);
    }

    function handleClose(e) {
        setIsDialogOpen(false);
    }




    return (
        <>
            <div className={"container"}>
                <div className="ongoing">
                    <div className="manga-search" >
                        <ReactSVG
                            src="icons/search-refraction.svg"
                            fill="#fff"
                        />
                        <input
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            type="text"
                            placeholder="Манга хайлт хийх..."
                        />
                    </div>
                    <div className={"page-header-title"}>
                        Бүх манганууд
                    </div>
                    <div className={"grid mt-5 lg:grid-cols-8 gap-4 md:grid-cols-5 sm:grid-cols-4 max-sm:grid-cols-2"}>
                        {
                            data.map((item, index) => (
                                <>
                                    <div>
                                        <CardCommon key={index} item={item} onClick={() => {openDialog(item);}} />
                                    </div>

                                </>
                            ))
                        }
                    </div>
                    <CustomPopup open={isDialogOpen} close={handleClose}>
                        <Manga
                            item={selectedItem}
                            shown={isDialogOpen}
                            setShown={value => setIsDialogOpen(value)}
                        />
                    </CustomPopup>
                </div>
            </div>


        </>

    )
}
