import React, {useState} from 'react'
import '../../App.css'
import ClientInfo from "../../components/pages/client/ClientInfo";
import ClientButtons from "../../components/pages/client/ClientButtons";
import {useUserStore} from "../../constans/StoreUserData";

export default function Client() {



    const { user, setUser } = useUserStore();

    return (
        <div className={""}>
            <ClientInfo user={user}/>
            <div className={"container-md"}>
                <ClientButtons/>
            </div>
        </div>
    )
}
