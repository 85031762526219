import React, {useEffect, useState} from 'react'
import List from "../../components/icons/List";
import Calendar from "../../components/icons/Calendar";
import Qpay from "../../components/icons/Qpay";
import Wallet from "../../components/icons/Wallet";
import {Link, useNavigate} from "react-router-dom";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import ArrowLeft from "../../components/icons/ArrowLeft";
import {Alert, Radio} from "@mui/material";
import SubscriptionPayment from "./SubscriptionPayment";
import CustomAlert from "../../components/common/CustomAlert";
import CustomPopup from "../../components/common/pop-up/CustomPopup";
import Bank from "../../components/icons/Bank";


export default function QPayScreen() {

    const  navigate = useNavigate();

    const [alert, setAlert] = useState('');
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState("");



    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);



    useEffect(() => {
        getSubscriptionTypes();
    }, []);



    const getSubscriptionTypes = async () => {
        try {
            const response = await get(`${API_URL}/api/user/subscription/type`);
            if ((await response).success) {
                setTypes((await response).payload);
            }
        } catch (e) {
        }
    }



    const selectSubscription = (event) => {
        const newSelectedType = event.target.value;
        const newItem = types.find(item => item.id.toString() === newSelectedType);
        setSelectedType(newSelectedType);
        setSelectedItem(newItem);

    };


    const openDialog = () => {
        if (selectedItem) {
            setIsDialogOpen(true);
        } else {
            setAlertType("error");
            setAlert("Хугацаа сонгоно уу!");
            setAlertVisible(true);
            setTimeout(() => {
                setAlertVisible(false);
            }, 2000);
        }
    };


    const handleClose = () => {
        setIsDialogOpen(false);
    };


    const paymentFromWallet = async () => {
        if (selectedItem){
            try {
                const response = await postJson(`${API_URL}/api/client/user/subscription/add?typeId=${selectedItem.id}`);
                if (response.data && response.data.type === "success") {
                    Alert("success", response.data.payload);
                } else {
                    setAlertType("error");
                    setAlert(response.payload.text);
                    setAlertVisible(true);
                }
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);
            } catch (error) {
            }
        }else {
            setAlertType("error");
            setAlert("Хугацаагаа сонгоно уу!");
            setAlertVisible(true);
            setTimeout(() => {
                setAlertVisible(false);
            }, 2000);

        }

    };


    const paymentBanks = () => {
        setAlertType("error");
        setAlert("Хугацаагаа сонгоно уу!");
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 2000);
    };



    return (
        <div>
            <div className={"container-center"}>
                <CustomAlert isVisible={alertVisible} type={alertType} message={alert}/>
                <div className={"qpay"}>
                    <div className={"header"}>
                        <div className="button-mobile button-primary" onClick={() => navigate(-1)}>
                            <ArrowLeft />
                        </div>
                        <div>
                          <span>
                              Миний хандив
                          </span>
                        </div>
                        <Link to={"/transactions"} className={"button-md button-primary"}>
                            <List/>
                            Хуулга
                        </Link>
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-3">
                    {types.map((item, index) => (
                        <div key={index} className="body">
                            <Calendar />
                            <div className="gap-1 flex flex-col">
                                <div className="year">
                                    <span>{item.alias.substring(0, 1)} сар</span>
                                </div>
                                <div className="price">
                                    <span>{item.price}₮</span>
                                </div>
                            </div>
                            <div>
                                <Radio
                                    value={item.id.toString()}
                                    checked={selectedType === item.id.toString()}
                                    onChange={selectSubscription}
                                    sx={{
                                        color: 'grey',
                                        '&.Mui-checked': {
                                            color: 'grey',
                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                display: 'block',
                                                width: 12,
                                                height: 12,
                                                backgroundColor: '#FF6B00',
                                                borderRadius: '50%',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                    <div className={"grid lg:grid-cols-4 md:grid-cols-2 mt-3 gap-3"}>
                        <div className={"button-md button-primary"} onClick={paymentFromWallet}>
                            <Wallet/>
                            Хэтэвчнээс төлөлт хийх
                        </div>
                        <div className={"button-md button-primary"} onClick={openDialog}>
                            <Qpay/>
                            QPay QR код үүсгэх
                        </div>
                        <Link to={'/Guide'} className={"button-md button-primary"} onClick={openDialog}>
                            <Bank/>
                            Дансаар төлөх
                        </Link>
                      {/*  {
                            selectedType ?
                                <div>
                                    <Link
                                        to={{pathname: `/paymentByBanks/${selectedType}`}}
                                        className={"button-md button-primary button-mobile"}
                                    >
                                        <Bank/>
                                        Банкаар төлөх
                                    </Link>
                                </div>
                                :
                                <div onClick={paymentBanks} className={"button-md button-primary button-mobile"}>
                                    <Bank/>
                                    Банкаар төлөх
                                </div>
                        }*/}
                    </div>
                </div>
                <CustomPopup open={isDialogOpen} close={handleClose}>
                    <SubscriptionPayment
                        item={selectedItem}
                        shown={isDialogOpen}
                        setShown={value => setIsDialogOpen(value)}
                    />
                </CustomPopup>
            </div>
        </div>

    )
}
