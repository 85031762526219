import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import ArrowLeft from "../../icons/ArrowLeft";
import User from "../../icons/User";
import {get} from "sodon-http/API";
import {API_URL} from "../../../constans/API_URL";
import Plus from "../../icons/Plus";

export default function ProfileInfo({user}) {


    const [loginname, setLoginname] = useState("");
    const [surname, setSurname] = useState("");
    const [givenname, setGivenname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [birthDate, setBirthDate] = useState('');
    const [profilePhotoUri, setProfilePhotoUri] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await get(`${API_URL}/api/user/${user.id}`);
                if (response.success) {
                    setLoginname(response.payload.loginname);
                    setSurname(response.payload.surname);
                    setGender(response.payload.gender)
                    setBirthDate(response.payload.birthDate)
                    setGivenname(response.payload.givenname);
                    setEmail(response.payload.email);
                    setPhone(response.payload.phone);
                    if (response.payload.photo)
                        setProfilePhotoUri(`${API_URL}/resource/${user.photo?.name}.${user.photo?.ext}`)
                }
            } catch (error) {
                console.log(error)
            }
        };
        fetchUserData();
    }, [user.id]);


    return (
        <div className={"profile"}>
            <div className={"flex gap-3 items-center mb-3"}>
                <Link to={"/client"}>
                    <div className={"button-md button-primary"}>
                        <ArrowLeft/>
                    </div>
                </Link>
                <div className={"header"}>Миний тохиргоо</div>
            </div>
            <div className={"body"}>
                <div className={"about"} style={{alignItems: "center"}}>
                    <div>
                        Профайл зураг
                    </div>
                    <Link to="/profile-image" className="img" style={{paddingBottom: "15px"}}>
                        {
                            user.image ?
                                <img
                                    src={profilePhotoUri}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = 'images/profile.png';
                                    }}
                                    alt="profile"
                                />

                                :
                                <div className="img">
                                    Зураг байхгүй
                                </div>
                        }
                    </Link>
                    <div>
                        <Link to="/profile-image" className="img" style={{color:"#A7A5A5", fontSize:"14px"}}>
                            Зураг оруулах
                        </Link>
                    </div>
                </div>
                <div className={"about"}>
                    <div>
                    Нэвтрэх нэр
                    </div>
                    {
                        loginname ?
                            <div>
                                {loginname}
                            </div>

                            :
                            <div>
                                Нэвтрэх нэр байхгүй
                            </div>

                    }
                </div>
                <div className={"about"}>
                    <div>
                        Өөрийн нэр
                    </div>
                    {
                        givenname ?
                            <div>
                                {givenname}
                            </div>

                            :
                            <div>
                                Өөрийн нэр байхгүй
                            </div>

                    }
                </div>
                <div className={"about"}>
                    <div>
                        Овог
                    </div>
                    {
                        surname ?
                            <div>
                                {surname}
                            </div>
                            :
                            <div>
                                Овог байхгүй
                            </div>
                    }
                </div>
                <div className={"about"}>
                    <div>
                        Хүйс
                    </div>
                    {gender ?
                        <div>
                            {gender === "MALE" &&
                                <>
                                    Эрэгтэй
                                </>
                            }
                            {gender === "FEMALE" &&
                                <>
                                    Эмэгтэй
                                </>
                            }
                            {gender === "OTHER" &&
                                <>
                                    Бусад
                                </>
                            }
                        </div>
                        :
                        <div>
                            Хүйс байхгүй
                        </div>
                    }
                </div>
                <div className={"about"}>
                    <div>
                        Төрсөн өдөр
                    </div>
                    {
                        birthDate ?
                        <div>
                            {birthDate[0]} / {birthDate[1]} / {birthDate[2]}
                        </div>
                            :
                            <div>
                                Төрсөн өдөр байхгүй
                            </div>

                    }
                </div>
                <div className={"about"}>
                    <div>
                        И-мэйл
                    </div>
                    {
                        email ?
                            <div>
                                {email}
                            </div>
                            :
                            <div>
                                И-мэйл байхгүй
                            </div>

                    }
                </div>
                <div className={"about"}>
                    <div>
                        Гар утас
                    </div>
                    {
                        phone ?
                            <div>
                                {phone}
                            </div>
                            :
                            <div>
                                Утас байхгүй
                            </div>
                    }
                </div>
            </div>
            <Link to={"/profile-edit"}>
                <div className={"button-md button-primary mt-5"}>
                    <User/>
                    Засах
                </div>
            </Link>
        </div>
    )
}
